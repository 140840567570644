
import React, { Component } from 'react'

import TableBody from '@mui/material/TableBody';
import { CELLFORMAT, StyledTableCell, StyledTableRow } from './GenericTable';
import { Checkbox, Collapse, IconButton, Radio, Switch, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { ArrowDownward, ArrowUpward, ContentCopy, Delete, Edit, North, South } from '@mui/icons-material';
import PagedDataGrid from './PagedDataGrid';
import { NGT_ACTIVETYPES } from './NestedGenericTable';
// 

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [subTableColSpan, setSubTableColSpan] = React.useState(0);
    React.useEffect(() => {
        // setSubTableColSpan(props.columns.length + 1);
        setSubTableColSpan(
            props.columns.length +
            (props.onSetActive != null && props.activeKey != null ? 1 : 0) +
            (props.onDelete != null ? 1 : 0) +
            (props.onDuplicate != null ? 1 : 0) +
            (props.onSubDublicate != null ? 1 : 0) +
            (props.onEdit != null ? 1 : 0) +
            (props.onPositionChange != null ? 2 : 0)
        );
    }, []);

    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.id}
                style={{ cursor: 'pointer', }}>
                <StyledTableCell onClick={() => setOpen(!open)}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    // onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                {props.onSetActive != null && props.activeKey != null &&
                    <StyledTableCell sx={{ width: 25, textAlign: "center !important" }} onClick={(event) => { event.stopPropagation(); props.isActive ? props.onSetActive(!props.isActive(row)) : props.onSetActive(!row[props.activeKey]); }} >
                        {props.activeCompType == NGT_ACTIVETYPES.CHECKBOX ?
                            <Checkbox disabled={props.activeCompDisabled} color="primary" checked={props.isActive ? props.isActive(row) : row[props.activeKey]} />
                            :
                            props.activeCompType == NGT_ACTIVETYPES.RADIO ?
                                <Radio type='radio' disabled={props.activeCompDisabled} checked={props.isActive ? props.isActive(row) : row[props.activeKey]} />
                                :
                                <Switch size='small' disabled={props.activeCompDisabled} color="primary" checked={props.isActive ? props.isActive(row) : row[props.activeKey]} />
                        }
                    </StyledTableCell>
                }

                {props.columns.map((column) => {
                    return <StyledTableCell onClick={() => { props.onClick(); }} key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey]}</StyledTableCell>
                })}
                {props.onDelete != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onDelete(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <Delete />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onDuplicate != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onDuplicate(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <ContentCopy />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onEdit != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onEdit(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <Edit />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onPositionChange != null &&
                    <StyledTableCell sx={{ maxWidth: 10 }} onClick={(event) => { event.stopPropagation(); props.onPositionChange(row, -1); }} >
                        <IconButton size="small" color='primary' >
                            <ArrowUpward style={{ height: 20, width: 20 }} />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onPositionChange != null &&
                    <StyledTableCell sx={{ maxWidth: 10 }} onClick={(event) => { event.stopPropagation(); props.onPositionChange(row, 1); }} >
                        <IconButton size="small" color='primary' >
                            <ArrowDownward style={{ height: 20, width: 20 }} />
                        </IconButton>
                    </StyledTableCell>
                }

            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ padding: 0 }} colSpan={subTableColSpan + 1}>
                    <Collapse key={"NTBody_Collapse_" + props.index + "_" + row[props.subTableKey]?.length} in={open} timeout="auto" unmountOnExit>
                        <div style={{ width: "100%" }}>
                            {props.customSubTableHeaderComponent &&
                                <div style={{ padding: 20 }}>
                                    {props.customSubTableHeaderComponent(row, props.index)}
                                </div>
                            }

                            <PagedDataGrid
                                subTableSortModel={props.subTableSortModel}
                                onSubPositionChange={props.onSubPositionChange ? (subrow, direction) => { props.onSubPositionChange(row, subrow, direction) } : null}
                                getSubRowId={props.getSubRowId}
                                rows={row[props.subTableKey]}
                                columns={props.subColumns}
                                mainIndex={props.index}  // Pass the mainIndex here
                                activeKey={props.subActiveKey}
                                subIsActive={props.subIsActive}
                                addBtnLabel={props.addSubBtnLabel}
                                onDuplicate={props.onSubDublicate != null ? (subIndex) => { props.onSubDublicate(row, subIndex) } : null}
                                noAdd={props.noSubAdd}
                                addBtnOnClick={() => { props.addSubBtnOnClick(props.index) }}
                                addBtnIcon={props.addSubBtnIcon}
                                onClick={(myRow) => { props.onSubClick(myRow) }}
                                onSetActive={props.onSubSetActive != null ? (row) => { props.onSubSetActive(row) } : null}
                                onEdit={props.onSubEdit != null ? (myRow) => { props.onSubEdit(myRow) } : null}
                                onDelete={props.onSubDelete != null ? (myRow) => { props.onSubDelete(myRow) } : null}
                            />
                            {props.customSubTableFooterComponent &&
                                <div style={{ padding: 20 }}>
                                    {props.customSubTableFooterComponent(row, props.index)}
                                </div>
                            }
                        </div>
                    </Collapse>
                </StyledTableCell>

            </StyledTableRow>
        </React.Fragment>
    );
}
export class NTTableBody extends Component {
    render() {
        return (
            <TableBody>
                {this.props.tableData?.map((row, index) => {
                    return <Row
                        key={row.id}
                        row={row}
                        customSubTableHeaderComponent={this.props.customSubTableHeaderComponent}
                        customSubTableFooterComponent={this.props.customSubTableFooterComponent}
                        getRowId={this.props.getRowId}
                        getSubRowId={this.props.getSubRowId}
                        onPositionChange={this.props.onPositionChange}
                        onSubPositionChange={this.props.onSubPositionChange}
                        subTableSortModel={this.props.subTableSortModel}
                        subColumns={this.props.subColumns}
                        subTableKey={this.props.subTableKey}
                        onEdit={this.props.onEdit != null ? (rowId) => { this.props.onEdit(index, rowId) } : null}
                        // (index, rowId) => {this.props.onEdit(index, rowId)}
                        onDelete={this.props.onDelete != null ? (rowId) => { this.props.onDelete(index, rowId) } : null}
                        onDuplicate={this.props.onDuplicate != null ? (rowId) => { this.props.onDuplicate(index, rowId, row) } : null}
                        onSubDublicate={this.props.onSubDublicate != null ? (row, subIndex) => { this.props.onSubDublicate(index, subIndex, row) } : null}
                        onSetActive={(checked) => { this.props.onSetActive(index, checked) }}
                        onSubSetActive={this.props.onSubSetActive != null ? (row) => {
                            this.props.onSubSetActive(index, row)
                        } : null}
                        activeCompType={this.props.activeCompType}
                        addSubBtnIcon={this.props.addSubBtnIcon}
                        addSubBtnLabel={this.props.addSubBtnLabel}
                        addSubBtnOnClick={this.props.addSubBtnOnClick}
                        noSubAdd={this.props.noSubAdd}
                        //     this.props.onSubSetActive(index, subIndex, checked) }}
                        activeKey={this.props.activeKey}
                        isActive={this.props.isActive}
                        subActiveKey={this.props.subActiveKey}
                        subIsActive={this.props.subIsActive}
                        columns={this.props.columns}
                        index={index}
                        onClick={() => { this.props.onClickRow(index) }}
                        onSubClick={(myRow) => { this.props.onClickSubRow(index, myRow) }}
                        onSubEdit={this.props.onSubEdit != null ? (myRow) => { this.props.onSubEdit(index, myRow) } : null}
                        onSubDelete={this.props.onSubDelete != null ? (myRow) => { this.props.onSubDelete(index, myRow) } : null}
                    />
                })}
            </TableBody>
        )
    }
}

export default NTTableBody



{/* <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow> */}
