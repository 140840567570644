import React, { Component } from 'react'
import { TabPanel } from '@mui/lab'
import { Button } from '@mui/material'
import { connect } from 'react-redux';
import ChatSupportCaseButton from '../../Elements/ChatSupportCaseButton';

import { SUPPORTCHAT_INFOTAB } from '../../../../redux/supportSlice';
import { APIData } from '../../../../constants/APIAdresses';

export class UserSupportOverviewTab extends Component {
    constructor(props) {
        super(props);
        this.offset = 0;
        this.state = {
            userCaseList: [],
            loadActive: true,
        }
    }
    loadSupportCasesForUser(){
        // offsetMultiplier, type (0=all, 1=open, 2=closed), userId, sessionId
        // /support/getCases
        let uri = APIData.REALTIME+"/support/getCases?type=0&"
        let userIdOrSessionIdExists = false;
        if(this.props.data?.userId != null){
            uri += "userId="+this.props.data.userId+"&";
            userIdOrSessionIdExists=true;
        }else if(this.props.data?.sessionId != null){
            uri += "sessionId="+this.props.data.sessionId+"&";
            userIdOrSessionIdExists=true;
        }
        if(userIdOrSessionIdExists){
            uri+= "offsetMultiplier="+this.offset;
            this.offset++;
            console.log("URI: ",uri);
            fetch(uri, {credentials: 'include'})
            .then(response => {
                if(response.status === 200){
                    return response.json();
                }else{
                    return null;
                }
            }).then(result => {
                console.log("RESULT: ",result);
                if(result?.length > 0){
                    this.setState({userCaseList: [...this.state.userCaseList, ...result]});
                }else{
                    this.offset=-1;
                    this.setState({loadActive: false})
                }
            })
        }
    }
    componentDidUpdate(){
        if(this.props.tabActive && this.offset == 0){
            this.loadSupportCasesForUser();
        }
    }
    render() {
        return (
            <TabPanel value={SUPPORTCHAT_INFOTAB.SUPPORTOVERVIEW.toString()}>
                {this.state.userCaseList.map(myCase => {
                        return (
                            <ChatSupportCaseButton key={"USOT_SupportCaseBtn_"+this.props.data?.id+"_"+myCase.id} data={myCase} disabled={this.props.data?.id === myCase.id}/>
                        )
                    })
                }
                {this.state.loadActive &&
                    <Button onClick={() => {this.loadSupportCasesForUser()}} style={{marginTop:10}}>Mehr Laden</Button>
                }
            </TabPanel>
        )
    }
}
function mapStateToProps(state) {
    console.log("USERSUPPORTOVERVIEWTABDATA: ",state.support.activeCaseList[state.support.chatCaseIndex]);
    return {
        currentCaseIndex: state.support.chatCaseIndex,
        data: state.support.activeCaseList[state.support.chatCaseIndex]
    }
}

export default connect(mapStateToProps)(UserSupportOverviewTab)
