import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React, { Component } from 'react'

export const GPA_OPTIONS = {
    DECREASE: "decrease",
    INCREASE: "increase"
}
export class GeneralPriceAdjustmentTypeSelector extends Component {
    render() {
        return (
            <div>
                <FormControl style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 20 }}>
                    <FormLabel>Generelle Preisanpassung</FormLabel>
                    <RadioGroup defaultValue={GPA_OPTIONS.DECREASE} value={this.props.value} onChange={(evt, value) => {this.props.setValue(value)}} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel value={GPA_OPTIONS.DECREASE} control={<Radio />} label="Reduzieren" /> 
                        <FormControlLabel value={GPA_OPTIONS.INCREASE} control={<Radio />} label="Erhöhen" />
                    </RadioGroup>
                </FormControl>
            </div>
        )
    }
}

export default GeneralPriceAdjustmentTypeSelector