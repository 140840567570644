import React, { Component } from 'react'
import CollapsibleTile from '../../../../components/CollapsibleTile'
import { connect } from 'react-redux'
import { setCollections, setGroupCollections, setSelectedCollections, setSelectedGroupCollections } from '../../../../redux/productSlice'
import { Done, Edit } from '@mui/icons-material'
import { MUI_COLORS } from '../../../../constants/MUIConstants'
import GenericTable, { GT_ACTIVETYPES } from '../../../../components/GenericTable/GenericTable'
import { APIData } from '../../../../constants/APIAdresses'
import { PE_CHANGE_TYPES } from './ProductEditor'
import StaticTable from '../../../../components/StaticTable/StaticTable'
import { Typography } from '@mui/material'
const selectedCollColumns = [
    {
        width: 100,
        label: 'Kollektion-ID',
        dataKey: 'id',
    },
    {
        width: 125,
        label: 'Produktkollektion-ID',
        dataKey: 'pCollId',
    },
    {
        flex: 1,
        label: 'Name',
        dataKey: 'title',
    },
    {
        flex: 1,
        label: 'Info',
        dataKey: 'info',
    },
]

const collectionColumns = [
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
    },
    {
        flex: 1,
        label: 'Name',
        dataKey: 'title',
    },
    {
        flex: 1,
        label: 'Info',
        dataKey: 'info',
    },
    {
        width: 100,
        label: 'Optional',
        dataKey: 'isOptional',
        format: (value) => {
            return value ? "Ja" : 'Nein'
        },
    },
]
const MODES = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
}
const itemsPerPageOptions = [10, 25, 50, 100];
// TODO THE SELECTED OPTIONS ARE NOT SELECTED IN THE TABLE VIEW WITH CHECKBOXES
export class CollectionSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentMode: MODES.VIEW,
            pageIndex: -1,
            pageCount: 0,
            itemsPerPage: 10,
        }
        this.shopId = this.props.shopId;
    }
    render() {
        return (
            <CollapsibleTile
                title="Kollektionen"
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../../assets/icons/box.png')} width={20} height={20} />}
                actions={
                    this.state.currentMode === MODES.VIEW ?
                        [
                            { label: "Editieren", icon: <Edit />, onClick: () => { this.setState({ currentMode: MODES.EDIT }) } },
                        ] :
                        [
                            { label: "Fertig", color: MUI_COLORS.PRIMARY, icon: <Done />, onClick: () => { this.setState({ currentMode: MODES.VIEW }) } },
                        ]
                }
            >
                {this.state.currentMode === MODES.VIEW &&
                    <StaticTable
                        columns={selectedCollColumns}
                        tableData={this.props.selectedCollections}
                        setTableData={(data) => {
                            this.props.setSelectedCollections(JSON.parse(JSON.stringify(data)));
                        }}
                        idKey="gc_id"
                        onDelete={(index) => {
                            console.log("DELETED INDEX: ", index, " DATA: ", this.props.selectedCollections[index]);
                        }}

                    />
                }
                <div style={{ overflowX: "hidden" }}>

                    {this.state.currentMode === MODES.EDIT &&

                        <div style={this.state.currentMode === MODES.VIEW ? { position: 'absolute', height: 0, width: 0, left: -1000 } : {}}>
                            <GenericTable
                                config={{ columns: collectionColumns }}
                                apiEndPoint={APIData.SHOP + `/shop/${this.props.shopId}/collections/getPaged?withoutCD=1`}
                                tableData={this.props.collections}
                                setTableData={(data) => {
                                    data = data.map((element) => {
                                        return {
                                            ...element,
                                            active: this.props.selectedCollections.filter(sgc => sgc.status !== PE_CHANGE_TYPES.DELETE).findIndex((el) => el.id.toString() === element.id.toString()) !== -1
                                        }
                                    })
                                    this.props.setCollections(JSON.parse(JSON.stringify(data)));
                                }}
                                activeKey="active"
                                isActive={(row) => {
                                    console.log("ISACTIVECHECKROW: ", row);
                                    return row.active && row.status !== PE_CHANGE_TYPES.DELETE;
                                }}
                                onSetActive={(index, value) => {
                                    let myData = [...this.props.collections];
                                    let selectedGC = [...this.props.selectedCollections];
                                    let selectedGCIndex = selectedGC.findIndex((el) => {
                                        console.log("ELID: ", el.id, " MYDATAID: ", myData[index].id);
                                        return el.id.toString() === myData[index].id.toString()
                                    });

                                    // console.log("############### MYDATAID: ",myData[index].id);
                                    // console.log("SELECTEDGC: ", selectedGC);
                                    if (selectedGCIndex === -1) {
                                        selectedGC.push({
                                            ...myData[index],
                                            "id": myData[index].id, "info": myData[index].info, "title": myData[index].title, "pCollId": null, "status": PE_CHANGE_TYPES.NEW
                                        })
                                    } else {
                                        if (selectedGC[selectedGCIndex].status === PE_CHANGE_TYPES.DELETE) {
                                            delete selectedGC[selectedGCIndex].status;
                                            // selectedGC[selectedGCIndex] = {...selectedGC[selectedGCIndex], status: null};
                                        } else if (selectedGC[selectedGCIndex].status === PE_CHANGE_TYPES.NEW) {
                                            selectedGC.splice(selectedGCIndex, 1);
                                        } else {
                                            selectedGC[selectedGCIndex] = { ...selectedGC[selectedGCIndex], status: PE_CHANGE_TYPES.DELETE };
                                        }
                                    }
                                    console.log("SELECTEDCOLLECTIONSSET: ", selectedGC);
                                    console.log("COLLECTIONSET: ", myData);
                                    myData[index] = { ...myData[index], active: value }
                                    this.props.setCollections(myData);
                                    this.props.setSelectedCollections(selectedGC);
                                }}
                                activeCompType={GT_ACTIVETYPES.CHECKBOX}
                                noAdd
                            />
                        </div>
                    }
                </div>
                {/* } */}
            </CollapsibleTile>
        )
    }
}
function mapStateToProps(state) {
    return {
        collections: JSON.parse(JSON.stringify(state.product.collections)),
        selectedCollections: JSON.parse(JSON.stringify(state.product.selectedCollections))
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setCollections: (data) => {
            dispatch(setCollections(data));
        },
        setSelectedCollections: (data) => {
            dispatch(setSelectedCollections(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CollectionSelector)