import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import NestedGenericTable from '../../../components/GenericTable/NestedGenericTable'
import { APIData } from '../../../constants/APIAdresses'
import { Button, Typography } from '@mui/material'
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2'
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer'
import { Add } from '@mui/icons-material'
import { MUI_COLORS } from '../../../constants/MUIConstants'
import GeneralPriceAdjustmentHeader from './GeneralPriceAdjustmentHeader'
import { GPA_OPTIONS } from './GeneralPriceAdjustmentTypeSelector'
const mainColumns = [
    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        width: 200,
        label: 'Title',
        dataKey: 'title',
    },
    {
        width: 200,
        label: 'Info',
        dataKey: 'info',
    },
    {
        width: 200,
        label: 'Optional',
        dataKey: 'isOptional',
        format: (value) => value ? "Ja" : "Nein"
    },
    // {
    //     width: 200,
    //     label: 'Multiselektion',
    //     dataKey: 'isMultiSelection',
    //     format: (value) => value ? "Ja" : "Nein"
    // },

]
const subColumns = [
    {
        width: 200,
        headerName: 'ID',
        field: 'cdid',
    },
    {
        flex: 1,
        headerName: 'Beschreibung',
        field: 'description',
    },
    {
        // width: 2,
        flex: 1,
        headerName: 'Preis',
        field: 'price',
        valueGetter: (value) => !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2).replace(".", ",") + " €" : "-",
    },
]
const CD_FIELD_IDS = {
    DESCRIPTION: "CD_DESCRIPTION",
    PRICE: "CD_PRICE",
    ALLERGENS: "CD_ALLERGENS"
}
export class CollectionsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: []
        }
        this.shopId = this.props.shopId;
        this.allergens = [];
        this.allergensRowData = [];
        this.loadAllergens();
    }
    loadAllergens() {
        fetch(APIData.SHOP + `/allergens`, { credentials: "include" })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log("ErrStatus while loading allergens: ", response.status);
                    return null;
                }
            }).then((result) => {
                if (result) {
                    this.allergensRowData = result;
                    this.allergens = result;
                }
            })
            .catch((error) => {
                console.log("Error while loading allergens: ", error);

            })
    }
    addCollection(config) {
        console.log("ADDCOLLECTION CONFIG: ", config);
        let body = {
            id: 0, // this implies that this is a create for the backend
            name: config.fields[0].value,
            isOptional: config.fields[2].value,
            // isMultiSelection: config.isMultiSelection, // this isn't implemented on the backend yet.
        }
        // console.log("CONFIGVALUE1: ", config.fields[1].value);
        if (config.fields[1].value?.length > 0) {
            body.info = config.fields[1].value;
        }
        // console.log("BODY: ", body);
        if (config.fields.length > 3) {
            // map through the fields after isOptional (index 2) to add cdList to body
            let cd = {}
            body.cdList = [];
            config.fields.slice(3).forEach((field) => {
                if (field.identifier === CD_FIELD_IDS.DESCRIPTION) {
                    cd.description = field.value;
                } else if (field.identifier === CD_FIELD_IDS.PRICE) {
                    cd.price = field.value;
                } else if (field.identifier === CD_FIELD_IDS.ALLERGENS) {
                    cd.cdaList = field.selectedOptions.map((allergen) => {
                        return { aid: allergen.id }
                    });
                    body.cdList.push({ ...cd, inserted: true });
                    cd = {};
                }
            });
        }
        fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log("ErrStatus while adding collection: ", response.status);
                }
            }).then(result => {
                if (result) {
                    this.setState({ collections: [...this.state.collections, { id: result.id, title: body.name, info: body.info ? body.info : "", isOptional: body.isOptional, isMultiSelection: body.isMultiSelection ? true : false, collectionData: [] }] });
                    this.props.closeDrawer();
                    this.props.showAlertDisplay("Kollektion erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                }
            }).catch(e => {
                console.log("Error while adding collection: ", e);
                this.props.showAlertDisplay("Fehler beim Hinzufügen der Kollektion.", ALERT_SEVERITY.ERROR);
            })
    }
    deleteCollection(index) {
        let body = {
            delete: true,
            id: this.state.collections[index].id,
            shopId: this.shopId
        }
        fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    let myCollections = [...this.state.collections];
                    myCollections.splice(index, 1);
                    this.setState({ collections: myCollections });
                    this.props.showAlertDisplay("Kollektion erfolgreich gelöscht.", ALERT_SEVERITY.SUCCESS);
                }
            }).catch(e => {
                console.log("Error while deleting collection: ", e);
                this.props.showAlertDisplay("Fehler beim Löschen der Kollektion.", ALERT_SEVERITY.ERROR);
            })
    }
    updateCollection(configData) {
        // console.log("UPDATING COLLECTION!");
        let body = {
            // id: this.state.collections[configData.index].id,
        }
        configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE && !Object.values(CD_FIELD_IDS).includes(field.identifier) ).forEach((field, index) => {
            if (index == 0) {
                body.id = this.state.collections[configData.index].id;
            }
            body[field.dbKey] = field.value;
        });
        if(configData.fields.length > 4){
            body.id= this.state.collections[configData.index].id;
            body.cdList = [];
            let cd = {}
            configData.fields.splice(4).forEach((field) => {
                if (field.identifier === CD_FIELD_IDS.DESCRIPTION) {
                    cd.description = field.value;
                } else if (field.identifier === CD_FIELD_IDS.PRICE) {
                    cd.price = field.value;
                } else if (field.identifier === CD_FIELD_IDS.ALLERGENS) {
                    cd.cdaList = field.selectedOptions.map((allergen) => {
                        return {aid: allergen.id}
                    })
                    body.cdList.push({...cd, inserted: true});
                    cd = {};
                }
            })
        }
        console.log("BODY: ", body);
        if (Object.keys(body).length > 0) {
            fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                .then(response => {
                    if (response.ok) {
                        let myCollections = [...this.state.collections];
                        myCollections[configData.index] = { ...myCollections[configData.index], ...body };
                        this.setState({ collections: myCollections });
                        this.props.closeDrawer();
                        this.props.showAlertDisplay("Kollektion erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                    }
                }).catch(e => {
                    console.log("Error while updating collection: ", e);
                    this.props.showAlertDisplay("Fehler beim Aktualisieren der Kollektion.", ALERT_SEVERITY.ERROR);
                })
        }
    }
    openCollectionEditDrawer(index) {
        let baseDrawerConfig = {
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCollection(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } },
                { label: "Element Hinzufügen", onClick: (configData) => { this.addCollectionDataInputConfig(configData,4) } },
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "id",
                    disabled: true,
                    value: this.state.collections[index].id,
                },
                {
                    label: "Titel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "title",
                    // isNumeric: true,
                    // minValue: 0,
                    // maxValue: this.state.maxSortKey,
                    value: this.state.collections[index].title,
                },
                {
                    label: "Info",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "info",
                    value: this.state.collections[index].info,
                },
                {
                    label: "Optional",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isOptional",
                    value: this.state.collections[index].isOptional,
                },
                // {
                //     label: "Multiselektion",
                //     type: GENERIC_DRAWER_TYPE.SWITCH,
                //     dbKey: "isMultiSelection",
                //     value: this.state.collections[index].isMultiSelection,
                // }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    updateCollectionData(configData, row) {
        console.log("CONFIGDATA: ", configData);
        let body = {
            id: this.state.collections[configData.index].id,
            cdList: [
                {
                    id: row.cdid,
                    // aid is being used if it's an insert
                    // id is being used if it's an delete for the allergen
                }
            ]
        }
        let update = false;
        if (configData.fields[1].status === GD_STATUS_TYPE.UPDATE) {
            body.cdList[0].update = {}
            update = true;
            body.cdList[0].update.description = configData.fields[1].value;
        }
        if (configData.fields[2].status === GD_STATUS_TYPE.UPDATE) {
            if (body.cdList[0].update == null) {
                body.cdList[0].update = {}
            }
            update = true;
            body.cdList[0].update.price = configData.fields[2].value;
        }

        configData.fields[3].selectedOptions.filter(so => so.status != null).forEach((allergen, index) => {
            update = true;
            if (index == 0) {
                body.cdList[0].cdaList = [];
            }
            if (allergen.status === GD_STATUS_TYPE.NEW) {
                body.cdList[0].cdaList.push({ aid: allergen.id });
            } else if (allergen.status === GD_STATUS_TYPE.DELETE) {
                body.cdList[0].cdaList.push({ id: allergen.dbId });
            }
        });
        if (update) {
            fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log("ErrStatus while updating collection data: ", response.status);
                        this.props.showAlertDisplay("Fehler beim Aktualisieren des Kollektionselements.", ALERT_SEVERITY.ERROR);
                    }
                }).then(result => {
                    if (result) {
                        console.log("RESULTUPDATECD: ", result);
                        let myCollections = [...this.state.collections];
                        // find the index of the collection data in the collection array
                        let myCollectionIndex = myCollections[configData.index].collectionData.findIndex((cd) => cd.cdid === row.cdid);
                        myCollections[configData.index].collectionData[myCollectionIndex].price = body.cdList[0].update?.price != null ? body.cdList[0].update.price : myCollections[configData.index].collectionData[myCollectionIndex].price;
                        myCollections[configData.index].collectionData[myCollectionIndex].description = body.cdList[0].update?.description != null ? body.cdList[0].update.description : myCollections[configData.index].collectionData[myCollectionIndex].description;
                        // finding the allergen ids that are being deleted and removing them from the allergens array at the collectiondata
                        let deleteIds = configData.fields[3].selectedOptions.filter(so => so.status === GD_STATUS_TYPE.DELETE).map((allergen) => allergen.id);
                        myCollections[configData.index].collectionData[myCollectionIndex].allergens = myCollections[configData.index].collectionData[myCollectionIndex].allergens.filter((allergen) => !deleteIds.includes(allergen.aid));
                        // now we need to add the new allergens to the collection data. We just use the result from the backend here
                        console.log("INSERTIDLIST: ", result.allergenInsertIdList);
                        if(result.allergenInsertIdList?.length > 0){
                            myCollections[configData.index].collectionData[myCollectionIndex].allergens = myCollections[configData.index].collectionData[myCollectionIndex].allergens.concat(result.allergenInsertIdList);
                        }
                        this.setState({ collections: [...myCollections] });
                        this.props.closeDrawer();
                        this.props.showAlertDisplay("Kollektionselement erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                        // myCollections[configData.index].collectionData.allergens.filter((allergen) => allergen.aid != null).forEach((allergen) => {
                        // selectedOptions at index 3 for allergens filter them by status delete and remove them  from allergens array 
                        // filter them by status 1 and find the corresponding aid in the [
                        //     {
                        //         "id": 1,
                        //         "uniqueKey": "GlutenGrains",
                        //         "defaultLabel": "Glutenhaltige Getreide",
                        //         "dbId": 14
                        //     },
                        //     {
                        //         "id": 5,
                        //         "uniqueKey": "Rye",
                        //         "defaultLabel": "Roggen",
                        //         "dbId": 16,
                        //         "status": 3
                        //     },
                        //     {
                        //         "id": 6,
                        //         "uniqueKey": "Dinkel",
                        //         "defaultLabel": "Dinkel",
                        //         "dbId": 15,
                        //         "status": 3
                        //     },
                        //     {
                        //         "id": 11,
                        //         "uniqueKey": "Hazelnut",
                        //         "defaultLabel": "Haselnüsse",
                        //         "dbId": 17
                        //     },
                        //     {
                        //         "id": 9,
                        //         "uniqueKey": "BrazilNuts",
                        //         "defaultLabel": "Brasilianische Nüsse",
                        //         "status": 1
                        //     },
                        //     {
                        //         "id": 8,
                        //         "uniqueKey": "Almonds",
                        //         "defaultLabel": "Mandeln",
                        //         "status": 1
                        //     }
                        // ]
                        // result: {
                        //     "id": 1,
                        //     "allergenInsertIdList": [
                        //         {
                        //             "aid": 9,
                        //             "id": 48
                        //         },
                        //         {
                        //             "aid": 8,
                        //             "id": 49
                        //         }
                        //     ]
                        // }
                        // cd :{
                        //     "cdid": 1,
                        //     "price": 0.6,
                        //     "allergens": [
                        //         {
                        //             "id": 13,
                        //             "aid": 5
                        //         }
                        //     ],
                        //     "description": "Mais"
                        // }
                    }
                }).catch(e => {
                    console.log("Error while updating collection data: ", e);
                    this.props.showAlertDisplay("Fehler beim Aktualisieren des Kollektionselements.", ALERT_SEVERITY.ERROR);
                })
        }
    }
    openCollectionDataEditDrawer(mainIndex, row) {
        let defaultSelection = [];
        console.log("ALLERGEN ROW: ", row);
        row.allergens.forEach((allergen) => {
            defaultSelection.push({ ...this.allergens.find((allergenData) => {
                console.log("ALLERGENSEARCH: ",allergen);
               return  allergenData.id === allergen.aid
            }
            ), dbId: allergen.id });
        })
        let baseDrawerConfig = {
            index: mainIndex,
            cdid: row.cdid,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCollectionData(configData, row) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "cdid",
                    disabled: true,
                    value: row.cdid,
                },
                {
                    label: "Beschreibung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "description",
                    value: row.description,
                },
                {
                    label: "Preis",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "price",
                    isNumeric: true,
                    minValue: 0,
                    value: row.price,
                },
                {
                    type: GENERIC_DRAWER_TYPE.MULTICHECKBOX,
                    label: "Allergene",
                    dbKey: "allergens",
                    collapsed: false,
                    options: [...this.allergens],
                    optionLabelKey: 'defaultLabel',
                    optionUniqueKey: 'id',
                    selectedOptions: [...defaultSelection]
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    addCollectionDataInputConfig(configData, index) {
        let spliceIndex = index ? index : 3;
        let collectionImportSelectorIndex = configData.fields.findIndex((field) => field.type === GENERIC_DRAWER_TYPE.ENTITY_SELECTOR);
        if (collectionImportSelectorIndex != -1) {
            configData.fields.splice(collectionImportSelectorIndex, 1);
        }
        if(configData.options[0].identifier === "IMPORT"){
            configData.options.splice(0, 1); // remove import option
        }

        configData.fields.splice(spliceIndex, 0, {
            label: "Beschreibung",
            type: GENERIC_DRAWER_TYPE.TEXT,
            dbKey: "description",
            isRequired: true,
            identifier: CD_FIELD_IDS.DESCRIPTION,
            maxChar: 25,
            value: "",
        }, {
            label: "Preis",
            type: GENERIC_DRAWER_TYPE.TEXT,
            dbKey: "price",
            isNumeric: true,
            isRequired: true,
            identifier: CD_FIELD_IDS.PRICE,
            minValue: 0,
            value: '',
        }, {
            type: GENERIC_DRAWER_TYPE.MULTICHECKBOX,
            label: "Allergene",
            // todo collapsible...
            collapsed: false,
            dbKey: "allergens",
            options: [...this.allergens],
            optionLabelKey: 'defaultLabel',
            optionUniqueKey: 'id',
            identifier: CD_FIELD_IDS.ALLERGENS,
            selectedOptions: [] // ???
        });
        this.props.openDrawer(configData, GD_MODE.NEW);
    }
    importCollectionData(value, configData) {
        console.log("Import data from collection with id: ", value);
        // /shop/:sid/collections/getAll

        fetch(APIData.SHOP + `/shop/${this.shopId}/collection/${value}/getData`, { credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log("ErrStatus while loading collection data: ", response.status);
                }
            }).then(result => {
                if (result?.length > 0) {
                    result = result.map((collectionData) => {
                        collectionData.cdaData = JSON.parse(collectionData.cdaData);
                        return collectionData;
                    })
                    console.log("IMPORTDATA: ", result);
                    result.forEach((collectionData) => {
                        configData.fields.splice(3, 0, {
                            label: "Beschreibung",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "description",
                            isRequired: true,
                            identifier: CD_FIELD_IDS.DESCRIPTION,
                            value: collectionData.description,
                        }, {
                            label: "Preis",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "price",
                            isNumeric: true,
                            isRequired: true,
                            identifier: CD_FIELD_IDS.PRICE,
                            minValue: 0,
                            value: collectionData.price,
                        }, {
                            type: GENERIC_DRAWER_TYPE.MULTICHECKBOX,
                            label: "Allergene",
                            // todo collapsible...
                            collapsed: false,
                            dbKey: "allergens",
                            options: [...this.allergens],
                            optionLabelKey: 'defaultLabel',
                            optionUniqueKey: 'id',
                            identifier: CD_FIELD_IDS.ALLERGENS,
                            selectedOptions: collectionData.cdaData.map((allergen) => {
                                let index = this.allergens.findIndex((allergenData) => allergenData.id === allergen.aid)
                                if (index != -1) {
                                    return { ...this.allergens[index], status: GD_STATUS_TYPE.NEW }
                                }
                                // return {aid: allergen.aid}
                            })
                        });
                    })
                    let collectionImportSelectorIndex = configData.fields.findIndex((field) => field.type === GENERIC_DRAWER_TYPE.ENTITY_SELECTOR);
                    if (collectionImportSelectorIndex != -1) {
                        configData.fields.splice(collectionImportSelectorIndex, 1);
                    }
                    console.log("CONFIGDATAAFTERIMPORT: ", configData);
                    this.props.openDrawer(configData, GD_MODE.NEW);
                    // console.log("IMPORTDATA: ", result);
                }
            }).catch(e => {
                console.log("Error while loading collection data: ", e);
                this.props.showAlertDisplay("Fehler beim Importieren der Kollektionselemente.", ALERT_SEVERITY.ERROR);
            })
    }
    addCollectionDataImportSelector(configData) {
        // console.log("IMPORTCONFIGDATA: ", configData);
        configData.options.splice(0, 1); // remove import option
        if (configData.fields.length < 4) {
            configData.fields.splice(3, 0, {
                label: "Kollektion Auswählen",
                apiEndPoint: APIData.SHOP+ "/shop/" + this.shopId + "/collections/getAll",
                paginationKey: "offset",
                entityTitleKey: 'title', // actually not needed since default is name
                entityAdditionalTitleKey: 'info',
                entityTitleId: 'id', // actually not needed since default is id
                type: GENERIC_DRAWER_TYPE.ENTITY_SELECTOR,
                onUpdateCallback: (value, configData) => { this.importCollectionData(value, configData) },
                pageSize: 20,
                value: "",
                title: "",
                dbKey: ''
            })
            this.props.openDrawer(configData, GD_MODE.NEW);
        }

    }
    openCollectionAddDrawer() {

        let baseDrawerConfig = {
            options: [

                { label: "Importieren", identifier:"IMPORT", onClick: (configData) => { this.addCollectionDataImportSelector(configData) } },
                { label: "Element Hinzufügen", onClick: (configData) => { this.addCollectionDataInputConfig(configData) } },
                { label: "Speichern", onClick: (configData) => { this.addCollection(configData) } },
                // { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Titel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "title",
                    value: "",
                    isRequired: true,
                },
                {
                    label: "Info",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "info",
                    value: "",
                },
                {
                    label: "Optional",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isOptional",
                    value: true,
                },

            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    };
    addCollectionData(configData) {
        let body = {
            id: this.state.collections[configData.index].id,
            cdList: [
                {
                    inserted: true,
                    description: configData.fields[0].value,
                    price: configData.fields[1].value,
                    cdaList: []
                    // aid is being used if it's an insert
                    // id is being used if it's an delete for the allergen
                }
            ]
        }
        // cdList
        configData.fields[2].selectedOptions.forEach((allergen) => {
            body.cdList[0].cdaList.push({ aid: allergen.id });
        })
        // console.log("CONFIGDATABODY: ", body);
        fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(result => {
                if (result) {
                    console.log("INSERTRESULT: ", result);
                    let myCollections = [...this.state.collections];
                    myCollections[configData.index].collectionData.push(
                        { cdid: result.cdList[0].id, description: body.cdList[0].description, price: body.cdList[0].price, allergens: result.allergenInsertIdList?.length > 0 ? result.allergenInsertIdList : [] });
                    this.setState({ collections: [...myCollections] });
                    this.props.closeDrawer();
                    this.props.showAlertDisplay("Kollektionselement erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                }
            })

    }
    deleteCollectionData(mainIndex, row) {
        console.log("SUBDELEETE: ", mainIndex, row);
        let body = {
            id: this.state.collections[mainIndex].id,
            cdList: [
                {
                    delete: true,
                    id: row.cdid
                }
            ]
        }
        fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    let myCollections = [...this.state.collections];
                    myCollections[mainIndex].collectionData = myCollections[mainIndex].collectionData.filter((data) => data.cdid !== row.cdid);
                    this.setState({ collections: [...myCollections] });
                    this.props.showAlertDisplay("Kollektionselement erfolgreich gelöscht.", ALERT_SEVERITY.SUCCESS);
                } else {
                    console.log("ErrStatus while deleting category data: ", response.status);
                    this.props.showAlertDisplay("Fehler beim Löschen vom Kollektionselement.", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while deleting category data: ", e);
                this.props.showAlertDisplay("Fehler beim Löschen vom Kollektionselement.", ALERT_SEVERITY.ERROR);
            })
    }

    openCollectionDataAddDrawer(mainIndex) {
        let baseDrawerConfig = {
            index: mainIndex,
            options: [
                { label: "Speichern", onClick: (configData) => { this.addCollectionData(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Beschreibung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "description",
                    value: "",
                    isRequired: true,
                },
                {
                    label: "Preis",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "price",
                    isNumeric: true,
                    isRequired: true,
                    minValue: 0,
                    value: '',
                },
                {
                    type: GENERIC_DRAWER_TYPE.MULTICHECKBOX,
                    label: "Allergene",
                    // todo collapsible...
                    collapsed: true,
                    dbKey: "allergens",
                    options: [...this.allergens],
                    optionLabelKey: 'defaultLabel',
                    optionUniqueKey: 'id',
                    selectedOptions: []
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    adjustPrices(type, value, row, index) {
        console.log("TYPE: ", type, " VALUE: ", value, " ROW: ", row, " INDEX: ", index);
        let body = {
            id: this.state.collections[index].id,
            generalPriceUpdate: 0.0,
        }
        body.generalPriceUpdate = type === GPA_OPTIONS.INCREASE ? parseFloat(value) : parseFloat(value) * -1;
        fetch(APIData.SHOP + `/shop/${this.shopId}/collections/updateOrCreate`, { method: 'POST', credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    let myCollections = [...this.state.collections];
                    console.log("MYCOLLECTIONS: ", myCollections[index].collectionData);
                    myCollections[index].collectionData = myCollections[index].collectionData.map((cd) => {
                        return { ...cd, price: (parseFloat(cd.price) + body.generalPriceUpdate) };
                    })
                    // this.setState({collections: [...myCollections]});
                    myCollections[index].collectionData.push({ cdid: 0, description: "RERENDERELEM", price: 0.0, allergens: [] });
                    this.setState({ collections: [...myCollections] }, () => {
                        // This is done to rerender, since the subtable only rerenders, if the length changes... Maybe fix this later..
                        // This is a workaround for now.
                        myCollections[index].collectionData.pop();
                        this.setState({ collections: [...myCollections] });
                    });
                    this.props.showAlertDisplay("Preis erfolgreich angepasst.", ALERT_SEVERITY.SUCCESS);
                }
            }).catch(e => {
                console.log("Error while adjusting prices: ", e);
                this.props.showAlertDisplay("Fehler beim Anpassen der Preise.", ALERT_SEVERITY.ERROR);
            })
    }
    duplicateCollection(index, rowId) {
        fetch(APIData.SHOP + `/shop/${this.shopId}/collection/${rowId}/duplicate`, { credentials: "include" })
        .then(response => {
            if(response.ok){
                return response.json();
            }else{
                console.log("ErrStatus while duplicating collection: ", response.status);
                throw new Error("Error while duplicating collection: " + response.status);
            }
        }).then(result => {
            if(result){
                console.log("DUPLICATE COLLECTION RESLT: ",result);
                // this.setState({ collections: [...this.state.collections, { id: result.id, title: body.name, info: body.info ? body.info : "", isOptional: body.isOptional, isMultiSelection: body.isMultiSelection ? true : false, collectionData: [] }] });
                let myCollections = [...this.state.collections];
                let addedCollection = {...myCollections[index], id: result.id, title: (myCollections[index].title + " (Kopie)").substring(0,40), collectionData: result.cdList.map((cd) => {
                    let myCd = {...cd};
                    myCd.cdid = cd.id;
                    delete myCd.id;
                    return myCd;
                })};
                myCollections.splice(index+1, 0, addedCollection);
                this.setState({collections: [...myCollections]});
                this.props.showAlertDisplay("Kollektion erfolgreich dupliziert.", ALERT_SEVERITY.SUCCESS);
            }
        })
        .catch(e => {
            console.log("Error while duplicating collection: ", e);
            this.props.showAlertDisplay("Fehler beim Duplizieren der Kollektion.", ALERT_SEVERITY.ERROR);
        })
    }
    render() {
        return (
            <CollapsibleTile
                title="Kollektionen"
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../assets/icons/box.png')} width={20} height={20} />}
            >
                <NestedGenericTable
                    customSubTableFooterComponent={(row, index) => {
                        return <GeneralPriceAdjustmentHeader openAlertDialog={this.props.openAlertDialog} showAlertDisplay={this.props.showAlertDisplay} onSave={(type, value) => { this.adjustPrices(type, value, row, index) }} />
                    }}
                    config={{ columns: [...mainColumns], subColumns: [...subColumns], subTableKey: 'collectionData' }}
                    tableData={this.state.collections}
                    setTableData={(data) => { 
                        console.log("COLLECTIONROWDATA: ", data);
                        this.setState({ collections: data }) 
                    }}
                    apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/collections/getPaged`}
                    getSubRowId={(row) => row.cdid}
                    // onEdit={(index, rowId) => { console.log("Edit: ", index, rowId) }}
                    onDuplicate={(index, rowId) => { 
                        this.props.openAlertDialog(
                            [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        // console.log("REGEL wird gelöscht!");
                                        this.duplicateCollection(index, rowId) 
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Kollektion wird nicht dupliziert...");
                                    }
                                }
                            ],
                            "Duplizieren", `Möchten Sie die Kollektion '${this.state.collections[index].id} - ${this.state.collections[index].title}' wirklich duplizieren?`);
                        
                    }}
                    onDelete={(index, rowId) => {
                        this.props.openAlertDialog(
                            [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        // console.log("REGEL wird gelöscht!");
                                        this.deleteCollection(index);
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Kollektion wird nicht gelöscht...");
                                    }
                                }
                            ],
                            "Löschen", 
                            `Möchten Sie die Kollektion '${this.state.collections[index].id} - ${this.state.collections[index].title}' wirklich löschen?`
                        );
                        }
                    }
                    addBtnLabel={"Kollektion Hinzufügen"}
                    addSubBtnLabel={"Kollektionselement Hinzufügen"}
                    onEdit={(index, rowId) => { this.openCollectionEditDrawer(index) }}
                    onSubDelete = { (mainIndex, row) => {
                            this.props.openAlertDialog (
                                [
                                    {
                                        label: "Ja",
                                        color: MUI_COLORS.ERROR,
                                        onChoice: () => {
                                            // console.log("REGEL wird gelöscht!");
                                            this.deleteCollectionData(mainIndex, row);
                                        }
                                    },
                                    {
                                        label: "Nein",
                                        onChoice: () => {
                                            console.log("Kollektionselement wird nicht gelöscht...");
                                        }
                                    }
                                ],
                                "Löschen", "Möchten Sie das Kollektionselement wirklich löschen?"
                            );
                        }
                    }
                    onSubEdit={(mainIndex, row) => {
                        console.log("ONSUBEDIT: ", mainIndex, row);
                        this.openCollectionDataEditDrawer(mainIndex, row);
                    }}
                    addBtnOnClick={() => { this.openCollectionAddDrawer() }}
                    addSubBtnOnClick={(index) => { this.openCollectionDataAddDrawer(index) }}
                />
            </CollapsibleTile>
        )
    }
}

export default CollectionsTile