import React, { Component } from 'react'
import "./assets/css/general.css";
import Header from './components/Header';
import { Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import TileButton from './components/TileButton';
import { setRole } from './redux/permissionSlice';
import { PermissionAreaComponent } from './hookInjection/PermissionAreaComponent';

import DefaultFrame from './components/DefaultFrame';



class Dashboard extends Component {
    constructor(props) {
        super(props);
    }
    withPermission
    render() {
        return (
            
            <DefaultFrame title="Dashboard">
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "2.5%" }}>
                    <TileButton path="/support" title="Support" iconSrc={require("./assets/icons/support.png")} />
                    <TileButton path="/conflicts" title="Konflikte" iconSrc={require("./assets/icons/customerservice.png")} />
                    <TileButton path="/users" title="Benutzerverwaltung" iconSrc={require("./assets/icons/team.png")} />
                    <TileButton path="/orders" title="Bestellverwaltung" iconSrc={require("./assets/icons/box.png")} />
                    <TileButton path="/partners" title="Partnermanagement" iconSrc={require("./assets/icons/store.png")} />
                    <TileButton path="/data" title="Datenverwaltung" iconSrc={require("./assets/icons/dashboard.png")} />
                </div>
            </DefaultFrame>
        )
    }
}
function mapStateToProps(state) {
    return {
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
