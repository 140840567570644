import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, fontSize, fontWeight } from '@mui/system';
import { Button, Checkbox, CircularProgress, Collapse, IconButton, MenuItem, Radio, Select, TableBody, TextField, Typography } from '@mui/material';
import { Add, Delete, Style } from '@mui/icons-material';
// import './GenericTable.css';
import { styled } from '@mui/material/styles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { APIData } from '../../../../constants/APIAdresses';
import { ALERT_SEVERITY } from '../../../../components/AlertDisplay2';
import { setCategories, setSelectedCategories } from '../../../../redux/productSlice';
import { connect } from 'react-redux';
// import GTTableHeader from './GTTableHeader';
// import GTTableBody from './GTTableBody';
// import NTTableBody from './NTTableBody';
// import NTTableHeader from './NTTableHeader';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

export const NGT_ACTIVETYPES = {
    SWITCH: "SWITCH",
    CHECKBOX: "CHECKBOX",
    RADIO: "RADIO",
}

const columns = [
    {
        width: 150,
        label: 'Kategorie-ID.',
        dataKey: 'id',
    },
    {
        // width: 200,
        label: 'Kategorie',
        dataKey: 'name',
    }
]
const subcolumns = [
    {
        // width: 50,
        width: 150,
        label: 'Unterkategorie-ID',
        dataKey: 'scKey',
    },
    {
        // width: 200,
        label: 'Unterkategorie',
        dataKey: 'scName',
    }
]
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#b9d3e4',
        // backgroundColor: '#adbdc8',
        color: '#5a5a5a',
        // fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const itemsPerPageOptions = [10, 25, 50, 100];
export class CategorySelectorTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            searchText: "",
            pageIndex: 0,
            pageCount: 2,
            itemsPerPage: 10,
            // data: []
        }
    }
    searchText(text) {
        this.setState({ searchText: text });
        if (text.length >= 3) {
            this.setState({ pageIndex: 0 });
            this.loadAndSetData(text, 0, this.state.itemsPerPage);
        } else {
            this.setState({ pageIndex: 0 });
            this.loadAndSetData(null, 0, this.state.itemsPerPage);
        }
    }
    loadAndSetData(searchText, pageIndex, itemsPerPage) {
        let query = (searchText != null ? "?search=" + searchText : "");
        query += (pageIndex != null ? (query.length > 0 ? "&" : "?") + "page=" + pageIndex : "");
        query += (itemsPerPage != null ? (query.length > 0 ? "&" : "?") + "ipp=" + itemsPerPage : "");
        this.setState({ isLoading: true });
        fetch(this.props.apiEndPoint + query, { credentials: "include", headers: { "Content-Type": "application/json" } })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then((result) => {
                result.data = typeof result.data === "string" ? JSON.parse(result.data) : result.data;
                // if(result.data?.length > 0){
                // }
                this.props.setTableData(result.data);
                this.setState({
                    pageCount: Math.ceil(result.totalcount / this.state.itemsPerPage),
                    isLoading: false,
                })
            }).catch((error) => {
                console.log("Error while loading table: ", error);
                this.setState({ isLoading: false });
            });
    }
    componentDidMount() {
        this.loadAndSetData(null, 0, this.state.itemsPerPage);
    }
    onNextPage() {
        this.loadAndSetData(null, this.state.pageIndex + 1, this.state.itemsPerPage);
        this.setState({ pageIndex: this.state.pageIndex + 1 })
    }
    onPrevPage() {
        this.loadAndSetData(null, this.state.pageIndex - 1, this.state.itemsPerPage);
        this.setState({ pageIndex: this.state.pageIndex - 1 })
    }
    onIPPChange(value) {
        this.setState({ itemsPerPage: value, pageIndex: 0 });
        this.loadAndSetData(this.state.searchText, 0, value);
    }
    setCatActive(row, subcatRow){
        let selectedIndex = this.props.selectedOptions.findIndex((so) => so.cid === row.id);
        let selectedOptions = [...this.props.selectedOptions];
        if(selectedIndex !== -1){
            // then we already have it otherwise it's a push
            if(subcatRow != null){
                if(subcatRow === -1){
                    console.log("ADDING DELETE TO : ",selectedOptions);
                    selectedOptions[selectedIndex].delete = true;
                    delete selectedOptions[selectedIndex].update;
                }
                else if(subcatRow === 0){
                    delete selectedOptions[selectedIndex].delete;
                    if(selectedOptions[selectedIndex].scKey != null){
                        selectedOptions[selectedIndex].update = {deleteSubcat: true};
                    }
                    // this is the no selection selector
                    // if(selectedOptions[selectedIndex].scKey ){
                    console.log("#### NO SELECTION SELECTOR ");
                    if(selectedOptions[selectedIndex].delete != null){
                        selectedOptions[selectedIndex].delete = null;
                    }
                    // if(selectedOptions[selectedIndex].delete != null){
                        // selectedOptions[selectedIndex].delete = null;
                    // }
                }else{
                    console.log("#### SUBCAT SELECTOR");
                    if(selectedOptions[selectedIndex].scKey !== subcatRow.subcatKey){
                        delete selectedOptions[selectedIndex].delete;
                        selectedOptions[selectedIndex].update = {scKey: subcatRow.subcatKey};
                    } else if(selectedOptions[selectedIndex].update?.scKey != null){
                        delete selectedOptions[selectedIndex].update;
                    }
                    // this is a subcategory selector
                }
            }else {
                console.log("#### CAT SELECTOR");
                if(selectedOptions[selectedIndex].insert){
                    selectedOptions.splice(selectedIndex, 1);
                }else{
                    if(selectedOptions[selectedIndex].delete == null){
                        selectedOptions[selectedIndex].delete = true;
                        // selectedOptions.push({"cid": row.id, "name":row.name, "scKey": null, "scName": null});
                    }else {
                        delete selectedOptions[selectedIndex].delete;
                    }
                }
                // this is a category selector, it doesn't have any subcategories
                // if(selectedOptions[selectedIndex].delete != null){

                // }
            }

        }else{
            if(subcatRow == null){
                console.log("ARE WE HERE??");
                selectedOptions.push({"cid": row.id, "name":row.name, "scKey": null, "scName": null, insert: true});
            }else{
                console.log("SUBCATROOOOW: ", subcatRow);
                selectedOptions.push({"cid": row.id, "name":row.name, "scKey": subcatRow.subcatKey, "scName": subcatRow.name, insert: true});
            }

        }
        this.props.setSelectedCategories([...selectedOptions])
        console.log("SETTING ACTIVE: ", row, " SUBCATROW: ",subcatRow);
        // this.props.onSetActive(row);
    }
    render() {
        return (
            <Paper style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1, maxHeight: 70, flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#f7fafc', padding: 10, borderTopRightRadius: 4, borderTopLeftRadius: 4 }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                        <TextField size='small' style={{ backgroundColor: "#fff", borderRadius: 4, width: "20%", minWidth: 250 }} onChange={(evt) => { this.searchText(evt.target.value) }} variant='outlined' label="Suche" />
                    </div>
                </div>
                <TableContainer sx={{ flex: 1, scrollbarWidth: 'none', msOverflowStyle: 'none' }} component={Box}>
                    <Table stickyHeader sx={{ minWidth: 700, }} size='small' aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}>Aktiv</StyledTableCell>
                                {columns.map((column) => {
                                    return <StyledTableCell sx={column.width != null ? { width: column.width } : {}} key={column.dataKey}  >{column.label}</StyledTableCell>
                                })}
                                <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.tableData?.map((row, index) => {
                                return <Row key={row.id}
                                    row={row}
                                    columns={subcolumns}
                                    selectedOptions={this.props.selectedOptions}
                                    activeCompDisabled={this.props.activeCompDisabled}
                                    activeKey={this.props.activeKey}
                                    // onSetActive={(value, pvrow, isUpdate) => { this.setProductActive(row, index, value, pvrow, isUpdate) }}
                                    onSetActive={(row, subcatRow) => { this.setCatActive(row, subcatRow) }}
                                    onDelete={this.props.onDelete}
                                    onEdit={this.props.onEdit}
                                    onPositionChange={this.props.onPositionChange} />
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
                <div style={{ height: 65, borderTop: "0.5px solid #cecece", display: "flex", color: '#676767', justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: 20 }}>
                        <Typography >Zeilananzahl:</Typography>
                        <Select onChange={(evt) => { this.onIPPChange(evt.target.value) }} style={{ marginLeft: 10 }} value={this.state.itemsPerPage} size='small' label="Zeilenanzahl">
                            {itemsPerPageOptions.map((item) => {
                                return <MenuItem key={"ippo_" + item} value={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Button onClick={() => { this.onPrevPage() }} disabled={this.state.pageIndex <= 0 || this.state.isLoading}>
                            {'<'}
                        </Button>
                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                            Seite{' '}
                            <strong>
                                {this.state.pageIndex + 1} / {this.state.pageCount}
                            </strong>{' '}
                        </span>
                        <Button onClick={() => { this.onNextPage() }} disabled={this.state.pageIndex + 1 >= this.state.pageCount || this.state.isLoading}>
                            {'>'}
                        </Button>
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
                {this.state.isLoading &&
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "50%",
                        backgroundColor: "rgba(0,0,0,0.4)",
                        padding: 10,
                        zIndex: 1, // Ensure it's above other elements
                    }}>
                        <CircularProgress size={25} />
                    </div>
                }
            </Paper>
        );
    }
}
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    // console.log("ROW::: "   , row);
    return (<React.Fragment key={row.id}>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.id}
            style={{ cursor: 'pointer', }}>

            <StyledTableCell onClick={() => {
                if (row.subcategories.length > 0) {
                    setOpen(!open);
                } else {
                    props.onSetActive(row);
                    // props.onSetActive((row.isInGroupCat === "1" ? "0" : "1"))
                }
            }} style={{ textAlign: "center" }}>
                <Checkbox color="primary" checked={props.selectedOptions?.some(so => { console.log("BEFOREERR: ",so); return so?.cid === row.id && so.delete == null })} />
            </StyledTableCell>
            {columns.map((column) => {
                return <StyledTableCell onClick={() => { props?.onClick(); }} key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey]}</StyledTableCell>
            })}
            <StyledTableCell onClick={() => {props.onSetActive(row, -1)}} style={{ textAlign: "center" }}>
                <IconButton color='primary' >
                    <Delete/>
                </IconButton>
            </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
            <StyledTableCell style={{ padding: 0 }} colSpan={Object.keys(row)?.length + 2}>
                <Collapse key={"PVSNTBody_Collapse_" + props.index + "_" + row[props.subTableKey]?.length} in={open} timeout="auto" unmountOnExit>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 15, justifyContent: "center", padding: 25 }}>
                        <div onClick={() => {
                            props.onSetActive(row, 0);
                        }}
                            style={{ textDecoration: "none", flexGrow: 1, flexDirection: "row", maxWidth: 250, padding: 20, borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", flexBasis: 250, backgroundColor: "#ebf1f5" }}>
                            <Radio color='primary' checked={(() => {
                                let index = props.selectedOptions?.findIndex((so) => so?.cid === row.id);
                                if (index !== -1 ) {
                                    if(props.selectedOptions[index].delete != null || props.selectedOptions[index].update?.deleteSubcat == true){
                                        return true;
                                    }
                                    return props.selectedOptions[index].scKey == null && props.selectedOptions[index].update?.scKey == null;
                                } else {
                                    return true;
                                }
                            }) ()} />
                            <Typography>Kein Auswahl</Typography>
                        </div>
                        {row.subcategories?.map((subRow, index) => {
                            return <div onClick={() => {
                                props.onSetActive(row, subRow);
                                // props.onSetActive("1", subRow, (row.pvId != null && row.isInGroupCat === '1'))
                            }} key={"VD_Selector_" + Object.values(subRow).join("")} style={{ textDecoration: "none", flexGrow: 1, flexDirection: "row", maxWidth: 250, padding: 20, borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", flexBasis: 250, backgroundColor: "#ebf1f5" }}>
                                <Radio color='primary' checked={(() => {
                                    let index = props.selectedOptions?.findIndex((so) => so?.cid === row.id);
                                    if (index !== -1) {
                                        if(props.selectedOptions[index].delete != null || props.selectedOptions[index].update?.deleteSubcat == true){
                                            return false;
                                        }
                                        return props.selectedOptions[index].scKey == subRow.subcatKey && props.selectedOptions[index].update?.scKey == null || props.selectedOptions[index].update?.scKey == subRow.subcatKey;
                                    } else {
                                        return false;
                                    }
                                })()} />
                                <Typography>{subRow.name}</Typography>
                            </div>
                        })}
                    </div>
                </Collapse>
            </StyledTableCell>
        </StyledTableRow>
    </React.Fragment>)

}
function mapStateToProps(state) {
    return {
        tableData: state.product.categories,
        selectedOptions: JSON.parse(JSON.stringify(state.product.selectedCategories)),
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setTableData: (data) => {
            dispatch(setCategories(data));
        },
        setSelectedCategories: (data) => {
            dispatch(setSelectedCategories(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategorySelectorTable)
