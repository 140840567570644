import React, { Component } from 'react'
import CollapsibleTile from '../../components/CollapsibleTile';
import GenericTable from '../../components/GenericTable/GenericTable';
import { APIData } from '../../constants/APIAdresses';
import { Card, Checkbox, Switch, Typography } from '@mui/material';
import ShopSelector from '../../components/ShopSelector';
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../components/GenericDrawer';
import { Close, Save } from '@mui/icons-material';
import { ALERT_SEVERITY } from '../../components/AlertDisplay2';
import { MUI_COLORS } from '../../constants/MUIConstants';

const columns = [
    { dataKey: 'id', label: 'ID', width: 120 },
    { dataKey: 'shopId', label: 'Shop-ID', width: 150 },
    { dataKey: 'shopName', label: 'Shop', },
    { dataKey: 'name', label: 'Rabattbezeichnung' },
    { dataKey: 'description', label: 'Beschreibung' },
    { dataKey: 'totalSumGreaterRule', label: 'Gesamtsummenregel', type: 'number' },
    { dataKey: 'percent', label: 'Prozent %' },
    { dataKey: 'price', label: 'Preis' },
    { dataKey: 'isOptional', label: 'Optional', format: (value) => { return value ? "Ja" : "Nein" } },
    { dataKey: 'editable', label: 'Bearbeitbar', format: (value) => { return value ? "Ja" : "Nein" } },

]
export class PromotionsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotionData: [],
            activeFilterEnabled: false,
            activeFilter: true,
            showTable: false,
        }
        this.HASPRESELECTEDSHOP = this.props.shopId != null && this.props.shopName != null;
        this.GD_SELECTEDSHOPVALUE = this.HASPRESELECTEDSHOP ? { id: this.props.shopId, name: this.props.shopName } : null;

    }
    loadData() {
        // Load data from API
    }
    componentDidUpdate(prevProps, prevState) {
        this.GD_SELECTEDSHOPVALUE = this.props.shopId != null && this.props.shopName != null ? { id: this.props.shopId, name: this.props.shopName } : null;
        console.log("GD_SELECTEDSHOPVALUE: ", this.GD_SELECTEDSHOPVALUE);
        // if (this.props.shopId != prevProps.shopId && this.props.shopName != prevProps.shopName) {
        //     this.HASPRESELECTEDSHOP = this.props.shopId != null && this.props.shopName != null;
        //     console.log("HASPRESELECTEDSHOP: ", this.HASPRESELECTEDSHOP);
        //     console.log("GD_SELECTEDSHOPVALUE: ", this.GD_SELECTEDSHOPVALUE);
        // }
    }
    updatePromotion(body, index, rowId, rowOld) {

        let myPromotionData = [...this.state.promotionData];
        // Update promotion
        fetch(APIData.SHOP + `/promotion/${rowId}/update`, { credentials: 'include', method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                this.props.closeDrawer();
                if (response.ok) {
                    this.props.showAlertDisplay("Verkaufsaktion erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                } else {
                    throw new Error("Error while updatePromotion (" + response.status + "): ", response);
                }
            }).catch(e => {
                myPromotionData[index] = rowOld;
                this.setState({ promotionData: [...myPromotionData] });
                this.props.showAlertDisplay("Die Verkaufsaktion konnte nicht aktualisiert werden.", ALERT_SEVERITY.ERROR);
                console.error("Error while updatePromotion: ", e);
            })
    }
    __updatePromotionDrawer(configData, index, rowId) {
        // Update promotion drawer
        let body = {
            fields: {}
        }
        configData.fields.filter((item) => { return item.status === GD_STATUS_TYPE.UPDATE }).forEach((item) => {
            body.fields[item.dbKey] = item.value === "" ? null : item.value;
        });
        // 
        if (this.GD_SELECTEDSHOPVALUE?.id != this.state.promotionData[index].shopId) {
            body.fields.shopId = this.GD_SELECTEDSHOPVALUE?.id != null ? this.GD_SELECTEDSHOPVALUE?.id : null;
        }
        if (Object.keys(body.fields).length > 0) {
            console.log("BODY: ", body);
            let myPromotionData = [...this.state.promotionData];
            let rowOld = JSON.parse(JSON.stringify(this.state.promotionData[index]));
            myPromotionData[index] = { ...myPromotionData[index], ...body.fields };
            if (this.GD_SELECTEDSHOPVALUE?.id != this.state.promotionData[index].shopId) {
                myPromotionData[index].shopId = this.GD_SELECTEDSHOPVALUE?.id;
                myPromotionData[index].shopName = this.GD_SELECTEDSHOPVALUE?.name;
                this.GD_SELECTEDSHOPVALUE = null;
            }
            this.setState({ promotionData: [...myPromotionData] });
            this.updatePromotion(body, index, rowId, rowOld);
        } else {
            this.props.showAlertDisplay("Keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
            this.props.closeDrawer();
        }
    }
    openEditDrawer(index, rowId) {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Speichern",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.__updatePromotionDrawer(configData, index, rowId) }
                }, {
                    label: "Abbrechen",
                    icon: <Close />,
                    onClick: (configData) => {
                        if (!this.HASPRESELECTEDSHOP) {
                            this.GD_SELECTEDSHOPVALUE = null;
                        }
                        this.props.closeDrawer()
                    }
                }
            ],
            fields: [
                {
                    label: "ID",
                    disabled: true,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: rowId,
                    dbKey: "id",
                },
                ...(this.props.setShopId ? [{
                    label: "Shop",
                    id: "shopSelector",
                    type: GENERIC_DRAWER_TYPE.CUSTOM,
                    customComponent: <div style={{ marginTop: 10 }}>
                        {this.state.promotionData[index].shopId != null ?
                            <ShopSelector preSelectedShopId={this.state.promotionData[index].shopId} onSelectValue={(value) => { console.log("SHOPSELECTORVALUE: ", value); this.GD_SELECTEDSHOPVALUE = value }} />
                            : <ShopSelector onSelectValue={(value) => { console.log("SHOPSELECTORVALUE: ", value); this.GD_SELECTEDSHOPVALUE = value }} />
                        }
                    </div>,
                }] : []),
                {
                    label: "Rabattbezeichnung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.promotionData[index].name,
                    dbKey: "name",
                    isRequired: true
                },
                {
                    label: "Beschreibung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.promotionData[index].description,
                    dbKey: "description",
                    isRequired: false
                },
                {
                    label: "Gesamtsummenregel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    value: this.state.promotionData[index].totalSumGreaterRule,
                    dbKey: "totalSumGreaterRule",
                    // isRequired: true
                },
                {
                    label: "Prozent %",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    maxValue: 99.99,
                    value: this.state.promotionData[index].percent,
                    dbKey: "percent",
                    // isRequired: true
                },
                {
                    label: "Preis",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    value: this.state.promotionData[index].price,
                    dbKey: "price",
                    // isRequired: true
                },
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.promotionData[index].isActive,
                    dbKey: "isActive",
                },
                {
                    label: "Optional",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.promotionData[index].isOptional,
                    dbKey: "isOptional",
                },
                {
                    label: "Bearbeitbar",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.promotionData[index].editable,
                    dbKey: "editable",
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    addPromotion(configData) {
        let body = {
            fields: {
                "shopId": this.GD_SELECTEDSHOPVALUE != null ? this.GD_SELECTEDSHOPVALUE.id : null,
                "name": null,
                "description": null,
                "totalSumGreaterRule": null,
                "percent": null,
                "price": null,
                "isActive": null,
                "isOptional": null,
                "editable": null
            }
        }
        configData.fields.forEach((item) => {
            if (item.id !== "shopSelector") {
                body.fields[item.dbKey] = item.value === "" ? null : item.value;
            }
        })
        if (body.fields.name != null) {
            console.log("ADD PROMOTION: ", body);
            fetch(APIData.SHOP + "/promotion/add", { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Error while addPromotion (" + response.status + "): ", response);
                    }
                }).then(result => {
                    this.props.closeDrawer();
                    if (result.insertId != null) {
                        this.props.showAlertDisplay("Verkaufsaktion erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                        let myPromotionData = [...this.state.promotionData];
                        myPromotionData.push({ id: result.insertId, shopName: this.GD_SELECTEDSHOPVALUE.name, ...body.fields });
                        this.setState({ promotionData: [...myPromotionData] });
                        if (!this.HASPRESELECTEDSHOP) {
                            this.GD_SELECTEDSHOPVALUE = null;
                        }
                    } else {
                        this.props.showAlertDisplay("Die Verkaufsaktion konnte nicht hinzugefügt werden (no-id).", ALERT_SEVERITY.ERROR)
                        throw new Error("Error while addPromotion (no-id): ", result);
                    }
                }).catch(e => {
                    console.error("Error while addPromotion: ", e);
                    this.props.closeDrawer();
                    this.props.showAlertDisplay("Die Verkaufsaktion konnte nicht hinzugefügt werden.", ALERT_SEVERITY.ERROR)
                })
        }
    }
    openAddDrawer() {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Speichern",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.addPromotion(configData) }
                }, {
                    label: "Abbrechen",
                    icon: <Close />,
                    onClick: (configData) => {
                        if (!this.HASPRESELECTEDSHOP) {
                            this.GD_SELECTEDSHOPVALUE = null;
                        }
                        this.props.closeDrawer()
                    }
                }
            ],
            fields: [
                ...(this.props.setShopId ? [{
                    label: "Shop",
                    id: "shopSelector",
                    type: GENERIC_DRAWER_TYPE.CUSTOM,
                    customComponent: <div style={{ marginTop: 10 }}>
                        <ShopSelector onSelectValue={(value) => { this.GD_SELECTEDSHOPVALUE = value }} />
                    </div>,
                }] : []),
                {
                    label: "Rabattbezeichnung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "name",
                    isRequired: true
                },
                {
                    label: "Beschreibung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "description",
                    isRequired: false
                },
                {
                    label: "Gesamtsummenregel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    value: "",
                    dbKey: "totalSumGreaterRule",
                    // isRequired: true
                },
                {
                    label: "Prozent %",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    maxValue: 99.99,
                    value: "",
                    dbKey: "percent",
                    // isRequired: true
                },
                {
                    label: "Preis",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    value: "",
                    dbKey: "price",
                    // isRequired: true
                },
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "isActive",
                },
                {
                    label: "Optional",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "isOptional",
                },
                {
                    label: "Bearbeitbar",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "editable",
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    deletePromotion(index, rowId) {
        let myData = [...this.state.promotionData];
        let rowOld = JSON.parse(JSON.stringify(this.state.promotionData[index]));
        myData.splice(index, 1);
        this.setState({ promotionData: [...myData] });
        fetch(APIData.SHOP + `/promotion/${rowId}/delete`, { credentials: 'include', method: 'DELETE' })
            .then(response => {
                if (response.ok) {
                    this.props.showAlertDisplay("Verkaufsaktion erfolgreich gelöscht.", ALERT_SEVERITY.SUCCESS);
                } else {
                    this.props.showAlertDisplay("Die Verkaufsaktion konnte nicht gelöscht werden.", ALERT_SEVERITY.ERROR);
                    throw new Error("Error while deletePromotion (" + response.status + "): ", response);
                }
            }).catch(e => {
                myData.splice(index, 0, rowOld);
                this.setState({ promotionData: [...myData] });
                console.error("Error while deletePromotion: ", e);
                this.props.showAlertDisplay("Die Verkaufsaktion konnte nicht gelöscht werden.", ALERT_SEVERITY.ERROR);
            })
    }
    render() {
        return (
            <CollapsibleTile
                onCollapseChange={(expanded, firstExpand) => {
                    if(expanded && firstExpand && !this.state.showTable) {
                        this.setState({showTable: true});
                    }
                 }}
                title="Verkaufsaktionen"
                icon={<img src={require('../../assets/icons/discount_orange.png')} width={20} height={20} />}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <Card style={{ padding: 20, }}>
                        <Typography>Filter:</Typography>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ flexDirection: "row", marginLeft: -10, display: "flex", alignItems: "center" }}>
                                <Checkbox checked={this.state.activeFilterEnabled} onChange={(evt) => { this.setState({ activeFilterEnabled: evt.target.checked }) }} />
                                <Typography>Aktiv</Typography>
                                <Switch disabled={!this.state.activeFilterEnabled} checked={this.state.activeFilter} onChange={(evt) => { this.setState({ activeFilter: evt.target.checked }) }} />
                            </div>
                            {
                                this.props.setShopId &&

                                <div style={{ flex: 1 }}>
                                    <ShopSelector onSelectShopId={id => { this.props.setShopId(id) }} />
                                </div>
                            }
                        </div>
                    </Card>
                    {this.state.showTable &&
                        <GenericTable

                            config={{ columns: [...columns] }}
                            apiEndPoint={APIData.SHOP + "/promotions/getPaged" +
                                (this.props.shopId != null ? "?shopId=" + this.props.shopId : "") +
                                (this.state.activeFilterEnabled ? (this.props.shopId != null ? "&" : "?") + "isActive=" + (this.state.activeFilter ? "1" : "0") : "")
                            }
                            onLoadError={(e) => { this.props.showAlertDisplay("Fehler beim Laden von Promotionen.", ALERT_SEVERITY.ERROR) }}
                            tableData={this.state.promotionData}
                            setTableData={(data) => { this.setState({ promotionData: data }) }}
                            activeKey="isActive"
                            onSetActive={(index, isActive) => {
                                this.updatePromotion({ fields: { isActive: isActive ? 1 : 0 } }, index, this.state.promotionData[index].id, JSON.parse(JSON.stringify(this.state.promotionData[index])));
                                let myPromotionData = [...this.state.promotionData];
                                myPromotionData[index].isActive = isActive;
                                this.setState({ promotionData: [...myPromotionData] });
                            }}
                            onDelete={(index, rowId) => {
                                this.props.openAlertDialog(
                                    [
                                        {
                                            label: "Ja",
                                            color: MUI_COLORS.ERROR,
                                            onChoice: () => {
                                                this.deletePromotion(index, rowId);
                                            }
                                        },
                                        {
                                            label: "Nein",
                                            onChoice: () => {
                                                console.log("Promotion wird nicht gelöscht...");
                                            }
                                        }
                                    ],
                                    "Löschen", `Möchten Sie das Promotion '${this.state.promotionData[index].id} - ${this.state.promotionData[index].name}' wirklich löschen?`)
                                console.log("index: ", index, " rowId: ", rowId)
                            }}
                            addBtnLabel="Promotion Hinzufügen"
                            addBtnOnClick={() => {
                                this.openAddDrawer();
                            }}
                            onEdit={(index, rowId) => {
                                this.openEditDrawer(index, rowId);
                            }}
                        />
                    }
                </div>
            </CollapsibleTile>
        )
    }
}

export default PromotionsTile