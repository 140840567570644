// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px; /* Space between grid items */
    width: 100%;
}

/* .grid-item { */
    /* background-color: #f0f0f0; */
    /* padding: 20px; */
    /* border: 1px solid #ccc; */
    /* text-align: center;
} */`, "",{"version":3,"sources":["webpack://./src/areas/GridLayout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2DAA2D;IAC3D,SAAS,EAAE,6BAA6B;IACxC,WAAW;AACf;;AAEA,iBAAiB;IACb,+BAA+B;IAC/B,mBAAmB;IACnB,4BAA4B;IAC5B;GACD","sourcesContent":[".grid-container {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    gap: 16px; /* Space between grid items */\n    width: 100%;\n}\n\n/* .grid-item { */\n    /* background-color: #f0f0f0; */\n    /* padding: 20px; */\n    /* border: 1px solid #ccc; */\n    /* text-align: center;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
