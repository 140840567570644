import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import GenericTable from '../../../components/GenericTable/GenericTable'
import { APIData } from '../../../constants/APIAdresses'
import { MUI_COLORS } from '../../../constants/MUIConstants'
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2'
import ProductEditor from './ProductEditor/ProductEditor'
import { connect } from 'react-redux'
import { setAllergens, setNutritiondata } from '../../../redux/productSlice'
// const MODES = {
//     VIEW: 'VIEW',
//     EDIT: 'EDIT',
//     ADD: 'ADD'
// }
const columns = [
    {
        width: 150,
        label: 'Produktbild',
        baseImgUri: APIData.MEDIA,
        dataKey: 'imgSource',
    },
    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        label: 'Name',
        dataKey: 'name',
    },
    {
        // width:2,
        label: 'Preis',
        dataKey: 'mainPrice',
        format: (value) => { return value != null ? value.replace(".", ",") + " €" : "-" }
    },
]
export class ProductsTile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            nutritionKeys: [],
            selectedProductIndex: -2,
            isLoading: true,
            testProduct: {},
        }
        this.shopId = this.props.shopId
        this.loadAllergensAndNutritionKeys();
    }
    deleteProduct(index) {
        // /shop/${shopId}/product/delete/${pid}
        let deletedProduct = { ...this.state.products[index] };
        let myTableData = [...this.state.products];
        myTableData.splice(index, 1);
        this.setState({ products: myTableData });
        fetch(APIData.SHOP + `/shop/${this.shopId}/product/delete/${deletedProduct.id}`, { credentials: 'include' })
            .then((response) => {
                if (response.ok) {
                    this.props.showAlertDisplay(`Produkt ${deletedProduct.name} erfolgreich gelöscht.`, ALERT_SEVERITY.SUCCESS);
                    this.setState({ products: myTableData });
                } else {
                    this.props.showAlertDisplay("Fehler beim Löschen des Produkts. Bitte versuche es erneut.", ALERT_SEVERITY.ERROR);
                    myTableData.splice(index, 0, deletedProduct);
                    this.setState({ products: myTableData });
                }
            }).catch(e => {
                console.log("Error while deleting product: ", e);
                this.props.showAlertDisplay("Fehler beim Löschen des Produkts. Bitte versuche es erneut.", ALERT_SEVERITY.ERROR);
                myTableData.splice(index, 0, deletedProduct);
                this.setState({ products: myTableData });
            })

    }
    setProductActive(index) {
        let body = { id: this.state.products[index].id, update: { active: this.state.products[index].active === 1 ? 0 : 1 } }
        console.log("this.state.products[index].active: ", this.state.products[index].active, " BODY: ", body);
        fetch(APIData.SHOP + `/shop/${this.shopId}/product/updateOrCreate`, {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                if (response.ok) {
                    let products = this.state.products;
                    this.props.showAlertDisplay(`Produkt erfolgreich ${products[index].active === 1 ? 'deaktiviert' : 'aktiviert'}.`, ALERT_SEVERITY.SUCCESS);
                    products[index].active = products[index].active === 1 ? 0 : 1;
                    this.setState({ products: products })
                }
            }).catch((error) => {
                console.log("Error while setting product active: ", error);
                this.props.showAlertDisplay("Fehler beim Aktivieren/Deaktivieren des Produkts. Bitte versuche es erneut.", ALERT_SEVERITY.ERROR);
            })
    }
    loadAllergensAndNutritionKeys() {
        fetch(APIData.SHOP + `/allergens`, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                if (response.ok) {
                    response.json().then((result) => {
                        // this.setState({allergens: result});
                        this.props.setAllergens(result);
                        fetch(APIData.SHOP + `/nutritionkeys`, { headers: { "Content-Type": "application/json" } })
                            .then((response) => {
                                if (response.ok) {
                                    response.json().then((result) => {
                                        // this.setState({nutritionKeys: result});
                                        result = result.map((item) => {
                                            return { ...item, value: '', isActive: false }
                                        })
                                        this.props.setNutritiondata(result);
                                        this.setState({isLoading: false, testProduct: {id: 1, name:"Test" }});
                                    })
                                }
                            }).catch((error) => {
                                console.log("Error while loading nutrition keys: ", error);
                            });
                    })
                }
            }).catch((error) => {
                console.log("Error while loading allergens: ", error);
            });

    }
    duplicateProduct(index, rowId){
        // /shop/:sid/product/:pid/duplicate
        fetch(APIData.SHOP + `/shop/${this.shopId}/product/${rowId}/duplicate`, { credentials: 'include' })
        .then((response) => {
            if(response.ok){
                return response.json();
            }else{
                console.log("Error while duplicating product: ", response.statusText);
                throw new Error("Error while duplicating product: " + response.statusText);
            }
        }).then((result) => {
            if(result.id != null){
                let myProductData = [...this.state.products];
                // myProductData.unshift({...myProductData[index], id: result.id});
                myProductData.splice(index+1, 0, {...myProductData[index], id: result.id, name: myProductData[index].name + " (Kopie)"});
                this.setState({products: myProductData});
                // this.props.showAlertDisplay("Produkt erfolgreich dupliziert.", ALERT_SEVERITY.SUCCESS);
            }
        })
        .catch(e => {
            console.log("Error while duplicating product: ", e);
            this.props.showAlertDisplay("Fehler beim Duplizieren des Produkts. Bitte versuche es erneut.", ALERT_SEVERITY.ERROR);
        })
    }
    render() {
        return (
            <CollapsibleTile
                isLoading={this.state.isLoading}
                title="Produkte"
                defaultExpanded
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../assets/icons/dairyproducts.png')} width={20} height={20} />}
            >
                <div style={this.state.selectedProductIndex !== -2 ? { position: "absolute", top: -500, left: -500, height: 0, width: 0 } : {}}>
                    <GenericTable
                        activeKey="active"
                        addBtnLabel="Produkt hinzufügen"
                        addBtnOnClick={() => { this.setState({ selectedProductIndex: -1 }) }}
                        apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/getPagedProducts`}
                        tableData={this.state.products}
                        setTableData={(data) => { this.setState({ products: data }) }}
                        onClickRow={(index) => { 
                            this.setState({ selectedProductIndex: index })
                        }}
                        config={{ columns: columns }}
                        onEdit={(index, id) => {
                            this.setState({ selectedProductIndex: index })
                        }}
                        onDuplicate={(index, id) => {
                            this.duplicateProduct(index, id);
                        }}
                        onDelete={(index, id) => {
                            this.props.openAlertDialog(
                                [
                                    {
                                        label: "Ja",
                                        color: MUI_COLORS.ERROR,
                                        onChoice: () => {
                                            this.deleteProduct(index);
                                        }
                                    },
                                    {
                                        label: "Nein",
                                        onChoice: () => {
                                            console.log("Produkt wird nicht gelöscht...");
                                        }
                                    }
                                ],
                                "Löschen", "Möchtest du das Produkt '" + this.state.products[index].name + "' wirklich löschen? Alternativ kannst du es auch deaktivieren, dadurch wird es nicht mehr im Shop angezeigt, aber bleibt in der Datenbank erhalten."
                            )
                        }}
                        onSetActive={(index, active) => {
                            this.setProductActive(index);
                        }}
                    />
                </div>
                {this.state.selectedProductIndex >= -1 ?
                    <ProductEditor
                        {...this.props /**This ensures that showAlertDisplay and other DefaultFrame functionalities are passed*/} 
                        shopId={this.shopId}
                        onBack={(productData) => { 
                            if (this.state.selectedProductIndex > -1 && productData?.update != null && Object.keys(productData.update).length >= 0) {
                                let myTableData = [...this.state.products];
                                myTableData[this.state.selectedProductIndex] = { ...myTableData[this.state.selectedProductIndex], ...productData.update }; 
                                this.setState({ selectedProductIndex: -2, products: [...myTableData] }) 
                            }else {

                                this.setState({ selectedProductIndex: -2 }) 
                            }
                        }}
                        nutritionKeys={this.state.nutritionKeys}
                        // allergens={this.state.allergens}
                        product={
                            this.state.selectedProductIndex >=0 ? this.state.products[this.state.selectedProductIndex] : null
                        }
                        // product = {this.state.testProduct}
                    />
                    : null
                }

            </CollapsibleTile>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}
function mapDispatchToProps(dispatch) {
    return {
        setNutritiondata: (data) => {
            dispatch(setNutritiondata(data));
        },
        setAllergens: (data) => {
            dispatch(setAllergens(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTile)

