import { createSlice } from "@reduxjs/toolkit";

export const NUTRITIONS= {
    'KCAL': 'Brennwert (kCal)',
    'KJ': 'Brennwert (kJ)',
    'FAT': 'Fett',
    'SAT_FAT': 'Gesättigte Fettsäuren',
    'CARB': 'Kohlenhydrate',
    'OF_SUGAR': 'Zucker',
    'PROT': 'Eiweiß',
    'SALT': 'Salz'
}
export const productSlice = createSlice({
    name:'product',
    initialState: {
        nutritionData: [],
        allergens: [],
        selectedAllergens: [],
        categories: [],
        selectedCategories: [
            // { "cid": 1, "name": "🍕 Pizza", "scKey": 1, "scName": "Test", },
            // { "cid": 3, "name": "🍔 Burger", "scKey": null, "scName": null }
        ],
        groupCollections: [],
        selectedGroupCollections: [
            // {"cid": null, "cName": null, "gc_id": 2, "gccName": "Pizza Mittel", "quantity": 2, "gc_cat_id": 2}
        ],
        collections: [

        ],
        selectedCollections: [
            // {"id": 4, "info": "", "title": "Dips", "pCollId": 4}
        ],
        productVariants: [
            // {
            //   "id": "1",
            //   "name": "ø28 cm",
            //   "info": "",
            //   "price": "7.90",
            //   "pgData": [
            //     {
            //         "cid": null,
            //         "cName": null,
            //         "gc_id": "1",
            //         "gccName": "Pizza Klein",
            //         "quantity": 2,
            //         "gc_cat_id": "1",
            //     }],
            //   "collData": [{"id": 1, "info": "Für ø28cm Pizza", "title": "Zutaten", "pCollId": 1}],
            // //   "allergens": null
            //     allergens : [{"id": 8, "aid": 10}]
            // },
            // {
            //   "id": "2",
            //   "name": "ø32 cm",
            //   "info": "",
            //   "price": "9.90",
            //   "pgData": [
            //   ],
            //   "collData": [{"id": 2, "info": "Für ø32cm Pizza", "title": "Zutaten", "pCollId": 2}],
            //   "allergens": []
            // },
            // {
            //   "id": "3",
            //   "name": "ø36 cm",
            //   "info": "",
            //   "price": "11.90",
            //   "pgData": [],
            //   "collData": [{"id": 3, "info": "Für ø36cm Pizza", "title": "Zutaten", "pCollId": 3}],
            //   "allergens": []
            // }
          ],
        pvCollectionData: []
    },
    reducers: {
        setNutritiondata: (state, arg) => {state.nutritionData = [...arg.payload]},
        setAllergens: (state, arg) => {state.allergens = [...arg.payload]},
        setSelectedAllergens: (state, arg) => {state.selectedAllergens = [...arg.payload]},
        setCategories: (state, arg) => {state.categories = [...arg.payload]},
        setSelectedCategories: (state, arg) => {state.selectedCategories = [...arg.payload]},
        setGroupCollections: (state, arg) => {state.groupCollections = [...arg.payload]},
        setSelectedGroupCollections: (state, arg) => {state.selectedGroupCollections = [...arg.payload]},
        setCollections: (state, arg) => {state.collections = [...arg.payload]},
        setSelectedCollections: (state, arg) => {state.selectedCollections = [...arg.payload]},
        setProductVariants: (state, arg) => {state.productVariants = JSON.parse(JSON.stringify(arg.payload))}, // shallow copy [...arg.payload] might not be enough here, because of nested objects
        setPVCollectionData: (state, arg) => {state.pvCollectionData = [...arg.payload]}
    }
})

export const { setAllergens, setNutritiondata, setSelectedAllergens, setCategories, setSelectedCategories, setGroupCollections, setSelectedGroupCollections, setCollections, setSelectedCollections, setProductVariants, setPVCollectionData} = productSlice.actions

export default productSlice.reducer