import { Cancel, Save } from '@mui/icons-material';
import { Button, CircularProgress, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react'
import { GD_MAIL_REGEX, GD_PHONE_REGEX, GENERIC_DRAWER_TYPE } from '../GenericDrawer';

export const EntityAdd = (props) => {
    const [fieldValues, setFieldValues] = useState(props.data?.fields?.length > 0 ? props.data.fields.map(fItem => { return { ...fItem, value: "" } }) : []);
    const [addActive, setAddActive] = useState(false);
    console.log("ENTITYADDPROPS: ", props.data);
    function actionCanceled() {
        setAddActive(false);
        setFieldValues(Array.from({ length: props.data.fields.length }, () => ""))
    }
    function onChangeFieldValue(index, value) {
        console.log("onChangeFieldValue:: Value="+value+" index="+index);
        let fv = [...fieldValues];
        fv[index].value = value;
        setFieldValues([...fv]);
    }
    function setFieldLoading(index, isLoading){
        let fv = [...fieldValues];
        fv[index].loading = isLoading;
        setFieldValues([...fv]);
    }
    function setFieldError(index, errorMsg){
        let fv = [...fieldValues];
        fv[index].error = true;
        fv[index].helperText = errorMsg;
        setFieldValues([...fv]);
    }
    return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
            {addActive &&
                <div style={{ padding: 10, display: "flex", flexDirection: "column", marginTop: 15, borderRadius: 5, borderWidth: 0.5, borderColor: "#979797", borderStyle: "dotted" }}>
                    {fieldValues.map((fieldItem, index) => {
                        switch (fieldItem.type) {
                            case GENERIC_DRAWER_TYPE.DIVIDER:
                                return <Divider key={"EntityAdd_" + props.data.btnLabel + "_" + index} style={{ marginTop: 25, marginBottom: 25, color: "#676767" }}>{fieldItem.label}</Divider>;
                            case GENERIC_DRAWER_TYPE.TEXT:
                                if (fieldItem.asyncCheck != null) {
                                    return <TextField maxRows={fieldItem.maxChar} type={fieldItem.isNumeric ? 'number' : fieldItem.isPhone ? 'tel' : 'text'} helperText={fieldItem.helperText} error={fieldItem.error} key={fieldItem.label + "_" + index}
                                        InputProps={{
                                            endAdornment: fieldItem.loading && (
                                                <InputAdornment position="end">
                                                    <CircularProgress size={15} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onBlur={(evt) => {
                                            console.log(evt.target.value);
                                            if (evt.target.value.length > 0) {
                                                if (fieldItem.isMail && !GD_MAIL_REGEX.test(evt.target.value)) {
                                                    setFieldError( index, "E-Mail Adresse ist ungültig!");
                                                } else if (fieldItem.isPhone && !GD_PHONE_REGEX.test(evt.target.value)) {
                                                    setFieldError( index, "Ungültige Telefonnummer!")
                                                } else if (evt.target.value.length < fieldItem.minChar) {
                                                    setFieldError( index, `Dieses Feld muss mindestens ${fieldItem.minChar} Zeichen haben.`);
                                                } else {
                                                    setFieldLoading(index, true);
                                                    fieldItem.asyncCheck()
                                                        .then((result) => { if (!result.success) { setFieldError( index, result.errorMsg) } })
                                                        .catch(() => { setFieldLoading( index, false); })
                                                        .finally(() => { setFieldLoading( index, false); })
                                                }
                                                // else if (fieldItem.check != null) {
                                                //     let errorMsg = fieldItem.check(evt.target.value);
                                                //     if (errorMsg != null) {
                                                //         setFieldError(index, errorMsg);
                                                //     }
                                                // }
                                            } else if (fieldItem.isRequired) {
                                                setFieldError( index, "Das ist ein Pflichtfeld")
                                            }
                                            // do the same checks like in the textfield in GenericDrawer and show errorMessages
                                            // setFieldError( index, errorMsg);
                                        }
                                        } onChange={(evt) => { /*if (!option.isPhone || option.isPhone && (/^(0|\+\d{1,15})$/).test(evt.target.value)) { updateField(index, evt.target.value) } else { }*/ onChangeFieldValue( index, evt.target.value); }
                                        } label={fieldItem.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={fieldItem.value} disabled={fieldItem.disabled} />;
                                } else {
                                    return <TextField maxRows={fieldItem.maxChar} type={fieldItem.isNumeric ? 'number' : fieldItem.isPhone ? 'tel' : 'text'} helperText={fieldItem.helperText} error={fieldItem.error} key={fieldItem.label + "_" + index}
                                        onBlur={(evt) => {
                                            console.log(evt.target.value);
                                            if (evt.target.value.length > 0) {
                                                if (fieldItem.isMail && !GD_MAIL_REGEX.test(evt.target.value)) {
                                                    setFieldError( index, "E-Mail Adresse ist ungültig!");
                                                } else if (fieldItem.isPhone && !GD_PHONE_REGEX.test(evt.target.value)) {
                                                    setFieldError( index, "Ungültige Telefonnummer!")
                                                } else if (evt.target.value.length < fieldItem.minChar) {
                                                    setFieldError( index, `Dieses Feld muss mindestens ${fieldItem.minChar} Zeichen haben.`);
                                                } else if (fieldItem.check != null) {
                                                    let errorMsg = fieldItem.check(evt.target.value);
                                                    if (errorMsg != null) {
                                                        setFieldError( index, errorMsg);
                                                    }
                                                }
                                            } else if (fieldItem.isRequired) {
                                                setFieldError( index, "Das ist ein Pflichtfeld")
                                            }
                                            // do the same checks like in the textfield in GenericDrawer and show errorMessages
                                            // setFieldError( index, errorMsg);
                                        }} onChange={(evt) => { onChangeFieldValue(index, evt.target.value); }} label={fieldItem.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={fieldItem.value} disabled={fieldItem.disabled} />;
                                }
                            case GENERIC_DRAWER_TYPE.ENUM:
                                return (<FormControl error={fieldItem.error} key={fieldItem.label + "_" + index} style={{ marginTop: 15, width: "100%" }}>
                                    <InputLabel id={fieldItem.label + "_" + index}>{fieldItem.label}</InputLabel>
                                    <Select value={fieldItem?.value} labelId={fieldItem?.label + "_" + index} label={fieldItem?.label} onChange={(evt) => { onChangeFieldValue(index, evt.target.value) }}>
                                        {Object.keys(fieldItem.options).map(key => {
                                            return <MenuItem key={key} value={fieldItem.options[key]}>{key}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>)
                            // return <TextField key={"EntityAdd_" + props.data.btnLabel + "_" + index} onBlur={()=> {console.log("Focus lost async check field")}} helperText={props.data.errorMsg} error={props.data.error} maxRows={fieldItem.maxChar != null ? fieldItem.maxChar : undefined} type={fieldItem.isNumeric == true ? 'number' : "text"} onChange={(evt) => { onChangeFieldValue(evt.target.value, index) }} label={fieldItem.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={fieldItem.value} />
                            // if(fieldItem.asyncCheck != null) {
                            // }else{
                            // return <TextField key={"EntityAdd_" + props.data.btnLabel + "_" + index} helperText={props.data.errorMsg} error={props.data.error} maxRows={fieldItem.maxChar != null ? fieldItem.maxChar : undefined} type={fieldItem.isNumeric == true ? 'number' : "text"} onChange={(evt) => { onChangeFieldValue(evt.target.value, index) }} label={fieldItem.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={fieldItem.value} />
                            // }
                            default:
                                return null
                        }
                        // return props.data.fieldOptions[index]?.isDivider == true ?

                    })}
                    <Button onClick={() => { props.onSave(props.data.entityKey, fieldValues); actionCanceled() }} style={{ marginTop: 10 }} startIcon={<Save />}>Speichern</Button>
                    <Button onClick={() => { actionCanceled() }} color='error' startIcon={<Cancel />}>Abbrechen</Button>
                </div>
            }
            {!addActive &&
                <Button onClick={() => { setAddActive(true) }} startIcon={props.data.btnIcon}>{props.data.btnLabel}</Button>
            }
        </div>
    )
}
export default EntityAdd