import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, fontSize, fontWeight } from '@mui/system';
import { Button, Checkbox, CircularProgress, Collapse, IconButton, MenuItem, Radio, Select, TableBody, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import './GenericTable.css';
import { styled } from '@mui/material/styles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { APIData } from '../../../constants/APIAdresses';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';
// import GTTableHeader from './GTTableHeader';
// import GTTableBody from './GTTableBody';
// import NTTableBody from './NTTableBody';
// import NTTableHeader from './NTTableHeader';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

export const NGT_ACTIVETYPES = {
    SWITCH: "SWITCH",
    CHECKBOX: "CHECKBOX",
    RADIO: "RADIO",
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#b9d3e4',
        // backgroundColor: '#adbdc8',
        color: '#5a5a5a',
        // fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const itemsPerPageOptions = [10, 25, 50, 100];
export class ProductVariantSelectorTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            searchText: "",
            pageIndex: 0,
            pageCount: 2,
            itemsPerPage: 10,
            // data: []
        }
    }
    searchText(text) {
        this.setState({ searchText: text });
        // console.log(text);
        if (text.length >= 3) {
            console.log("TextSearch: ", text);
            this.setState({ pageIndex: 0 });
            this.loadAndSetData(text, 0, this.state.itemsPerPage);
        } else {
            this.setState({ pageIndex: 0 });
            this.loadAndSetData(null, 0, this.state.itemsPerPage);
        }
    }
    loadAndSetData(searchText, pageIndex, itemsPerPage) {
        let query = (searchText != null ? "?search=" + searchText : "");
        query += (pageIndex != null ? (query.length > 0 ? "&" : "?") + "page=" + pageIndex : "");
        query += (itemsPerPage != null ? (query.length > 0 ? "&" : "?") + "ipp=" + itemsPerPage : "");
        this.setState({ isLoading: true });
        fetch(this.props.apiEndPoint + query, { credentials: "include" })
            .then((response) => {
                return response.json();
            }).then((result) => {
                result.data = typeof result.data === "string" ? JSON.parse(result.data) : result.data;
                // this.props.setTableData(JSON.parse(result.data));
                this.props.setTableData(result.data);
                this.setState({
                    // data: JSON.parse(result.data), 
                    pageCount: Math.ceil(result.totalcount / this.state.itemsPerPage),
                    isLoading: false,
                })
            }).catch((error) => {
                console.log("Error while loading table: ", error);
                this.setState({ isLoading: false });
            });
    }
    componentDidMount() {
        this.loadAndSetData(null, 0, this.state.itemsPerPage);
    }
    onNextPage() {
        this.loadAndSetData(null, this.state.pageIndex + 1, this.state.itemsPerPage);
        this.setState({ pageIndex: this.state.pageIndex + 1 })
    }
    onPrevPage() {
        this.loadAndSetData(null, this.state.pageIndex - 1, this.state.itemsPerPage);
        this.setState({ pageIndex: this.state.pageIndex - 1 })
    }
    onIPPChange(value) {
        this.setState({ itemsPerPage: value, pageIndex: 0 });
        this.loadAndSetData(this.state.searchText, 0, value);
    }
    setProductActive(row, index, value, pvrow, isUpdate) {
        this.setState({isLoading:true});
        console.log("Setting active (pvid: " + JSON.stringify(pvrow) + "): ", row);
        let updateObj = {
            id: row.gccpId,
            pid: row.id,
            name: row.name,
            // variant: pvrow?.name,
        }
        if(pvrow?.id != null && pvrow?.name != null) {
            updateObj.pvId = pvrow.id;
            updateObj.variant = pvrow.name;
        }
        let body = {
            
        }
        if (value === "1") {
            if (isUpdate) {
                body.update = [{
                    gc_category_product_id: row.gccpId,
                    pvId: pvrow.id,
                }];
            } else {
                let insertItem = {
                    pid: row.id,
                }
                if (pvrow?.id != null) {
                    insertItem.pvId = pvrow.id;
                }
                body.insert = [insertItem];
            }
        } else if (row.gccpId != null) {
            // remove
            body.delete = [row.gccpId];
        }
        // console.log("BODY: ", body)
        if (Object.keys(body).length > 0) {

            let url = APIData.SHOP + `/shop/${this.props.shopId}/groupCategory/${this.props.groupCategoryId}/updateOrCreate`
            fetch(url, { credentials: "include", method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(body) })
                .then(response => {
                    // console.log("Response: ", response.status);
                    if (response.ok) {
                        return response.json();
                    }
                }).then(result => {
                    // console.log("Result: ", result);
                    let myData = [...this.props.tableData];
                    if (value === "1" && result.catProductInsertIdList) {
                        myData[index].gccpId = result.catProductInsertIdList[0].insertId;
                        updateObj.id = result.catProductInsertIdList[0].insertId;
                        myData[index].isInGroupCat = value;
                        if (pvrow?.id != null) {
                            myData[index].pvId = pvrow.id;
                        }
                        console.log("TABLEDATANEW: ", myData);
                        this.props.updateMainTableProducts(updateObj);
                    } else if (isUpdate) {
                        myData[index].pvId = pvrow.id;
                        this.props.updateMainTableProducts(updateObj);
                    } else {
                        this.props.removeMainTableProduct(myData[index].gccpId);
                        myData[index].gccpId = null;
                        myData[index].isInGroupCat = value;
                        myData[index].pvId = null;
                    }
                    this.props.setTableData(myData);
                    this.setState({isLoading:false});
                    // catProductInsertIdList
                    this.props.onSetActive(index, value);
                })
                .catch(e => {
                    this.setState({isLoading:false});
                    this.props.showAlert("Fehler beim Speichern des Produkts", ALERT_SEVERITY.ERROR);
                    console.log("Error while setting active: ", e);
                })


            // 
        }
    }
    render() {
        return (
            <Paper style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1, maxHeight: 70, flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#f7fafc', padding: 10, borderTopRightRadius: 4, borderTopLeftRadius: 4 }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                        <TextField size='small' style={{ backgroundColor: "#fff", borderRadius: 4, width: "20%", minWidth: 250 }} onChange={(evt) => { this.searchText(evt.target.value) }} variant='outlined' label="Suche" />
                    </div>
                </div>
                <TableContainer sx={{ flex: 1, scrollbarWidth: 'none', msOverflowStyle: 'none' }} component={Box}>
                    <Table stickyHeader sx={{ minWidth: 700, }} size='small' aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {/* <StyledTableCell sx={{ width: 50 }} >THIS IS FOR THE COLLAPSE ARROW BUTTON</StyledTableCell> */}
                                <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}>Aktiv</StyledTableCell>
                                {this.props.config.columns?.map((column) => {
                                    return <StyledTableCell sx={column.width != null ? { width: column.width } : {}} key={column.dataKey}  >{column.label}</StyledTableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.tableData?.map((row, index) => {
                                return <Row key={row.id}
                                    row={row}
                                    columns={this.props.config.columns}
                                    activeCompDisabled={this.props.activeCompDisabled}
                                    activeKey={this.props.activeKey}
                                    onSetActive={(value, pvrow, isUpdate) => { this.setProductActive(row, index, value, pvrow, isUpdate) }}
                                    onDelete={this.props.onDelete}
                                    onEdit={this.props.onEdit}
                                    onPositionChange={this.props.onPositionChange} />
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
                <div style={{ height: 65, borderTop: "0.5px solid #cecece", display: "flex", color: '#676767', justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: 20 }}>
                        <Typography >Zeilananzahl:</Typography>
                        <Select onChange={(evt) => { this.onIPPChange(evt.target.value) }} style={{ marginLeft: 10 }} value={this.state.itemsPerPage} size='small' label="Zeilenanzahl">
                            {itemsPerPageOptions.map((item) => {
                                return <MenuItem key={"ippo_" + item} value={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Button onClick={() => { this.onPrevPage() }} disabled={this.state.pageIndex <= 0 || this.state.isLoading}>
                            {'<'}
                        </Button>
                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                            Seite{' '}
                            <strong>
                                {this.state.pageIndex + 1} / {this.state.pageCount}
                            </strong>{' '}
                        </span>
                        <Button onClick={() => { this.onNextPage() }} disabled={this.state.pageIndex + 1 >= this.state.pageCount || this.state.isLoading}>
                            {'>'}
                        </Button>
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
                {this.state.isLoading &&
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "50%",
                        backgroundColor: "rgba(0,0,0,0.4)",
                        padding: 10,
                        zIndex: 1, // Ensure it's above other elements
                    }}>
                        <CircularProgress size={25} />
                    </div>
                }
            </Paper>
        );
    }
}
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (<React.Fragment key={row.id}>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.id}
            style={{ cursor: 'pointer', }}>

            <StyledTableCell onClick={() => {
                if (row.variantData.length > 0) {
                    setOpen(!open);
                } else {
                    props.onSetActive((row.isInGroupCat === "1" ? "0" : "1"))
                }
            }} style={{ textAlign: "center" }}>
                <Checkbox color="primary" checked={row.isInGroupCat === "1"} />
            </StyledTableCell>
            {props.columns.map((column) => {
                return <StyledTableCell onClick={() => { props.onClick(); }} key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey]}</StyledTableCell>
            })}
        </StyledTableRow>
        <StyledTableRow>
            <StyledTableCell style={{ padding: 0 }} colSpan={Object.keys(row)?.length + 2}>
                <Collapse key={"PVSNTBody_Collapse_" + props.index + "_" + row[props.subTableKey]?.length} in={open} timeout="auto" unmountOnExit>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 15, justifyContent: "center", padding: 25 }}>
                        <div onClick={() => {
                            if (row.pvId != null && row.isInGroupCat === '1') {
                                props.onSetActive("0");
                            }
                        }}
                            style={{ textDecoration: "none", flexGrow: 1, flexDirection: "row", maxWidth: 250, padding: 20, borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", flexBasis: 250, backgroundColor: "#ebf1f5" }}>
                            <Radio color='primary' checked={row.pvId == null && row.isInGroupCat === '0'} />
                            <Typography>Kein Auswahl</Typography>
                        </div>
                        {row.variantData.map((subRow, index) => {
                            return <div onClick={() => {
                                props.onSetActive("1",subRow, (row.pvId != null && row.isInGroupCat === '1'))
                            }} key={"VD_Selector_" + Object.values(subRow).join("")} style={{ textDecoration: "none", flexGrow: 1, flexDirection: "row", maxWidth: 250, padding: 20, borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", flexBasis: 250, backgroundColor: "#ebf1f5" }}>
                                <Radio color='primary' checked={subRow.id == row.pvId} />
                                <Typography>{subRow.name}</Typography>
                            </div>
                        })}
                    </div>
                    {/* <Typography>Test</Typography> */}
                </Collapse>
            </StyledTableCell>
        </StyledTableRow>
    </React.Fragment>)

}

export default ProductVariantSelectorTable
