import React, { Component } from 'react'
import CollapsibleTile from '../../components/CollapsibleTile';
import { APIData } from '../../constants/APIAdresses';
import GenericTable from '../../components/GenericTable/GenericTable';
import { MUI_COLORS } from '../../constants/MUIConstants';
import { ALERT_SEVERITY } from '../../components/AlertDisplay2';
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../components/GenericDrawer';
import { Save } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';

const columns = [
    { dataKey: 'id', label: 'ID', width: 120 },
    { dataKey: 'uniqueKey', label: 'Eindeutige Bezeichnung', width: 150 },
    { dataKey: 'defaultLabel', label: 'Standardbezeichnung', },
    { dataKey: 'unit', label: 'Einheit', type: 'number', width: 70 }
]
export class NutritionDataTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nutritonData: []
        }
    }
    deleteNutrition(index, rowId) {
        // Delete Nutrition from API
        fetch(APIData.SHOP + `/nutritions/${rowId}/delete`, { credentials: 'include', method: 'DELETE' })
            .then(response => {
                if (response.ok) {
                    let data = [...this.state.nutritonData];
                    data.splice(index, 1);
                    this.setState({ nutritonData: data });
                } else {
                    console.log("Error while deleting nutrition: ", response);
                    this.props.showAlertDisplay("Der Nährstoff konnte nicht gelöscht werden (" + response.status + ").", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while deleting nutrition: ", e);
                this.props.showAlertDisplay("Der Nährstoff konnte nicht gelöscht werden.", ALERT_SEVERITY.ERROR);
            })
    }
    updateNutrition(configData,index, id) {
        let body = {
            fields: {

            }
        }
        let filteredFields = configData.fields.filter((field) => { return field.status === GD_STATUS_TYPE.UPDATE });
        filteredFields.forEach((field) => {
            body.fields[field.dbKey] = field.value;
        })
        fetch(APIData.SHOP + `/nutritions/${id}/update`, { credentials: 'include', method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
        .then(response => {
            this.props.closeDrawer();
            if(response.ok){
                let data = [...this.state.nutritonData];
                data[index] = {...data[index], ...body.fields};
                this.setState({nutritonData: data});
                this.props.showAlertDisplay("Nährstoff erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
            }else{
                this.props.showAlertDisplay("Der Nährstoff konnte nicht aktualisiert werden (" + response.status + ").", ALERT_SEVERITY.ERROR);
            }
        }).catch(e => {
            console.log("Error while updating nutrition: ", e);
            this.props.showAlertDisplay("Der Nährstoff konnte nicht aktualisiert werden.", ALERT_SEVERITY.ERROR);
        })

    }
    openNutritionEditDrawer(index, rowId) {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Speichern",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.updateNutrition(configData, index, rowId) }
                },{
                    label: "Abbrechen",
                    icon: <Close />,
                    onClick: (configData) => { this.props.closeDrawer() }
                }
            ],
            fields: [
                {
                    label: "ID",
                    disabled: true,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: rowId,
                    dbKey: "id",
                },
                {
                    label: "Eindeutige Bezeichnung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.nutritonData[index].uniqueKey,
                    dbKey: "uniqueKey",
                    isRequired: true
                },
                {
                    label: "Standardbezeichnung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.nutritonData[index].defaultLabel,
                    dbKey: "defaultLabel",
                    isRequired: true
                },
                {
                    label: "Einheit",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.nutritonData[index].unit,
                    dbKey: "unit",
                    isRequired: true
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    addNutrition(configData) {
        console.log("ADDNUTRITION: ",configData);
        let body = {
            fields: {}
        }
        configData.fields.forEach((field) => {
            body.fields[field.dbKey] = field.value;
        });
        fetch(APIData.SHOP + "/nutritions/add", { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
        .then(response => {
            if(response.ok) {
                return response.json();
            }else{
                this.props.showAlertDisplay("Der Nährstoff konnte nicht hinzugefügt werden (" + response.status + ").", ALERT_SEVERITY.ERROR);
                return null;
            }
        })
        .then(result => {
            if(result.id != null){
                let data = [...this.state.nutritonData];
                data.push({...body.fields, id: result.id});
                this.setState({nutritonData: data});
                this.props.closeDrawer();
            }
        })
        .catch(e => {
            console.log("Error while adding nutrition: ", e);
            this.props.showAlertDisplay("Der Nährstoff konnte nicht hinzugefügt werden.", ALERT_SEVERITY.ERROR);
        })
        
    }
    openNewNutritionDrawer() {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Hinzufügen",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.addNutrition(configData) }
                },{
                    label: "Abbrechen",
                    icon: <Close />,
                    onClick: (configData) => { this.props.closeDrawer() }
                }
            ],
            fields: [
                {
                    label: "Eindeutige Bezeichnung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "uniqueKey",
                    isRequired: true
                },
                {
                    label: "Standardbezeichnung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "defaultLabel",
                    isRequired: true
                },
                {
                    label: "Einheit",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "unit",
                    isRequired: true
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    render() {
        return (
            <CollapsibleTile
                onCollapseChange={(expanded) => { }}
                title="Nährstoffdaten"
                icon={<img src={require('../../assets/icons/wheat.png')} width={20} height={20} />}
            >
                <GenericTable
                    config={{ columns: [...columns] }}
                    apiEndPoint={APIData.SHOP + "/nutritions/getPaged"}
                    tableData={this.state.nutritonData}
                    setTableData={(data) => { this.setState({ nutritonData: data }) }}
                    onEdit={(index, rowId) => { 
                        this.openNutritionEditDrawer(index, rowId);
                        console.log("index: ", index, " rowId: ", rowId) }}
                    onDelete={(index, rowId) => {
                        this.props.openAlertDialog(
                            [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        // console.log("REGEL wird gelöscht!");
                                        this.deleteNutrition(index, rowId);
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Nutrition wird nicht gelöscht...");
                                    }
                                }
                            ],
                            "Löschen", `Möchten Sie den Nährstoff '${this.state.nutritonData[index].uniqueKey}' wirklich löschen?`)
                        console.log("index: ", index, " rowId: ", rowId)
                    }}
                    addBtnOnClick={() => { 
                        this.openNewNutritionDrawer();
                     }}
                    addBtnLabel="Nährstoff Hinzufügen"
                />
            </CollapsibleTile>
        )
    }
}

export default NutritionDataTile