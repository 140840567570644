import React, { Component } from 'react'
import DefaultFrame from '../components/DefaultFrame';
import UserShopSelector from './UserShopSelector';

const TestWrapper = ({children}) => {
    const micCheckStr = "test123MIC-CHECK";
    return React.cloneElement(children, { micCheckStr });
};


class TestChild extends React.Component {
    render() {
        return (
            <div>
                Hello World - PROPS={JSON.stringify(this.props)}
            </div>
        );
    }
}
export class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedShops: [],
        }
    }

    // userId int NOT NULL, companyId int NOT NULL, shopId MEDIUMINT UNSIGNED NOT NULL, role TINYINT NOT NULL DEFAULT 2, UNIQUE(userId, shopId), FOREIGN KEY (userId) REFERENCES user(id)

    render() {
        console.log("myProps?: ", this.props);
        return (
            <div>
                <DefaultFrame >
                    <div>
                        <UserShopSelector userId={3} selectedShops={this.state.selectedShops} setSelectedShops={(shops) => {this.setState({selectedShops: shops})}} />
                    </div>
                    {/* <div>Hello World - PROPS={JSON.stringify(this.props)}</div> */}
                    {/* <TestChild /> */}
                </DefaultFrame>
            </div>
        );
    }
}

export default Test;
