import { Button, FormControlLabel, Radio, RadioGroup, Switch, Tab, Tabs, TextField, Typography, } from '@mui/material'
import React, { Component } from 'react'
import "./SupportOverview.css"
import "../../assets/css/general.css"
import { TabContext } from '@mui/lab'
import AllSupportCasesTabContent from './Tabs/AllSupportCasesTabContent'
import OpenSupportCasesTabContent from './Tabs/OpenSupportCasesTabContent'
import ClosedSupportCasesTabContent from './Tabs/ClosedSupportCasesTabContent'
import Header from '../../components/Header'
import { connect } from 'react-redux'
import { setRole } from '../../redux/permissionSlice'
import GenericTable from '../../components/GenericTable/GenericTable'

import { addCasesToCaseList, addCaseToActiveCaseListAndSetIndex, setCaseList, setCaseOffset, setChatDialogOpen, setLiveDataUpdate, setSupportCaseTableData } from '../../redux/supportSlice'
import SearchSupporCaseContent from './Tabs/SearchSupporCaseContent'
import { APIData } from '../../constants/APIAdresses'
import DefaultFrame from '../../components/DefaultFrame'
import { width } from '@mui/system'


export const SUPPORT_TAB = { ALL: 0, OPEN: 1, CLOSED: 2 };
const supportCaseColumns = [
    { dataKey: 'id', label: 'ID', width: 120 },
    { dataKey: 'userId', label: 'Benutzer-ID' },
    { dataKey: 'createdAt', label: 'Erstellungsdatum', width: 150, format: (value) => { return !isNaN(new Date(value)) ? new Date(value).toLocaleString('de', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(",", "") : "-" } },
    { dataKey: 'status', label: 'Status', width: 140, format: (value) => { return value + "" === "1" ? "Offen ⚠️" : value + "" === "2" ? "Geschlossen ✅" : "Unbekannt" } },
]
export class SupportOverview extends Component {

    constructor(props) {
        super(props);
        // if(!props.loggedIn){
        //     window.location="/";
        // }
        this.state = {

            currentCaseType: SUPPORT_TAB.OPEN,
            searchValue: "",
            searchActive: false,
            searchContent: null,
            supportCases: [],
            liveData: true,

            // caseList: [
            //     {id:"18446744"},
            //     {id:2},
            //     {id:3},
            //     {id:4},
            //     {id:5},{id:6},{id:7},{id:8},{id:9},{id:10},{id:11},{id:12},{id:13},{id:14},{id:15},{id:16},{id:17},{id:18},{id:19},{id:20},{id:21},{id:22},{id:23},{id:24},{id:25},{id:26},{id:27},{id:28},{id:29},{id:30},{id:31},{id:32},{id:33},
            // ],
            // currentCaseList: [, "455", "300", "301", "302", "303"]
        }
        // this.autoRefreshInterval = null;
        console.log("PROPS: ", props);
    }

    componentDidMount() {
        if (this.props.openCaseOffset == 0) {
            this.loadCases(this.props.openCaseOffset, SUPPORT_TAB.OPEN);
        }
    }
    loadCases(offset, tab) {
        let uri = APIData.REALTIME+"/support/getCases?offsetMultiplier=" + offset + "&type=" + tab;
        console.log("Loading cases with uri: ", uri);
        fetch(uri, { credentials: "include" })
            .then(response => {
                if (response.ok) {
                    if (response.status === 204) { // status means request was processed but no data were found
                        return [];
                    } else {
                        return response.json();
                    }
                }
            }).then(result => {
                if (result.length > 0) {
                    this.props.addCasesToCaseList({ tab: tab, list: result });
                    switch (tab) {
                        case SUPPORT_TAB.ALL: this.props.setCaseOffset({ tab: SUPPORT_TAB.ALL, value: this.props.allCaseOffset + 1 }); break;
                        case SUPPORT_TAB.OPEN: this.props.setCaseOffset({ tab: SUPPORT_TAB.OPEN, value: this.props.openCaseOffset + 1 }); break;
                        case SUPPORT_TAB.CLOSED: this.props.setCaseOffset({ tab: SUPPORT_TAB.CLOSED, value: this.props.closedCaseOffset + 1 }); break;
                    }
                } else {
                    switch (tab) {
                        case SUPPORT_TAB.ALL: this.props.setCaseOffset({ tab: SUPPORT_TAB.ALL, value: -1 }); break;
                        case SUPPORT_TAB.OPEN: this.props.setCaseOffset({ tab: SUPPORT_TAB.OPEN, value: -1 }); break;
                        case SUPPORT_TAB.CLOSED: this.props.setCaseOffset({ tab: SUPPORT_TAB.CLOSED, value: -1 }); break;
                    }
                }
            }).catch(e => {
                console.log("Error while loading cases: ", e);
            })
    }
    // loadAutoRefresh(){}
    loadMore() {
        let myOffset = -1;
        switch (this.state.currentTab) {
            case SUPPORT_TAB.ALL:
                myOffset = this.props.allCaseOffset;
                break;
            case SUPPORT_TAB.OPEN:
                myOffset = this.props.openCaseOffset;
                break;
            case SUPPORT_TAB.CLOSED:
                myOffset = this.props.closedCaseOffset;
                break;
        }
        if (myOffset != null && myOffset != -1) {
            this.loadCases(myOffset, this.state.currentTab);
        }
    }
    onTabChange(event, newValue) {

        this.setState({ currentTab: newValue, searchValue: "" });
        if (this.state.searchValue.length > 0) {
            switch (this.state.currentTab) {
                case SUPPORT_TAB.ALL:
                    this.props.setCaseOffset({ tab: SUPPORT_TAB.ALL, value: 0 });
                    break;
                case SUPPORT_TAB.OPEN:
                    this.props.setCaseOffset({ tab: SUPPORT_TAB.OPEN, value: 0 });
                    break;
                case SUPPORT_TAB.CLOSED:
                    this.props.setCaseOffset({ tab: SUPPORT_TAB.CLOSED, value: 0 });
                    break;
            }
        }
        let offset = newValue === SUPPORT_TAB.ALL ? this.props.allCaseOffset : newValue === SUPPORT_TAB.OPEN ? this.props.openCaseOffset : newValue === SUPPORT_TAB.CLOSED ? this.props.closedCaseOffset : -1;
        if (offset == 0) {
            // if tab doesn't have any data yet -> load
            this.loadCases(offset, newValue);

        }
    }
    searchForCase() {
        if (this.state.searchValue.length > 0) {
            this.setState({ searchActive: true });
            fetch(APIData.REALTIME + `/support/getCaseById/${this.state.searchValue}/status/${this.state.currentTab}`, { credentials: "include" })
                .then(response => {
                    console.log("SearchForCase Response: ", response);
                    if (response.ok) {
                        return response.json();
                    } else {
                        return null;
                    }
                }).then(result => {
                    console.log("Result For Search: ", result);
                    if (result != null) {
                        this.setState({
                            searchContent: result
                        })
                        // if(result)
                    }
                }).catch(e => {
                    alert("Fehler bei der Suche vom Supportfall");
                })
        } else {
            if (this.state.searchActive || this.state.searchContent != null) {
                this.setState({
                    searchActive: false,
                    searchContent: null
                })
            }
        }
        // /support/getCaseById/:id/status/:status
    }
    setLiveData(value) {
        // this.setState({ liveData: value });
        this.props.setLiveDataUpdate(value);
    }
    render() {
        return (
            <DefaultFrame title="Support">
                {/* <Typography>ROLE: {this.props.role}</Typography> */}
                <div style={{ padding: "15px", height: "100%", display: "flex", flexDirection: "column", gap: 15 }}>
                    <div style={{ display: "flex", gap: 15 }}>
                        <div style={{flex:1}}>
                            <RadioGroup value={this.state.currentCaseType} onChange={(evt) => { this.setState({ currentCaseType: evt.target.value }) }} row>
                                <FormControlLabel value={SUPPORT_TAB.ALL} control={<Radio />} label="Alle" />
                                <FormControlLabel value={SUPPORT_TAB.OPEN} control={<Radio />} label="Offen ⚠️" />
                                <FormControlLabel value={SUPPORT_TAB.CLOSED} control={<Radio />} label="Geschlossen ✅" />
                            </RadioGroup>
                        </div>
                        <div style={{flex:1, display:"flex", gap:5, justifyContent:"right", alignItems:"center"}}>
                            <Switch checked={this.props.liveDataUpdate} onChange={(evt) => {this.setLiveData(evt.target.checked)}}/>
                            <Typography>Live-Aktualisierung</Typography>
                        </div>
                    </div>
                    <GenericTable
                        config={{ columns: [...supportCaseColumns] }}
                        apiEndPoint={APIData.REALTIME + "/support/cases/getPaged" + (this.state.currentCaseType != SUPPORT_TAB.ALL ? "?status=" + this.state.currentCaseType : "")}
                        defaultItemsPerPage={25}
                        tableData={this.props.supportCaseTableData}
                        setTableData={(data) => {
                            console.log("Setting data: ", data);
                            this.props.setSupportCaseTableData(data);
                        }}
                        onClickRow={(index, data) => {
                            console.log("Clicked on row: ", index, "= ", data);
                            this.props.openChatForCase(data);
                        }}
                        noAdd
                    />
                    {/* <GenericTable
                    config={{ columns: [...supportCaseColumns] }}
                    apiEndPoint={APIData.REALTIME+"/support/cases/getPaged"}
                    defaultItemsPerPage={25}
                    tableData={this.state.supportCases}
                    setTableData={(data) => { this.setState({ supportCases: data }) }}
                    /> */}
                </div>
            </DefaultFrame>
        )
    }
}
function mapStateToProps(state) {
    return {
        role: state.permission.role,
        supportCaseTableData: state.support.supportCaseTableData,
        liveDataUpdate: state.support.liveDataUpdate,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setLiveDataUpdate: (value) => {
            dispatch(setLiveDataUpdate(value));
        },
        setCaseOffset: (data) => {
            dispatch(setCaseOffset(data));
        },
        setSupportCaseTableData: (data) => {
            dispatch(setSupportCaseTableData(data));
        },
        setCaseList: (data) => {
            dispatch(setCaseList(data))
        },
        addCasesToCaseList: (data) => {
            dispatch(addCasesToCaseList(data));
        },
        setRole: (role) => {
            dispatch(setRole(role));
        },
        openChatForCase: (data) => {
            dispatch(setChatDialogOpen(true));
            dispatch(addCaseToActiveCaseListAndSetIndex({ ...data, unreadItems: 0, msgList: [], loadOffset: 0, userInfo: {}, orderList: [], orderOffset: 0, supportCases: [], joined: false }));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportOverview)
