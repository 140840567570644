import React, { Component } from 'react'
import CollapsibleTile from '../../../../components/CollapsibleTile'
import { connect } from 'react-redux'
import { setGroupCollections, setSelectedGroupCollections } from '../../../../redux/productSlice'
import { Done, Edit } from '@mui/icons-material'
import { MUI_COLORS } from '../../../../constants/MUIConstants'
import GenericTable, { GT_ACTIVETYPES } from '../../../../components/GenericTable/GenericTable'
import { APIData } from '../../../../constants/APIAdresses'
import { PE_CHANGE_TYPES } from './ProductEditor'
import StaticTable from '../../../../components/StaticTable/StaticTable'
const columns = [
    {
        width: 150,
        label: 'Gruppenkollektion-ID',
        dataKey: 'gc_id',
    },
    {
        width: 50,
        label: 'Menge',
        dataKey: 'quantity',
    },
    {
        width: 150,
        label: 'Kategorie-ID.',
        dataKey: 'cid',
    },
    {
        // width: 200,
        label: 'Kategorie',
        dataKey: 'cName',
    },

    {
        width: 150,
        label: 'Gruppenkategorie-ID.',
        dataKey: 'gc_cat_id',
    },
    {
        // width: 200,
        label: 'Gruppenkategorie',
        dataKey: 'gccName',
    },

]
const gcColumns = [
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
    },
    {
        width: 100,
        label: 'Anzahl',
        dataKey: 'quantity',
    },
    {
        flex: 1,
        label: 'Name',
        dataKey: 'name',
    },
    {
        width: 100,
        // flex: 1,
        label: 'Kategorie',
        dataKey: 'cid',
        format: (value) => {
            return value ? "Ja" : 'Nein'
        },
    },
    {
        // width: 4,
        width: 120,
        label: 'Gruppenkategorie',
        dataKey: 'gc_cat_id',
        format: (value) => {
            return value ? "Ja" : 'Nein'
        },
    },
]
const MODES = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
}
const itemsPerPageOptions = [10, 25, 50, 100];
export class GCSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentMode: MODES.VIEW,
            pageIndex: -1,
            pageCount: 0,
            itemsPerPage: 10,
        }
        this.shopId = this.props.shopId;
    }
    loadGroupCollections() {
        fetch(APIData.SHOP + `/shop/${this.shopId}/groupCollections/getPaged`, { credentials: "include", headers: { "Content-Type": "application/json" } })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    console.log("ErrStatus while loading group collections (GCSelector): ", response.status);
                }
            }).then(result => {
                if (result != null) {
                    result.data = typeof result.data === "string" ? JSON.parse(result.data) : result.data;
                    this.props.setGroupCollections(result.data);
                    this.setState({ pageCount: Math.ceil(parseInt(result.totalcount) / this.state.itemsPerPage), pageIndex: 0 });
                }
            }).catch(e => {
                console.log("Error while loading group collections (GCSelector): ", e);
            })
    }
    componentDidMount() {
        this.loadGroupCollections();
    }
    render() {
        return (
            <CollapsibleTile
                title="Gruppenkollektionen"
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../../assets/icons/hierarchical-structure.png')} width={20} height={20} />}
                actions={
                    this.state.currentMode === MODES.VIEW ?
                        [
                            { label: "Editieren", icon: <Edit />, onClick: () => { this.setState({ currentMode: MODES.EDIT }) } },
                        ] :
                        [
                            { label: "Fertig", color: MUI_COLORS.PRIMARY, icon: <Done />, onClick: () => { this.setState({ currentMode: MODES.VIEW }) } },
                        ]
                }
            >
                {this.state.currentMode === MODES.VIEW ?
                    <StaticTable
                        columns={columns}
                        tableData={this.props.selectedGroupCollections}
                        setTableData={(data) => {
                            this.props.setSelectedGroupCollections(JSON.parse(JSON.stringify(data)));
                        }}
                        idKey="gc_id"
                        onDelete={(index, row) => {
                            console.log("DELETE INDEX: ", index, " DATA: ", this.props.selectedGroupCollections[index]);
                        }}

                    />
                    :
                    <GenericTable
                        config={{ columns: gcColumns }}
                        apiEndPoint={APIData.SHOP + `/shop/${this.props.shopId}/groupCollections/getPaged`}
                        tableData={this.props.groupCollections}
                        setTableData={(data) => {
                            data = data.map((element) => {
                                return {
                                    ...element, active:
                                        this.props.selectedGroupCollections.filter(sgc => sgc.status !== PE_CHANGE_TYPES.DELETE).findIndex((el) => el.gc_id.toString() === element.id.toString()) !== -1
                                };
                            })
                            this.props.setGroupCollections(JSON.parse(JSON.stringify(data)));
                        }}
                        activeKey="active"
                        isActive={(row) => {
                            return row.active && row.status !== PE_CHANGE_TYPES.DELETE;
                        }}
                        onSetActive={(index, value) => {
                            let myData = [...this.props.groupCollections];
                            let selectedGC = [...this.props.selectedGroupCollections];
                            let selectedGCIndex = selectedGC.findIndex((el) => el.gc_id.toString() === myData[index].id.toString());
                            console.log("myData: ", myData[index]);
                            console.log("selectedGC: ", selectedGC);

                            if (selectedGCIndex === -1) {
                                selectedGC.push({
                                    cid: myData[index].cid,
                                    cName: myData[index].cid != null ? myData[index].name : null,
                                    gc_id: myData[index].id,
                                    gccName: myData[index].gc_cat_id != null ? myData[index].name : null,
                                    quantity: myData[index].quantity,
                                    gc_cat_id: myData[index].gc_cat_id,
                                    status: PE_CHANGE_TYPES.NEW
                                });
                            } else {
                                if (selectedGC[selectedGCIndex].status === PE_CHANGE_TYPES.DELETE) {
                                    console.log("DELETE STATUS: ", selectedGC[selectedGCIndex]);
                                    delete selectedGC[selectedGCIndex].status;
                                    // selectedGC[selectedGCIndex] = {...selectedGC[selectedGCIndex], status: null};
                                } else if (selectedGC[selectedGCIndex].status === PE_CHANGE_TYPES.NEW) {
                                    selectedGC.splice(selectedGCIndex, 1);
                                } else {
                                    selectedGC[selectedGCIndex] = { ...selectedGC[selectedGCIndex], status: PE_CHANGE_TYPES.DELETE };
                                }
                            }
                            myData[index] = { ...myData[index], active: value }
                            this.props.setGroupCollections(myData);
                            this.props.setSelectedGroupCollections(selectedGC);
                        }}
                        activeCompType={GT_ACTIVETYPES.CHECKBOX}
                        noAdd
                    />
                }
            </CollapsibleTile>
        )
    }
}
function mapStateToProps(state) {
    return {
        groupCollections: JSON.parse(JSON.stringify(state.product.groupCollections)),
        selectedGroupCollections: JSON.parse(JSON.stringify(state.product.selectedGroupCollections))
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setGroupCollections: (data) => {
            dispatch(setGroupCollections(data));
        },
        setSelectedGroupCollections: (data) => {
            dispatch(setSelectedGroupCollections(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GCSelector)