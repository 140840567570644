import { Autocomplete, TextField } from '@mui/material';
import React, { Component } from 'react'
import { APIData } from '../constants/APIAdresses';

export class ShopSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: null
        }
    }
    componentDidMount(){
        if(this.props.preSelectedShopId != null){
            this.selectAndLoadShopById(this.props.preSelectedShopId);
        }
    }
    selectAndLoadShopById(id){
        if(id != null){
            fetch(APIData.SHOP + `/shop/${id}/getInformation` , { credentials: "include", headers: { "Content-Type": "application/json" } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("ErrStatus (" + response.status + ") while loading shop by id");
                    }
                }).then(shopData => {
                    console.log("ShopData: ", shopData);
                    if (shopData != null) {
                        let myValue = { ...shopData, label: `[${shopData.id}] `+ shopData.name + ": " + shopData.street + " " + shopData.houseNumber + ", " + shopData.zip + " " + shopData.city };
                        this.setState({ value: myValue });
                        this.notifyParent(myValue);
                    }
                })
                .catch(e => {
                    console.log("Error while /shop/getById: ", e);
                })
        }else{
            this.setState({ value: null });
        }
    }
    loadShopByName(name) {
        if (name.length > 0) {
            // this.setState({ isLoading: true });
            fetch(APIData.SHOP + "/shops/getByName?name=" + name, { credentials: "include", headers: { "Content-Type": "application/json" } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("ErrStatus (" + response.status + ") while loading shop by name");
                    }
                }).then(shopData => {
                    console.log("ShopData: ", shopData);
                    if (shopData?.length > 0) {
                        shopData = shopData.map((item) => { return { ...item, label: `[${item.id}] `+ item.name + ": " + item.street + " " + item.houseNumber + ", " + item.zip + " " + item.city } })
                        this.setState({ options: shopData });
                    }
                })
                .catch(e => {
                    console.log("Error while /shop/getByName: ", e);
                })
        } else {
            this.setState({ options: [] });
        }
    }
    notifyParent(value){
        if(this.props.onSelectShopId != null){
            this.props.onSelectShopId(value?.id);
        }
        if(this.props.onSelectValue != null){
            this.props.onSelectValue(value);
        }
    }
    render() {
        return (
            <div>
                <Autocomplete
                        value={this.state.value}
                        noOptionsText="Keine Ergebnisse"
                        style={{ minWidth: 0 }}
                        options={this.state.options}
                        onFocus={() => {
                            this.setState({value: null, options: []});
                            // this.props.onSelectShopId(null);
                            // this.props.onSelectValue(null);
                            this.notifyParent(null);
                        }}
                        // value={null} // Ensures the input clears after selection
                        onChange={(evt, value) => {
                            console.log("ADD VALUE: ", value);
                            this.setState({ value: value });
                            this.notifyParent(value);
                            // if(value != null){
                            // }else{
                            //     this.notifyParent(null);
                            // }
                        }}
                        renderInput={(params) => (
                            <TextField
                                style={{ minWidth: 0 }}
                                {...params}
                                inputRef={(input) => { this.autocompleteInputRef = input; }} // Store reference to the specific input field
                                onBlur={() => {
                                    // Clear the options after the input loses focus
                                    this.setState({ options: [] });
                                }}
                                onChange={(evt) => {
                                    // Load the options based on user input
                                    this.loadShopByName(evt.target.value);
                                }}
                                placeholder="Shopsuche..."
                            />
                        )}
                    />
            </div>
        )
    }
}

export default ShopSelector