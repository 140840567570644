import { Button, Checkbox, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux';
import { setNutritiondata } from '../../../../redux/productSlice';

const columns = [

    { field: 'defaultLabel', headerName: 'Bezeichnung', width: 150 },
    { field: 'value', headerName: 'Menge', width: 150, editable: true },
]
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export class NutritionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // columns: [{ field: 'isActive', headerName: 'Aktiv', width: 150, renderCell: (params) => { return <Checkbox onClick={(event) => { event.stopPropagation(); this.updateActivity(params.row); }} size='small' style={{ padding: 0 }} checked={params.row.isActive} /> } }, ...columns]
        }
        // Array of refs for each TextField
    }

    updateActivity(row) {
        let rowIndex = this.props.data.findIndex(r => r.id === row.id);
        let rows = [...this.props.data];
        rows[rowIndex].isActive = !rows[rowIndex].isActive;
        this.props.setData([...rows]);
    }



    render() {
        return (
            <div style={{ width: "100%" }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {this.props.data?.map((row, index) => {
                                    return (
                                        <TableCell key={row.id}>
                                            <div
                                                onClick={() => {
                                                    if(index !== 1){
                                                        let myRows = [...this.props.data];
                                                        if (myRows[index].editActive == null) { // if editActive is null, set it to toggled isActive
                                                            myRows[index].editActive = !myRows[index].isActive;
                                                            if(index === 0){
                                                                myRows[1].editActive = !myRows[1].isActive;
                                                            }
                                                        } else if (myRows[index].editActive != myRows[index].isActive) {
                                                            // if editActive is not equal to isActive delete editActive, 
                                                            // since this isn't an edit anymore.
                                                            delete myRows[index].editActive;
                                                            if(index === 0){
                                                                delete myRows[1].editActive;
                                                            }
                                                        }
                                                        this.props.setData([...myRows]);
                                                    }
                                                }}
                                                style={{ display: "flex", cursor: 'pointer', gap: 10, alignItems: "center", }}>
                                                <Checkbox
                                                    disabled={index === 1 /** Disable Brennwert kJ */}
                                                    style={{ padding: 0 }}
                                                    checked={row?.editActive != null ? row?.editActive : row?.isActive}
                                                />
                                                <Typography style={{ fontSize: 12 }}>
                                                    {row.defaultLabel}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ border: 0 }}>
                                {this.props?.data?.map((row, index) => {
                                    return (
                                        <TableCell key={`TBROW_${row.id}_${index}`} component="th" scope="row" style={{}}>
                                            <NT_TextField
                                                row={row}
                                                disabled={index === 1 /** Disable Brennwert kJ */}
                                                onChange={(changeValue) => {
                                                    let myRows = [...this.props.data];
                                                    console.log("ONCHANGEBEFORE: ", myRows[index]);
                                                    if (changeValue === myRows[index].value) {
                                                        delete myRows[index].edited;
                                                        if(index === 0){
                                                            delete myRows[1].edited;
                                                        }
                                                    } else {
                                                        myRows[index].edited = changeValue;
                                                        if(index === 0){
                                                            myRows[1].edited = !isNaN(parseFloat(changeValue)) ? (parseFloat(changeValue) * 4.184).toFixed(2) : ""; // 1 kJ = 4.184 kcal
                                                        }
                                                    }
                                                    console.log("ONCHANGEAFTER: ", myRows[index]);
                                                    this.props.setData([...myRows]);
                                                }}
                                                onBlur={() => {
                                                    let myRows = [...this.props.data];
                                                    if(myRows[index].edited != null && !myRows[index].isActive){
                                                        myRows[index].editActive = true;
                                                        if(index === 0){
                                                            myRows[1].editActive = true;
                                                        }
                                                    }
                                                    if(myRows[index].edited == null && myRows[index].editActive){
                                                        delete myRows[index].editActive;
                                                        if(index === 0){
                                                            delete myRows[1].editActive;
                                                        }
                                                    }
                                                    if(myRows[index].value.length >0 && !myRows[index].editActive){
                                                        delete myRows[index].editActive;
                                                        if(index === 0){
                                                            delete myRows[1].editActive;
                                                        }
                                                    }
                                                    if(myRows[index].edited === ""){
                                                        if(myRows[index].editActive){
                                                            delete myRows[index].editActive;
                                                            if(index === 0){
                                                                delete myRows[1].editActive;
                                                            }
                                                        }else {
                                                            myRows[index].editActive = false;
                                                            if(index === 0){
                                                                myRows[1].editActive = false;
                                                            }
                                                        }
                                                    }
                                                    
                                                
                                                    this.props.setData([...myRows]);

                                                }}
                                                setInactive={() => {
                                                    let myRows = [...this.props.data];
                                                    console.log("SETINACTIVE: ", myRows[index]);
                                                    if (myRows[index].isActive) {
                                                        myRows[index].editActive = false;
                                                        if(index === 0){
                                                            myRows[1].editActive = false;
                                                        }
                                                    } else{
                                                        delete myRows[index].editActive;
                                                        if(index === 0){
                                                            delete myRows[1].editActive;
                                                        }
                                                    }
                                                    this.props.setData([...myRows]);
                                                }}
                                            />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <Button onClick={() => { console.log("ROWS: ", this.props.data) }}>Save</Button> */}
            </div>
        );
    }
}

class NT_TextField extends Component {
    constructor(props) {
        super(props);
        this.textFieldRef = React.createRef();  // Initialize the ref
    }

    handleKeyDown = (evt) => {
        if (evt.key === "Enter") {
            if (this.textFieldRef.current) {
                console.log("ROW IN HANDLEKEYDOWN: ", this.props.row);
                this.textFieldRef.current.blur();  // Blur the TextField if the ref exists
            }
        }
    }

    render() {
        return (
            <TextField
                inputRef={this.textFieldRef}  // Use inputRef for MUI TextField
                size='small'
                onChange={(evt) => {
                    this.props.onChange(evt.target.value);
                }}
                disabled={this.props.disabled}
                onBlur={() => {
                    this.props.onBlur();
                    // if (
                    //     (this.props.row.edited?.length > 0 || this.props.row.value?.length > 0) && 
                    //     this.props.row.edited == null && this.props.row.value.length > 0 ||
                    //     this.props.row.editActive != null
                    // ) {
                    //     console.log("BLUR")
                    //     this.props.onBlur();
                    // } else {
                    //     console.log("SETINACTIVE")
                    //     this.props.setInactive();
                    // }
                }}
                onKeyDown={this.handleKeyDown}  // Handle Enter key press
                value={this.props.row.edited != null ? this.props.row.edited : this.props.row.value}
                type='number'
                style={{ width: "100%" }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        data: JSON.parse(JSON.stringify(state.product.nutritionData)),
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setData: (data) => {
            dispatch(setNutritiondata(data));
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NutritionTable);

