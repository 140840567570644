import { Add, Remove } from '@mui/icons-material'
import { Button, IconButton, Typography } from '@mui/material'
import React, { Component } from 'react'

export class OrderPositionButton extends Component {
    // return <div style={{ backgroundColor: "wheat" }}><b>#{op.position}: </b> {op.quantity}x {op.name} | {(op.isRefund === 1 ? "-" : "") + op.price.toFixed(2)} € TOTAL: {(op.isRefund === 0 ? "-" : "") + (op.quantity * op.price).toFixed(2)} €</div>
    render() {
        return (
            <div style={{ flexBasis:450, height:140, display:"flex", border:'0.5px solid #cecece', borderRadius:5}}>
                <div style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center", borderRight:"0.5px solid #cecece"}}>
                    <Typography>#{this.props.data.position}</Typography>
                </div>
                <div style={{flex:4, display:"flex", flexDirection:"column", gap:10, justifyContent:"center", alignItems:"center"}}>
                    <Typography>{this.props.data.name}</Typography>

                    <Typography>EP: {this.props.data.price.toFixed(2).replace(".",",")} € | GP: {(this.props.data.price*(this.props.data.updatedQuantity != null ? this.props.data.updatedQuantity : this.props.data.quantity)).toFixed(2).replace(".",",")} €</Typography>
                    <Typography>STORNOS: {this.props.alreadyRefundedQty}</Typography>
                </div>
                <div style={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <div onClick={() => {
                        if(!this.props.data.isRefund){

                            this.props.updateQuantity((this.props.data.updatedQuantity != null ? this.props.data.updatedQuantity : this.props.data.quantity)+1)
                        }
                        }} style={{height:40, width:40,backgroundColor:!this.props.data.isRefund ? '#1976d2' : "#cecece", borderTopLeftRadius:5, borderTopRightRadius:5, display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Add style={{color:"white"}}/>
                    </div>
                    <div  style={{height:38, width:38, borderLeft:"0.5px solid #cecece", borderRight:"0.5px solid #cecece", display:"flex", justifyContent:"center", alignItems:"center"}}>
                         <Typography>{this.props.data.updatedQuantity != null ? this.props.data.updatedQuantity : this.props.data.quantity}</Typography>
                         {/* <input value={this.props.data.quantity} style={{height:40, width:34, textAlign:"center"}} /> */}
                    </div>
                    <div onClick={() => {
                        if(!this.props.data.isRefund && (this.props.data.updatedQuantity == null ? this.props.data.quantity-this.props.alreadyRefundedQty > 0 : this.props.data.updatedQuantity-this.props.alreadyRefundedQty > 0)){
                            this.props.updateQuantity((this.props.data.updatedQuantity != null ? this.props.data.updatedQuantity : this.props.data.quantity)-1)
                        }
                    }} style={{height:40, width:40, backgroundColor:(
                            this.props.data.updatedQuantity != null ? this.props.data.updatedQuantity-this.props.alreadyRefundedQty : this.props.data.quantity+this.props.alreadyRefundedQty) > 0 && !this.props.data.isRefund ? '#1976d2' : "#cecece", borderBottomLeftRadius:5, borderBottomRightRadius:5, display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Remove style={{color:"white"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderPositionButton