import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import GenericTable from '../../../components/GenericTable/GenericTable'
import { APIData } from '../../../constants/APIAdresses'
import { MUI_COLORS } from '../../../constants/MUIConstants'
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2'
import NestedGenericTable, { NGT_ACTIVETYPES } from '../../../components/GenericTable/NestedGenericTable'
import { ArrowBack, Edit } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import ProductVariantSelectorTable from './ProductVariantSelectorTable'
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer'
const gcColumns = [
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
    },
    {
        flex: 1,
        label: 'Name',
        dataKey: 'name',
    },
]
const gccpColumns = [
    {
        width: 100,
        headerName: 'ID',
        field: 'id',
    },
    {
        width: 120,
        headerName: 'Produkt-ID.',
        field: 'pid',
    },
    {
        width: 120,
        headerName: 'Varianten-ID.',
        field: 'pvId',
    },
    {
        flex: 1,
        headerName: 'Produktname',
        field: 'name',
    },
    {
        flex: 1,
        headerName: 'Variante',
        field: 'variant',
    }
]
const gcpColumns=[
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
    },
    
    {
        flex: 1,
        label: 'Name',
        dataKey: 'name',
    },
    {
        flex: 1,
        label: 'Beschreibung',
        dataKey: 'pvName',
    }
]
const gcpvColumns=[
    {
        width: 100,
        headerName: 'ID',
        field: 'id',
    },
    {
        flex: 1,
        headerName: 'Name',
        field: 'name',
    },
    {
        width: 120,
        headerName: 'Preis',
        valueGetter: (value) => !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2).replace(".", ",") + " €" : "-",
        field: 'price',
    }
]
export class GroupCategoriesTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            pvTableData: [],
            selectedGCIndex: -1,
            isLoading: false,
        }
        this.shopId = this.props.shopId;
    }
    loadGroupCatProducts(gc_cat_id) {
        fetch(APIData.SHOP + `/shop/${this.shopId}/groupCategories/${gc_cat_id}/getProducts`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    this.props.showAlertDisplay("Fehler beim Laden der Produkte", ALERT_SEVERITY.ERROR);
                }
            }).then(data => {
                console.log("Loaded group cat products: ", data);
            }).catch(e => {
                console.log("Error while loading group cat products: ", e);
            })
    }
    deleteProductFromGroupCat(mainIndex, gc_cat_id) {
        console.log("PROPS: ",mainIndex," CATID: ",gc_cat_id);
        let body ={
            delete: [gc_cat_id]
        }
        console.log("DELETE BODY: ", body);
        this.setState({isLoading: true})
        let url = APIData.SHOP + `/shop/${this.props.shopId}/groupCategory/${this.state.tableData[mainIndex].id}/updateOrCreate`
        fetch(url, { credentials: "include", method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(body) })
        .then(response => {
            this.setState({isLoading: false})
            if(response.ok){
                let myTableData = [...this.state.tableData];
                let index = myTableData[mainIndex].products.findIndex((gc) => gc.id.toString() === gc_cat_id.toString());
                if(index != -1){
                    myTableData[mainIndex].products.splice(index, 1);
                    this.setState({tableData: [...myTableData]});
                    this.props.showAlertDisplay("Produkt erfolgreich aus der Gruppenkategorie gelöscht", ALERT_SEVERITY.SUCCESS);
                }
            }else{
                this.props.showAlertDisplay("Fehler beim Löschen des Produkts aus der Gruppenkategorie", ALERT_SEVERITY.ERROR);
                console.log("ErrStatus while deleting product: ",response.status);
            }
        }).catch(e => {
            console.log("Error while deleting product from group cat: ", e);
            this.props.showAlertDisplay("Fehler beim Löschen des Produkts aus der Gruppenkategorie", ALERT_SEVERITY.ERROR);
            this.setState({isLoading: false})
            })

    }
    addGC(configData){
        let body = {
            id: "0",
            name: configData.fields[0].value,
        }
        let url = APIData.SHOP + `/shop/${this.props.shopId}/groupCategory/0/updateOrCreate`
            fetch(url, { credentials: "include", method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(body) })
            .then(response => {
                if(response.ok){
                    return response.json();
                }
            }).then(result => {
                if(result.gc_cat_id != null){
                    let myTableData = [...this.state.tableData];
                    myTableData.push({id: result.gc_cat_id, name: body.name, products: []});
                    this.setState({tableData: [...myTableData]});
                    this.props.showAlertDisplay("Gruppenkategorie erfolgreich hinzugefügt", ALERT_SEVERITY.SUCCESS);
                    this.props.closeDrawer();
                    console.log("INSERTRESULT: ",result);
                }
            })
            .catch(e => {})
    }
    deleteGC(index){
        // /shop/:sid/groupCategory/:gc_cat_id/delete"
        let url = APIData.SHOP + `/shop/${this.props.shopId}/groupCategory/${this.state.tableData[index].id}/delete`
        fetch(url, { credentials: "include", method: "DELETE", headers: { "Content-Type": "application/json" } })
        .then(response => {
            if(response.ok){
                let myTableData = [...this.state.tableData];
                myTableData.splice(index, 1);
                this.setState({tableData: [...myTableData]});
                this.props.showAlertDisplay("Gruppenkategorie erfolgreich gelöscht", ALERT_SEVERITY.SUCCESS);
            }else{
                if(response.status === 406){
                    this.props.showAlertDisplay("Gruppenkategorie kann nicht gelöscht werden, da sie entweder noch Produkte enthält, in Gruppenkollektionen oder an einer anderen Stelle referenziert wird!", ALERT_SEVERITY.ERROR);
                }
            }
        }).catch(e => {
            this.props.showAlertDisplay("Fehler beim Löschen der Gruppenkategorie", ALERT_SEVERITY.ERROR);
        })
    }
    updateGC(configData){
        if(configData.fields[1].status === GD_STATUS_TYPE.UPDATE){
            let body = {
                id: configData.fields[0].value,
                name: configData.fields[1].value,
            }
            let url = APIData.SHOP + `/shop/${this.props.shopId}/groupCategory/${configData.fields[0].value}/updateOrCreate`
            fetch(url, { credentials: "include", method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(body) })
            .then(response => {
                if(response.ok){
                    this.props.showAlertDisplay("Gruppenkategorie erfolgreich aktualisiert!", ALERT_SEVERITY.SUCCESS);
                    let myTableData = [...this.state.tableData];
                    let index = myTableData.findIndex((gc) => gc.id.toString() === body.id.toString());
                    if(index != -1){
                        myTableData[index].name = configData.fields[1].value;
                        this.setState({tableData: [...myTableData]});
                    }
                }else{
                    console.log("ErrStatus while updating group category: ",response.status);
                    this.props.showAlertDisplay("Fehler beim Aktualisieren der Gruppenkategorie", ALERT_SEVERITY.ERROR);
                }
                this.props.closeDrawer();
            }).catch(e => {
                console.log("Error while updating group category: ", e);
                this.props.showAlertDisplay("Fehler beim Aktualisieren der Gruppenkategorie", ALERT_SEVERITY.ERROR);
                this.props.closeDrawer();
            })
        }else{
            this.props.showAlertDisplay("Keine Änderungen vorgenommen!", ALERT_SEVERITY.INFO);
            this.props.closeDrawer();
        }
    }
    openEditGCDrawer(index){
        let baseDrawerConfig = {
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateGC(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } },
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "id",
                    value: this.state.tableData[index].id,
                    disabled:true,
                },
                {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "name",
                    value: this.state.tableData[index].name,
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.EDIT);
    }
    openAddGroupCatDrawer() {
        let baseDrawerConfig = {
            options: [
                { label: "Speichern", onClick: (configData) => { this.addGC(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } },
            ],
            fields: [
                {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "name",
                    value: "",
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    render() {
        return (
            <CollapsibleTile
                title="Gruppenkategorien"
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../assets/icons/queue.png')} width={20} height={20} />}
            >
                <div style={this.state.selectedGCIndex != -1 ? { height: 0, position: "absolute", top: -500 } : {}}>
                    <NestedGenericTable
                        config={{ columns: [...gcColumns], subColumns: [...gccpColumns], subTableKey: "products" }}
                        apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/groupCategories/getPaged`}
                        tableData={this.state.tableData}
                        onEdit={(index) => {
                            this.openEditGCDrawer(index);
                        }}
                        onDelete={(index) => {
                            this.props.openAlertDialog(
                                [
                                    {
                                        label: "Ja",
                                        color: MUI_COLORS.ERROR,
                                        onChoice: () => {
                                            this.deleteGC(index);
                                        }
                                    },
                                    {
                                        label: "Nein",
                                        onChoice: () => {
                                            console.log("Kollektion wird nicht gelöscht...");
                                        }
                                    }
                                ],
                                "Löschen", "Möchten Sie die Gruppenkategorie '"+this.state.tableData[index].name+"' wirklich löschen?");
                        }}
                        getSubRowId={(subRow) => { return subRow.id }}
                        onClickSubRow={(index, subRow) => {
                            console.log("Clicked subrow: ", index, subRow);
                        }}
                        onSubDelete={(mainIndex, row) => {
                            console.log("ROW: ", row.id);
                            this.props.openAlertDialog(
                                [
                                    {
                                        label: "Ja",
                                        color: MUI_COLORS.ERROR,
                                        onChoice: () => {
                                            this.deleteProductFromGroupCat(mainIndex, row.id);
                                        }
                                    },
                                    {
                                        label: "Nein",
                                        onChoice: () => {
                                            console.log("Produkt wird nicht gelöscht...");
                                        }
                                    }
                                ],
                                "Löschen", "Möchtest du das Produkt '"+row.name+"' wirklich löschen?"
                            )
                        }}
                        setTableData={(data) => { this.setState({ tableData: data }) }}
                        addBtnOnClick={() => {
                            this.openAddGroupCatDrawer();
                         }}
                        addBtnLabel="Kategorie Hinzufügen"
                        addSubBtnLabel="Produkte Anpassen"
                        addSubBtnIcon={<Edit />}
                        addSubBtnOnClick={(mainIndex) => {
                            // this.props.addSubBtnOnClick(mainIndex);
                            this.setState({ selectedGCIndex: mainIndex });
                        }}
                    />
                </div>
                {
                    // TODO: TIDY UP THIS MESS
                    this.state.selectedGCIndex != -1 &&
                    <div style={{display:"flex", flexDirection:"column", gap:25}}>
                        {/* <h1>Selected GC: {this.state.selectedGC}</h1> */}
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ flex: 1 }}></div>
                            <div style={{ flex: 2, display: "flex", justifyContent: "center" }}>
                                <Typography><b>Gruppenkategorie:</b> {this.state.tableData[this.state.selectedGCIndex]?.name} ({this.state.tableData[this.state.selectedGCIndex]?.id})</Typography>
                            </div>
                            <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>
                                <Button variant='outlined' startIcon={<ArrowBack />} onClick={() => { this.setState({ selectedGCIndex: -1 }) }}>Zurück</Button>
                            </div>
                        </div>
                        <ProductVariantSelectorTable 
                            config = {{columns: [...gcpColumns], subColumns: [...gcpvColumns], subTableKey: "variantData"}}
                            showAlert={this.props.showAlertDisplay}
                            apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/groupCategory/${this.state.tableData[this.state.selectedGCIndex]?.id}/loadPagedProducts`}
                            setTableData={(data) => { this.setState({ pvTableData: data }) }}
                            tableData={this.state.pvTableData}
                            removeMainTableProduct={(id) => {
                                console.log("DELETING: ", id);
                                let myTableData = [...this.state.tableData];
                                console.log("2DELETING: ", myTableData[this.state.selectedGCIndex].products);
                                let index = myTableData[this.state.selectedGCIndex].products.findIndex((gc) => gc.id.toString() === id.toString());
                                console.log("3DELETING: ", myTableData[this.state.selectedGCIndex].products);
                                if(index != -1){
                                    myTableData[this.state.selectedGCIndex].products.splice(index, 1);
                                    console.log("3DELETING: ", myTableData[this.state.selectedGCIndex].products);
                                    this.setState({tableData: [...myTableData]});
                                }
                            }}
                            updateMainTableProducts={(data) => {
                                console.log("############UPDATE MAIN TABLE PRODUCTS: ", data);
                                let myTableData = [...this.state.tableData];
                                
                                console.log("############UPDATE MAIN TABLE PRODUCTS: ", myTableData[this.state.selectedGCIndex].products);
                                let index = myTableData[this.state.selectedGCIndex].products.findIndex((gc) => gc.id === data.id)
                                if(index != -1){
                                    myTableData[this.state.selectedGCIndex].products[index] = {...data};
                                }else {
                                    myTableData[this.state.selectedGCIndex].products.push({...data});
                                }
                                console.log("AFTER INSERT: ", myTableData[this.state.selectedGCIndex].products);
                                this.setState({tableData: [...myTableData]});
                            }}
                            groupCategoryId={this.state.tableData[this.state.selectedGCIndex]?.id}
                            shopId={this.shopId}
                            onSetActive={(index, value) => {
                                let myTableData = [...this.state.pvTableData];
                                myTableData[index].isInGroupCat = value;
                                // myTableData[index].id = selec;
                                this.setState({ pvTableData: myTableData });
                            }}
                        />
                        {/* <NestedGenericTable
                            
                            config={{ columns: [...gcpColumns], subColumns: [...gcpvColumns], subTableKey: "variantData" }}
                            // apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/groupCategory/${this.state.tableData[this.state.selectedGCIndex]?.id}/loadPagedProducts`}
                            apiEndPoint={APIData.SHOP + `/shop/1/groupCategory/1/loadPagedProducts`}
                            tableData={this.state.pvTableData}
                            activeKey="isInGroupCat"
                            isActive={(row) => {return row.isInGroupCat === "1"}}
                            onSetActive={(index, active) => {
                                console.log("WTF???");
                                // this.onSetActive(index, active);
                            }}
                            getSubRowId={(subRow) => { console.log("ROW??: ",subRow); return subRow.id }}
                            onClickSubRow={(index, subRow) => {
                                console.log("Clicked subrow: ", index, subRow);
                            }}
                            activeCompType={NGT_ACTIVETYPES.RADIO}

                            setTableData={(data) => { this.setState({ pvTableData: data }) }}
                            
                            noAdd noSubAdd
                        /> */}
                    </div>
                }
            </CollapsibleTile>
        )
    }
}

export default GroupCategoriesTile