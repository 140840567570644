import React, { Component } from 'react'
import Header from '../../components/Header'
import { connect } from 'react-redux'
// import { PermissionAreaComponent } from '../../hookInjection/PermissionAreaComponent'
import { setRole } from '../../redux/permissionSlice'
import DefaultFrame from '../../components/DefaultFrame'
// import PermissionAreaComponent from '../../hookInjection/PermissionAreaComponent'
import GenericTable from '../../components/GenericTable/GenericTable'
import { APIData } from '../../constants/APIAdresses'
import OrderTable from './OrderTable'
import { formatDateToGermanTime } from '../../Essentials'
import { DELIVERY_OPTION, ORDER_STATUS, ORDER_STATUS_LABEL } from '../../constants/Constants'
import { Typography } from '@mui/material'
import { ALERT_SEVERITY } from '../../components/AlertDisplay2'
const orderColumns = [

    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        width: 80,
        label: 'Lieferart',
        dataKey: 'deliveryOption',
        format: (data) => {
            return <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", gap: 10 }}>
                <img src={require(`./../../assets/icons/${data === DELIVERY_OPTION.DELIVERY ? 'deliveryscooter.png' : 'take-away.png'}`)} width={20} height={20} />
                <Typography>{data === DELIVERY_OPTION.DELIVERY ? 'Lieferung' : 'Abholung'}</Typography>
            </div>
        }
    },
    {
        width: 80,
        label: 'Status',
        dataKey: 'status',
        format: (data, row) => {
            let statusItems = getDeliveryStatusIconAndLabel(data, row);
            return <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", gap: 10 }}>
                <img src={statusItems.icon} width={20} height={20} />
                <Typography>{statusItems.statusLabel}</Typography>
            </div>
        }
    },
    {
        width: 120,
        label: 'Shop',
        dataKey: 'shopName',
    },
    // {
    //     width: 90,
    //     label: 'Zahlungsart',
    //     dataKey: 'paymentMethod',
    // },

    {
        width: 150,
        label: 'Name',
        dataKey: 'fullname',
    },
    {
        width: 150,
        label: 'Adresse',
        dataKey: 'street',
        format: (data, row) => {
            return <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <Typography fontSize={14}>{row.fullname}</Typography>
                <Typography fontSize={14}>{`${row.company != null ? row.company + " - " : ""}${row.street} ${row.houseNumber}, ${row.zip} ${row.city}`}</Typography>
                {row.phone != null &&
                    <Typography fontSize={14}>{row.phone}</Typography>
                }
            </div>
        }
    },
    {
        width: 150,
        label: 'Rechnungsadresse',
        dataKey: 'InvStreet',
        format: (data, row) => {
            return row.sameInvoiceAddress === 1 ? "-" : <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <Typography fontSize={14}>{row.InvFullname}</Typography>
                <Typography fontSize={14}>{`${row.InvCompany != null ? row.InvCompany + " - " : ""} ${row.InvStreet} ${row.InvNumber}, ${row.InvZip} ${row.InvCity}`}</Typography>
                {row.InvPhone != null &&
                    <Typography fontSize={14}>{row.InvPhone}</Typography>
                }
            </div>
        }
    },
    {
        width: 120,
        label: 'Datum',
        dataKey: 'createDate',
        format: (data) => {
            return formatDateToGermanTime(data);
        }
    },
]
function getDeliveryStatusIconAndLabel(data, row) {
    let icon = null;
    let statusLabel = "";
    switch (data) {
        case ORDER_STATUS.PENDING:
            icon = require('./../../assets/icons/timer.png');
            statusLabel = ORDER_STATUS_LABEL.PENDING
            break;
        case ORDER_STATUS.BEINGPREPARED:
            icon = require('./../../assets/icons/cooking.png');
            statusLabel = ORDER_STATUS_LABEL.BEINGPREPARED
            break;
        case ORDER_STATUS.ONTHEWAY:
            icon = row.deliveryOption === DELIVERY_OPTION.DELIVERY ? require('./../../assets/icons/deliveryscooter.png') : require('./../../assets/icons/take-away.png');
            statusLabel = row.deliveryOption === DELIVERY_OPTION.DELIVERY ? ORDER_STATUS_LABEL.ONTHEWAY : ORDER_STATUS_LABEL.READYTOPICKUP;
            break;
        case ORDER_STATUS.DELIVERY_ISSUE:
            icon = require('./../../assets/icons/warning.png');
            statusLabel = ORDER_STATUS_LABEL.DELIVERY_ISSUE;
            break;
        case ORDER_STATUS.DELIVERED:
            icon = require('./../../assets/icons/checked_filled.png');
            statusLabel = ORDER_STATUS_LABEL.DELIVERED;
            break;
        case ORDER_STATUS.CANCELED:
            icon = require('./../../assets/icons/error_filled.png');
            statusLabel = ORDER_STATUS_LABEL.CANCELED;
            break;
        default:
            icon = require('./../../assets/icons/error_filled.png');
            statusLabel = ORDER_STATUS_LABEL.ERROR;
            break;
    }
    return { icon: icon, statusLabel: statusLabel };
}
export class OrderManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderData: []
        }
    }
    updateQuantity(index, opIndex, value) {
        console.log("Update Quantity: ", index, opIndex, value);
        let myOrderData = [...this.state.orderData];
        if (myOrderData[index].orderPositions[opIndex].quantity === value) {
            delete myOrderData[index].orderPositions[opIndex].updatedQuantity;
        } else {
            myOrderData[index].orderPositions[opIndex].updatedQuantity = value;
        }
        this.setState({ orderData: myOrderData });
    }
    updateStatus(index, status) {
        console.log("UPDATING::: ", index, status);
        let myTableData = [...this.state.orderData];
        if (parseInt(myTableData[index].status) === status) {
            delete myTableData[index].updatedStatus;
        } else {
            myTableData[index].updatedStatus = status;
        }
        this.setState({ orderData: myTableData });
    }
    updateDiscount(index, discount) {
        let myTableData = [...this.state.orderData];
        if (discount.length > 0) {
            myTableData[index].discount = discount;
        } else {
            delete myTableData[index].discount;
        }
        this.setState({ orderData: myTableData });
    }
    onSave(index) {
        let myData = { ...this.state.orderData[index] };
        let body = { id: myData.id, shopId: myData.shopId };
        if (myData.updatedStatus != null) {
            body.status = myData.updatedStatus;
        }
        if (myData.discount != null) {
            body.discount = myData.discount;
        }
        if (myData.orderPositions != null) {
            myData.orderPositions.forEach((op, index) => {
                if (op.updatedQuantity != null) {
                    if (body.quantities == null) {
                        body.quantities = {};
                    }
                    body.quantities[op.position] = op.updatedQuantity;
                }
            });
        }
        console.log("UPDATED BODY: ", body);
        console.log("UPDATED DATA: ", myData);

        if (Object.keys(body).length > 2) {
            let myTableData = [...this.state.orderData];
            myTableData[index].isLoading = true;
            this.setState({ orderData: myTableData });
            fetch(APIData.USER + "/order/update", { credentials: 'include', headers: { 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(body) })
                .then((response) => {
                    if (response.ok) {
                        console.log("LOAD DATA AGAIN");
                        fetch(APIData.USER + "/order/getWithPositions/"+myData.id, { credentials: 'include' })
                        .then(response => {
                            if(response.ok){
                                return response.json();
                            }else{
                                this.props.showAlertDisplay("Fehler beim Laden der aktualisierten Bestellung ("+response.status+")", ALERT_SEVERITY.ERROR);
                            }
                        }).then(result => {
                            if(result != null){
                                let myTableData = [...this.state.orderData];
                                result.orderPositions = typeof result.orderPositions === "string" ? JSON.parse(result.orderPositions) : result.orderPositions;
                                myTableData[index] = result;
                                delete myTableData[index].isLoading;
                                this.setState({ orderData: myTableData });
                            }
                        }).catch(e => {
                            this.props.showAlertDisplay("Fehler beim Laden der aktualisierten Bestellung", ALERT_SEVERITY.ERROR);
                            console.log("Error while reloading order: ", e);
                        })
                        this.props.showAlertDisplay("Bestellung erfolgreich gespeichert", ALERT_SEVERITY.SUCCESS);
                    } else {
                        this.props.showAlertDisplay("Fehler beim Speichern der Bestellung", ALERT_SEVERITY.ERROR);
                        delete myTableData[index].isLoading;
                        this.setState({ orderData: myTableData });
                    }
                }).catch(e => {
                    console.log("Error while updating order: ", e);
                    delete myTableData[index].isLoading;
                    this.setState({ orderData: myTableData });
                    this.props.showAlertDisplay("Fehler beim Speichern der Bestellung", ALERT_SEVERITY.ERROR);
                })
        } else {
            this.props.showAlertDisplay("Keine Änderungen erkannt", ALERT_SEVERITY.INFO);
        }
    }
    render() {
        return (
            <>
                <OrderTable
                    config={{ columns: [...orderColumns] }}
                    apiEndPoint={APIData.USER + "/order/getPaged"}
                    tableData={this.state.orderData}
                    setTableData={(data) => { this.setState({ orderData: data }) }}
                    updateQuantity={(index, opIndex, value) => { this.updateQuantity(index, opIndex, value) }}
                    updateStatus={(index, status) => { this.updateStatus(index, status) }}
                    updateDiscount={(index, discount) => { this.updateDiscount(index, discount) }}
                    onSave={(index) => { this.onSave(index) }}
                />
            </>
        )
    }
}
class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Bestellverwaltung">
                <OrderManagement {...this.props} />
            </DefaultFrame>
        )
    }
}
export default defaultWrapper;
// function mapStateToProps(state){
//     return {
//         role: state.permission.role
//     }
// }
// function mapDispatchToProps(dispatch){
//     return {
//         setRole: (role) => {
//             dispatch(setRole(role));
//         }
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(OrderManagement);
