import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import { APIData } from '../../../constants/APIAdresses';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';
import MultipleAutocomplete from '../../../components/MultipleAutocomplete';
import { Save } from '@mui/icons-material';

export class SectorsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            // sectors: [],
            shopSectors: [],
            loadedSectors: []
        }
        this.loadedSectors = [];
        this.initialShopSectors = [];
    }
    loadSectors() {
        return new Promise((resolve, reject) => {
            fetch(APIData.SHOP + "/sectors", { credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return null;
                    }
                }).then(result => {
                    if (result) {
                        let sectors = result.map(s => { return { id: s.id, title: s.nameKey } });
                        console.log("SECTORS: ", sectors);
                        // this.loadedSectors=sectors;
                        this.setState({ loadedSectors: sectors });
                        // this.setState({ sectors:  sectors});
                        resolve();
                    } else {
                        this.props.showAlert("Fehler beim Laden der Sektoren.", ALERT_SEVERITY.ERROR);
                        reject();
                    }
                }).catch(e => {
                    console.log("Error while loading sectors: ", e);
                    reject();
                    this.props.showAlert("Fehler beim Laden der Branchen.", ALERT_SEVERITY.ERROR);
                })
        })
    }
    loadShopSectors() {
        fetch(APIData.SHOP + "/shop/" + this.props.shopId + "/sectors", { credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result) {
                    this.initialShopSectors = result.map(s => { return { id: s.id, title: s.nameKey } });
                    this.setState({ shopSectors: [...this.initialShopSectors], loading: false });
                } else {
                    this.setState({ loading: false });
                    this.props.showAlert("Fehler beim Laden der Shopbranchen.", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while loading shop sectors: ", e);
                this.setState({ loading: false });
                this.props.showAlert("Fehler beim Laden der Shopbranchen.", ALERT_SEVERITY.ERROR);
            })
    }
    load() {
        this.setState({ loading: true });
        this.loadSectors().then(() => {
            this.loadShopSectors();
        }).catch(e => {
            this.setState({ loading: false });
        });
    }
    addSector(id) {
        this.setState({ loading: true });
        fetch(APIData.SHOP + "/shop/" + this.props.shopId + "/addSector/" + id, { credentials: "include", method: 'PUT', headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (!response.ok) {
                    this.props.showAlert("Fehler beim Hinzufügen der Branche.", ALERT_SEVERITY.ERROR);
                    this.setState({ shopSectors: [] }, () => {
                        this.load();
                    });
                } else {
                    this.props.showAlert(`Branche hinzugefügt.`, ALERT_SEVERITY.SUCCESS);
                    this.setState({ loading: false });
                }
            }).catch(e => {
                console.log("Error while adding sector: ", e);
                this.props.showAlert("Fehler beim Hinzufügen der Branche.", ALERT_SEVERITY.ERROR);
                this.setState({ shopSectors: [] }, () => {
                    this.load();
                });
            })
    }
    removeSector(id) {
        this.setState({ loading: true });
        fetch(APIData.SHOP + "/shop/" + this.props.shopId + "/removeSector/" + id, { credentials: "include", method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (!response.ok) {
                    this.setState({ shopSectors: [] }, () => {
                        this.load();
                    });
                    this.props.showAlert("Fehler beim Entfernen der Branche.", ALERT_SEVERITY.ERROR);
                } else {
                    this.setState({ loading: false });
                    this.props.showAlert(`Branche entfernt.`, ALERT_SEVERITY.SUCCESS);
                }
            }).catch(e => {
                console.log("Error while removing sector: ", e);
                this.props.showAlert("Fehler beim Entfernen der Branche.", ALERT_SEVERITY.ERROR);
                this.setState({ shopSectors: [] }, () => {
                    this.load();
                });
            })
    }
    render() {
        return (
            <CollapsibleTile
                onCollapseChange={(isExpanded, firstExpand) => {
                    console.log("ONCOLLAPSE???")
                    if (isExpanded && firstExpand) {
                        this.load();
                    }
                }}
                // actions={[
                //     { label: "Zurücksetzen", onClick: () => { this.setState({shopSectors: []}, () => {this.setState({shopSectors: [...this.initialShopSectors]})}) } }
                //     { label: "Speichern", icon: <Save />, onClick: () => {  } }
                // ]}
                title="Branchen"
                icon={<img src={require('../../../assets/icons/franchise.png')} width={20} height={20} />}
                isLoading={this.state.loading}
            >

                <MultipleAutocomplete
                    options={this.state.loadedSectors} // Options should match shopSectors in structure
                    defaultValues={this.state.shopSectors} // These should be derived from sectors
                    // label="Branchen"
                    onChange={(newValue) => { console.log("NEW VALUE: ", newValue) }}

                    onAdd={(item) => {
                        console.log('Added:', item)
                        this.addSector(item.id);
                    }}
                    onDelete={(item) => {
                        console.log('Deleted:', item);
                        this.removeSector(item.id);
                    }}
                    // onDelete={(item) => console.log('Deleted:', item)}
                    filterOptions={(options, { inputValue }) =>
                        options.filter(option => !this.state.shopSectors.some(value => value.id === option.id))
                    } // Prevent selecting already selected values
                />

            </CollapsibleTile>
        )
    }
}

export default SectorsTile