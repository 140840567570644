import React, { Component } from 'react'
import CollapsibleTile from '../../../../components/CollapsibleTile'
import { Add } from '@mui/icons-material'
import { setProductVariants } from '../../../../redux/productSlice'
import { connect } from 'react-redux'
import ProductVariant from './ProductVariant'
import { PE_CHANGE_TYPES } from './ProductEditor'

export class ProductVariantArea extends Component {
    componentDidMount(){
        console.log("PRODUCTVARIANTS: ", this.props.productVariants);
    }
    addNewProductVariant () {
        let myPV = [...this.props.productVariants];
        myPV.push({
            name: "",
            info: "",
            price: 0.0,
            collData: [],
            pgData: [],
            allergens: [],
            status: PE_CHANGE_TYPES.NEW
        });
        this.props.setProductVariants(myPV);
    }
    render() {
        return (
            <CollapsibleTile
                title="Produktvarianten"
                onCollapseChange={(expanded, firstExpand) => { }}
                // defaultExpanded
                icon={<img src={require('../../../../assets/icons/distribution.png')} width={20} height={20} />}
                actions={
                    [
                        { label: "Produktvariante Hinzufügen", icon: <Add />, onClick: () => { this.addNewProductVariant()} },
                    ]
                }
            >
                <div style={{display:"flex", flexDirection:"column", gap:15}}>
                    {this.props.productVariants.map((data, index) => {
                        return ( data.status !== PE_CHANGE_TYPES.DELETE &&
                            <ProductVariant key={index} index={index} shopId={this.props.shopId} data={data} onDelete={() => {
                                let myPV = [...this.props.productVariants];
                                if(myPV[index].status === PE_CHANGE_TYPES.NEW){
                                    console.log("PV IS NEW SPLICING...")
                                    myPV.splice(index, 1);
                                } else {
                                    console.log("PV IS NOT NEW SETTING DELETE...")
                                    myPV[index].status = PE_CHANGE_TYPES.DELETE;
                                }
                                this.props.setProductVariants(myPV);
                            }} />
                        )
                    })}
                </div>
            </CollapsibleTile>
        )
    }
}

function mapStateToProps(state) {
    console.log("PVLEFT: ",state.product.productVariants?.filter(pv => pv.status !== PE_CHANGE_TYPES.DELETE).length);
    return {
        productVariants: JSON.parse(JSON.stringify(state.product.productVariants))
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // addProductVariant: (variant) => {
        //     dispatch(addProductVariant(variant));
        // },
        // removeProductVariant: (variant) => {
        //     dispatch(removeProductVariant(variant));
        // },
        setProductVariants: (variants) => {
            dispatch(setProductVariants(variants));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantArea)