import { Delete } from '@mui/icons-material'
import { TableBody, TableContainer, TableRow, Table, TableHead, IconButton, TableCell, tableCellClasses } from '@mui/material'
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react'
import { PE_CHANGE_TYPES } from '../../areas/partnermanagement/ShopContent/ProductEditor/ProductEditor';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#b9d3e4',
        // backgroundColor: '#adbdc8',
        color: '#5a5a5a',
        // fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export class StaticTable extends Component {
    render() {
        return (
            <TableContainer sx={{ flex: 1, scrollbarWidth: 'none', msOverflowStyle: 'none', borderRadius: 2 }} component={Box}>
                <Table stickyHeader sx={{ minWidth: 700, }} size='small' aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {this.props.columns.map((column) => {
                                return <StyledTableCell sx={column.width != null ? { width: column.width } : {}} key={column.dataKey}  >{column.label}</StyledTableCell>
                            })}
                            {this.props.onDelete != null &&
                                <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}></StyledTableCell>
                            }
                            {
                                this.props.onEdit != null &&
                                <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}></StyledTableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.tableData?.filter(item => item.status !== PE_CHANGE_TYPES.DELETE).length > 0 ?
                            this.props.tableData.map((row, index) => {
                                return row.status !== PE_CHANGE_TYPES.DELETE && <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={"SELECTOR_ROW_" + index}>
                                    {this.props.columns.map((column) => {
                                        return <StyledTableCell onClick={() => { }} key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey] != null ? row[column.dataKey] : "-"}</StyledTableCell>
                                    })}
                                    {this.props.onDelete != null &&
                                        <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}>
                                            <IconButton onClick={() => {
                                                let myTableData = [...this.props.tableData];
                                                if (myTableData[index].status === PE_CHANGE_TYPES.NEW) {
                                                    myTableData.splice(index, 1);
                                                } else if (myTableData[index].status === PE_CHANGE_TYPES.DELETE) {
                                                    delete myTableData[index].status;
                                                } else {
                                                    myTableData[index] = { ...myTableData[index], status: PE_CHANGE_TYPES.DELETE };
                                                }
                                                this.props.setTableData(myTableData);
                                                this.props.onDelete(index);
                                            }}>
                                                <Delete color='primary' />
                                            </IconButton>

                                        </StyledTableCell>
                                    }
                                </StyledTableRow>
                            }) :
                            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <StyledTableCell sx={{ padding: "25px", textAlign: "center" }} colSpan={this.props.columns.length + (this.props.onDelete != null ? 1 : 0)}>Keine Daten</StyledTableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

export default StaticTable