export function formatDateToGermanTime(dateString) {
    const date = new Date(dateString);
    
    // Convert to German time zone (CET/CEST)
    const options = {
        timeZone: 'Europe/Berlin',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    
    const germanTime = date.toLocaleString('de-DE', options);

    // Convert 'de-DE' format to DD.MM.YYYY HH:MM:SS
    return germanTime.replace(',', '');
}