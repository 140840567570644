import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile';
import { Close, DeliveryDining, Edit, KeyboardArrowDown, Restaurant } from '@mui/icons-material';
import { APIData } from '../../../constants/APIAdresses';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../../constants/MUIConstants';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';
import { Button, TextField, Typography } from '@mui/material';
import DeliveryRuleButton from './DeliveryRuleButton';
import { GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer';

export class DeliveryRulesTile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rules: [],
            rulesLoading: false,
            loadReachedEnd: false,
            editActive: false,
            zipValue: "",
            zipValueErr: "",
            amount: "",
            deliveryFee: "",
            deliveryFeeErr: "",
            amountErr: "",
            filter: "",
        }
        this.offSetMultiplier = -1;
        this.loadingActive = false;
    }
    loadRules() {
        console.log("LOADING RULES...");
        if (!this.state.loadReachedEnd && !this.loadingActive) {
            console.log("LOADING RULES...1");
            this.offSetMultiplier++;
            this.setState({ rulesLoading: true });
            this.loadingActive = true;
            fetch(APIData.SHOP + `/shop/${this.props.shopId}/zipRules?offsetMultiplier=${this.offSetMultiplier}`, { credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return null;
                    }
                }).then(result => {
                    console.log("ZIPRULES: ", result);
                    this.loadingActive = false;
                    if (result != null) {
                        if (result.length > 0) {
                            this.setState({ rules: [...result], rulesLoading: false, loadReachedEnd: result.length < 20 });
                        } else {
                            this.setState({ rulesLoading: false, loadReachedEnd: true });
                        }
                    } else {
                        this.setState({ rulesLoading: false });
                        this.props.showAlert("Fehler beim Laden der Lieferregeln.", ALERT_SEVERITY.ERROR);
                    }
                }).catch(e => {
                    this.setState({ rulesLoading: false });
                    this.loadingActive = false;
                    console.log("Error while loading delivery rules: ", e);
                    this.props.showAlert("Fehler beim Laden der Lieferregeln.", ALERT_SEVERITY.ERROR);
                })
        }
    }
    setRuleActive(index, active) {
        let newRules = [...this.state.rules];
        newRules[index].isActive = active;
        this.setState({ rules: [...newRules] });
        const showErrAndReset = () => {
            this.props.showAlert("Fehler beim Ändern der Regel.", ALERT_SEVERITY.ERROR);
            newRules[index].isActive = !active;
            this.setState({ rules: [...newRules] });
        }
        fetch(APIData.SHOP + `/shop/${this.props.shopId}/toggleZipRule/${newRules[index].id}/${active ? 1 : 0}`, { credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    this.props.showAlert("Regel erfolgreich geändert.", ALERT_SEVERITY.SUCCESS);
                } else {
                    showErrAndReset();
                }
            }).catch(e => {
                console.log("Error while toggling rule: ", e);
                showErrAndReset();
            })
    }
    deleteRule(index) {
        let newRules = [...this.state.rules];
        fetch(APIData.SHOP + `/shop/${this.props.shopId}/deleteZipRule/${newRules[index].id}`, { credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    newRules.splice(index, 1);
                    this.setState({ rules: [...newRules] });
                    this.props.showAlert("Regel erfolgreich gelöscht.", ALERT_SEVERITY.SUCCESS);
                } else {
                    this.props.showAlert("Fehler beim Löschen der Regel.", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while deleting rule: ", e);
                this.props.showAlert("Fehler beim Löschen der Regel.", ALERT_SEVERITY.ERROR);
            })
    }
    addZipRule() {
        let body = {
            zip: this.state.zipValue,
            minOrderAmount: this.state.amount.replace(",", "."),
        }
        if (this.state.deliveryFee !== "") {
            body.deliveryFee = this.state.deliveryFee.replace(",", ".");
        }
        this.setState({ rulesLoading: true });
        this.loadingActive = true;
        fetch(APIData.SHOP + `/shop/${this.props.shopId}/addZipRule`, { credentials: "include", method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    // return response.json();
                    this.offSetMultiplier = -1;
                    this.loadingActive = false;
                    response.json().then(result => {
                        this.setState({ zipValue: "", amount: "", loadReachedEnd: false, rulesLoading: false, rules: [...this.state.rules, { id: result.id, isActive: 1, ...body }] });

                    }).catch(e => {
                        console.log("Error while adding zip rule: ", e);
                    })
                    this.zipTextFieldRef.focus();
                    this.props.showAlert("Regel erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                } else {
                    throw new Error("Error while adding zip rule: " + response.status);
                }
            }).catch(e => {
                this.setState({ rulesLoading: false });
                this.loadingActive = false;
                console.log("Error while adding zip rule: ", e);
                this.props.showAlert("Fehler beim Hinzufügen der Regel.", ALERT_SEVERITY.ERROR);
            })
    }
    updateZipRule(index, configData) {
        // let body = {
        //     isActive: configData.fields[1].value,
        //     zip: configData.fields[2].value,
        //     minOrderAmount: configData.fields[3].value,
        //     deliveryFee: configData.fields[4].value
        // }
        let filtered = configData.fields.filter((field) => field.status !== GENERIC_DRAWER_TYPE.UPDATE);
        let body = {};
        filtered.forEach((field) => {
            body[field.dbKey] = field.value;
        })
        console.log("BODY: ", body);
        if (Object.keys(body)) {
            fetch(APIData.SHOP + `/shop/${this.props.shopId}/updateZipRule/${configData.fields[0].value}`,
                {
                    credentials: "include", method: 'POST', body: JSON.stringify(body),
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => {
                    if(response.ok){
                        let newRules = [...this.state.rules];
                        newRules[index] = {...this.state.rules[index], ...body };
                        this.setState({ rules: [...newRules] });
                        this.props.showAlert("Regel erfolgreich geändert.", ALERT_SEVERITY.SUCCESS);
                        this.props.closeDrawer();
                    }else{
                        console.log("Error while updating zip rule: ", response.status);
                    }
                }).catch(e => {
                    console.log("Error while updating zip rule: ", e);
                })
        }else {
            console.log("Nothing in the body...")
        }
    }
    openEditDrawer(index) {
        let baseDrawerConfig = {
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateZipRule(index, configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "ID",
                    disabled: true,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.rules[index].id,
                    dbKey: "id",
                },
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isActive",
                    value: this.state.rules[index].isActive,
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "zip",
                    maxChar: 5,
                    isNumeric: true,
                    value: this.state.rules[index].zip,
                },
                {
                    label: "Mindestbestellwert",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "minOrderAmount",
                    maxChar: 5,
                    isNumeric: true,
                    value: this.state.rules[index].minOrderAmount,
                },
                {
                    label: "Liefergebühr",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "deliveryFee",
                    maxChar: 5,
                    isNumeric: true,
                    value: this.state.rules[index].deliveryFee,
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GENERIC_DRAWER_TYPE.UPDATE);
    }
    render() {
        return (
            <CollapsibleTile
                onCollapseChange={(isExpanded, firstExpand) => {
                    console.log("ONCOLLAPSE???")
                    if (isExpanded && firstExpand) {
                        this.loadRules();
                    }
                }}
                title="Liefergebiete"
                icon={<img src={require('../../../assets/icons/map.png')} width={20} height={20} />}
                isLoading={this.state.rulesLoading}
                actions={[
                    {
                        label: "Mehr Laden",
                        icon: <KeyboardArrowDown />,
                        variant: MUI_BTN_VARIANTS.OUTLINED,
                        disabled: this.state.loadReachedEnd || this.state.rulesLoading || this.loadingActive,
                        onClick: () => { this.loadRules() }
                    },
                    {
                        label: !this.state.editActive ? "Hinzufügen" : "Abbrechen",
                        icon: !this.state.editActive ? <Edit /> : <Close />,
                        variant: !this.state.editActive ? MUI_BTN_VARIANTS.CONTAINED : MUI_BTN_VARIANTS.TEXT,
                        onClick: () => { this.setState({ editActive: !this.state.editActive }) }
                    }
                ]}
            >
                {
                    this.state.editActive &&
                    <div style={{ border: "0.5px solid #cecece", borderRadius: 4, padding: 20, marginBottom: 20, }}>
                        <div style={{ gap: 10, display: "flex" }}>
                            <TextField inputRef={(ref) => { this.zipTextFieldRef = ref }} size='small' style={{ flex: 1 }} helperText='' value={this.state.zipValue} onChange={(evt) => {
                                if (evt.target.value.startsWith("-") && evt.target.value.length <= 6) {
                                    if (evt.target.value === "-" && this.state.zipValue.length === 0) {
                                        this.setState({ zipValue: "-11111" });
                                    } else {
                                        this.setState({ zipValue: "" })
                                    }
                                }
                                if (evt.target.value.match(/^[0-9]*$/) && evt.target.value.length <= 5) {
                                    this.setState({ zipValue: evt.target.value })
                                }
                            }}
                                onKeyDown={(evt) => { console.log("EVTKEY: ", evt.key); if (evt.key === "Enter") { console.log("Enter pressed..."); this.amountTxtFieldRef.focus(); } }}
                                label='Postleitzahl' />
                            <TextField inputRef={(ref) => { this.amountTxtFieldRef = ref }} size='small' style={{ flex: 1 }} value={this.state.amount} onChange={(evt) => {
                                if (evt.target.value.match(/^[0-9]*[.,]?[0-9]{0,2}$/)) {
                                    let myCheckStr = evt.target.value.replace(".", ",");
                                    if (myCheckStr === "0") {
                                        if (this.state.amount === "") {
                                            myCheckStr = "0,";
                                        } else {
                                            myCheckStr = "";
                                        }
                                    }
                                    // evt.target.value = evt.target.value.replace(",", ".");
                                    myCheckStr = myCheckStr.startsWith(",") ? "0" + myCheckStr : myCheckStr;

                                    this.setState({ amount: myCheckStr });
                                }
                            }}
                                onKeyDown={(evt) => { if (evt.key === "Enter") this.addZipRule(); }}
                                label='Mindestbestellwert' />
                            <TextField size='small' style={{ flex: 1 }} value={this.state.deliveryFee} onChange={(evt) => {
                                if (evt.target.value.match(/^[0-9]*[.,]?[0-9]{0,2}$/)) {
                                    let myCheckStr = evt.target.value.replace(".", ",");
                                    if (myCheckStr === "0") {
                                        if (this.state.deliveryFee === "") {
                                            myCheckStr = "0,";
                                        } else {
                                            myCheckStr = "";
                                        }
                                    }
                                    // evt.target.value = evt.target.value.replace(",", ".");
                                    myCheckStr = myCheckStr.startsWith(",") ? "0" + myCheckStr : myCheckStr;

                                    this.setState({ deliveryFee: myCheckStr });
                                }
                            }}
                                onKeyDown={(evt) => { if (evt.key === "Enter") this.addZipRule(); }}
                                label='Liefergebühr' />
                        </div>
                        <Button style={{ width: "100%", marginTop: 10 }} onClick={() => { this.addZipRule() }} variant='contained' startIcon={<DeliveryDining />}>Hinzufügen</Button>
                    </div>
                }

                {this.state.rules.length > 0 ?

                    <>
                        <TextField size='small' style={{ width: "100%" }} value={this.state.filter} onChange={(evt) => { if (evt.target.value.match(/^[0-9]*$/) && evt.target.value.length <= 5) this.setState({ filter: evt.target.value }) }} label='Filter...' />
                        <div style={{ display: "flex", marginTop: 20, flexWrap: "wrap", gap: 20 }}>
                            {

                                this.state.rules.map((rule, index) => {
                                    return (
                                        rule.zip.includes(this.state.filter) &&
                                        <DeliveryRuleButton key={"DR_BTN_" + rule.id} rule={rule} setActive={(active) => {
                                            this.setRuleActive(index, active)
                                        }}
                                            onEdit={() => {
                                                this.openEditDrawer(index)
                                            }}
                                            onDelete={() => {
                                                this.props.openAlertDialog(
                                                    [
                                                        {
                                                            label: "Ja",
                                                            color: MUI_COLORS.ERROR,
                                                            onChoice: () => {
                                                                // console.log("REGEL wird gelöscht!");
                                                                this.deleteRule(index);
                                                            }
                                                        },
                                                        {
                                                            label: "Nein",
                                                            onChoice: () => {
                                                                console.log("REGEL wird nicht gelöscht...");
                                                            }
                                                        }
                                                    ],
                                                    "Löschen", "Möchten Sie die Regel wirklich löschen?")
                                            }}
                                        />
                                    )
                                })
                            }

                        </div>
                    </>
                    :
                    <Typography style={{ fontStyle: 'italic', color: '#979797' }}>Keine Daten Vorhanden...</Typography>
                }
            </CollapsibleTile>
        )
    }
}
// "id":1,"shopId":1,"zip":"-11111","minOrderAmount":"20.00","isActive":1
export default DeliveryRulesTile