import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import { History, Save } from '@mui/icons-material';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../../constants/MUIConstants';
import { TextField, Typography } from '@mui/material';
import { APIData } from '../../../constants/APIAdresses';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';

export class ShopCommissionConfigTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commissionsLoading: false,
            loadReachedEnd: false,
            data: {
                defaultCommission: 8.5,
                monthLimit: 260.00,
                onlinePaymentCommission: 4.25,
                onlinePaymentFixedSum: 0.56,
                extDeliveryCommission: 32.00
            },
            hasOwnConfig: false,
            actions: []
        }

    }
    onSave() {
        // console.log("STATEDATA: ",JSON.stringify(this.state.data));
        if (this.state.data.update != null) {

            let myBodyData = { ...this.state.data };
            let updatePart = { ...myBodyData.update };
            delete myBodyData.update;
            delete myBodyData.shopId;
            myBodyData = { ...myBodyData, ...updatePart };
            fetch(APIData.USER + `/shop/${this.props.shopId}/commissionConfig`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(myBodyData)
            }).then(response => {
                if (response.ok) {
                    this.props.showAlertDisplay("Provisionseinstellungen gespeichert", ALERT_SEVERITY.SUCCESS);
                    this.loadCommissionData();
                } else {
                    throw new Error("Fehler beim Speichern der Provisionseinstellungen")
                }
            }).catch(error => {
                this.props.showAlertDisplay("Fehler beim Speichern der Provisionseinstellungen", ALERT_SEVERITY.ERROR);
                console.error(error);
            })
        } else {
            this.props.showAlertDisplay("Keine Änderungen zum Speichern", ALERT_SEVERITY.INFO);
        }
    }
    restoreDefault() {
        fetch(APIData.USER + `/shop/${this.props.shopId}/commissionConfig`, { method: 'DELETE', credentials: 'include' })
            .then(response => {
                if (response.ok) {
                    this.props.showAlertDisplay("Provisionseinstellungen zurückgesetzt", ALERT_SEVERITY.SUCCESS);
                    this.loadCommissionData();
                } else {
                    throw new Error("Fehler beim Zurücksetzen der Provisionseinstellungen")
                }
            }).catch(error => {
                this.props.showAlertDisplay("Fehler beim Zurücksetzen der Provisionseinstellungen", ALERT_SEVERITY.ERROR);
                console.error(error);
            })
    }
    loadCommissionData() {
        this.setState({ commissionsLoading: true });
        fetch(APIData.USER + `/shop/${this.props.shopId}/commissionConfig`, { credentials: 'include', headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error("Fehler beim Laden der Provisionseinstellungen")
                }
            }).then(data => {
                console.log("COMMISSIONDATA: ", data);
                // let tileActions = [...this.state.actions];
                // this means the shop has a configuration otherwise if the length is 1 it means the shop has no configuration and the default values are used
                this.setState({ data: data[0], commissionsLoading: false, hasOwnConfig: data.length > 1, });
            }).catch(error => {
                this.props.showAlertDisplay("Fehler beim Laden von Provisionseinstellungen", ALERT_SEVERITY.ERROR);
                console.error(error);
                this.setState({ commissionsLoading: false });
            })
        // API CALL
    }
    updateField(field, value) {
        console.log("FIELD: ", field, " VALUE: ", value);
        this.setState(prevState => {
            const newData = { ...prevState.data };
            if (newData[field] != value) {
                if (!newData.update) {
                    newData.update = {};
                }
                newData.update[field] = value;
            } else if (newData.update && newData.update[field] != null) {
                delete newData.update[field];
                if (Object.keys(newData.update).length === 0) {
                    delete newData.update;
                }
            }
            return { data: newData };
        });
    }
    render() {
        return (
            <CollapsibleTile onCollapseChange={(isExpanded, firstExpand) => {
                console.log("ONCOLLAPSE???")
                if (isExpanded && firstExpand) {
                    // this.loadRules();
                    this.loadCommissionData();
                }
            }}
                title="Provisionseinstellungen"
                icon={<img src={require('../../../assets/icons/commission.png')} width={20} height={20} />}
                isLoading={this.state.commissionsLoading}
                actions={
                    this.state.hasOwnConfig ? [{
                        label: "Standard Wiederherstellen",
                        icon: <History />,
                        variant: MUI_BTN_VARIANTS.OUTLINED,
                        disabled: this.state.commissionsLoading,
                        onClick: () => { this.restoreDefault() }
                    }, {
                        label: "Speichern",
                        icon: <Save />,
                        variant: MUI_BTN_VARIANTS.CONTAINED,
                        disabled: this.state.commissionsLoading,
                        onClick: () => { this.onSave() }
                    }] :
                        [{
                            label: "Speichern",
                            icon: <Save />,
                            variant: MUI_BTN_VARIANTS.CONTAINED,
                            disabled: this.state.commissionsLoading,
                            onClick: () => {
                                if (this.props.shopId == 0) {
                                    // ask before change, since this is the default for all shops!
                                    this.props.openAlertDialog(
                                        [
                                            {
                                                label: "Ja",
                                                color: MUI_COLORS.ERROR,
                                                onChoice: () => {
                                                    // console.log("REGEL wird gelöscht!");
                                                    this.onSave();
                                                }
                                            },
                                            {
                                                label: "Nein",
                                                onChoice: () => {
                                                    console.log("Provision wird nicht aktualisiert...");
                                                }
                                            }
                                        ],
                                        "Standardprovisionseinstellungen", `Bist du dir sicher, dass du die Konfiguration zur Standardprovision überschreiben möchtest? Nach dem Überschreiben werden diese Einstellungen für alle Partnershops verwendet, die keine individuelle Konfiguration haben?`)
                                } else {
                                    this.onSave()
                                }
                            }
                        }]
                }
            >
                <div style={{display:"flex", gap:25, flexDirection:"column"}}>
                    {this.props.shopId == 0 && <Typography fontSize={12}>Hierbei handelt es sich um Einstellung zur Kalkulation der Abrechnungen für alle Shops auf der Plattform, die keine individuelle Konfiguration haben.</Typography>}
                    <div style={{ display: "grid", gap: 16, gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", width: "100%" }}>
                        <TextField type='number' onChange={(evt) => { this.updateField("defaultCommission", evt.target.value) }} value={this.state.data.update?.defaultCommission != null ? this.state.data.update.defaultCommission : this.state.data.defaultCommission} label="Standardprovision %" />
                        <TextField type='number' onChange={(evt) => { this.updateField("monthLimit", evt.target.value) }} value={this.state.data.update?.monthLimit != null ? this.state.data.update.monthLimit : this.state.data.monthLimit} label="Monatlicher Limit €" />
                        <TextField type='number' onChange={(evt) => { this.updateField("onlinePaymentCommission", evt.target.value) }} value={this.state.data.update?.onlinePaymentCommission != null ? this.state.data.update.onlinePaymentCommission : this.state.data.onlinePaymentCommission} label="Online-Provision %" />
                        <TextField type='number' onChange={(evt) => { this.updateField("onlinePaymentFixedSum", evt.target.value) }} value={this.state.data.update?.onlinePaymentFixedSum != null ? this.state.data.update.onlinePaymentFixedSum : this.state.data.onlinePaymentFixedSum} label="Online Transaktionsgebühr (Fix) €" />
                        <TextField type='number' onChange={(evt) => { this.updateField("extDeliveryCommission", evt.target.value) }} value={this.state.data.update?.extDeliveryCommission != null ? this.state.data.update.extDeliveryCommission : this.state.data.extDeliveryCommission} label="Externe Liefergebühr %" />
                    </div>
                </div>
            </CollapsibleTile>
        )
    }
}

export default ShopCommissionConfigTile