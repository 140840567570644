// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media only screen and (max-width: 768px) {
    /* Your CSS styles for smaller screens go here */
    .description-ingredients-area {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/areas/partnermanagement/ShopContent/ProductEditor/ProductEditor.css"],"names":[],"mappings":";AACA;IACI,gDAAgD;IAChD;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["\n@media only screen and (max-width: 768px) {\n    /* Your CSS styles for smaller screens go here */\n    .description-ingredients-area {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
