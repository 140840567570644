import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import GenericTable from '../../../components/GenericTable/GenericTable';
import { APIData } from '../../../constants/APIAdresses';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer';
import { Close, Save } from '@mui/icons-material';
import { Card, Checkbox, Switch, Typography } from '@mui/material';
import ShopSelector from '../../../components/ShopSelector';
const reviewColumns = [
    { dataKey: 'id', label: 'ID', width: 120 },
    { dataKey: 'userId', label: 'Benutzer-ID', width: 150 },
    { dataKey: 'name', label: 'Name', },
    { dataKey: 'publishName', label: 'Anonym', format: (value) => { return value ? "Nein" : "Ja" } },
    { dataKey: 'stars', label: 'Bewertung', },
    { dataKey: 'comment', label: 'Kommentar', },
    { dataKey: 'dateOfReview', label: 'Datum', format: (value) => { return new Date(value).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" }) } },
    { dataKey: 'reported', label: 'Gemeldet', format: (value) => { return value ? "Ja" : "Nein" } }

]
export class ReviewsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewsLoading: false,
            reviewData: [],
            activeFilterEnabled: false,
            reportedFilterEnabled: false,
            activeFilter: true,
            reportedFilter: true,
            showTable: false,
        }
    }
    updateReview(fields, index, rowId) {
        let body = {
            fields: fields
        }
        console.log("Updating Review: ", fields, index, rowId);
        // /review/:id/update
        let reviewData = [...this.state.reviewData];
        let rowOld = JSON.parse(JSON.stringify(this.state.reviewData[index]));
        reviewData[index] = { ...reviewData[index], ...fields };
        this.setState({ reviewData: [...reviewData] });
        fetch(APIData.SHOP + `/review/${rowId}/update`, { credentials: 'include', method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    this.props.showAlertDisplay("Bewertung erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                } else {
                    throw new Error("Error while updateReview (" + response.status + "): ", response);
                }
            }).catch(e => {
                reviewData[index] = rowOld;
                this.setState({ reviewData: [...reviewData] });
                this.props.showAlertDisplay("Die Bewertung konnte nicht aktualisiert werden.", ALERT_SEVERITY.ERROR);
                console.error("Error while updateReview: ", e);
            })
    }
    updateReviewDrawer(configData, index, rowId) {
        // console.log("UPDATEREVIEW: ",configData," Index: ",index, " rowID:", rowId);
        let body = {
            fields: {}
        }
        configData.fields.filter((field) => { return field.status === GD_STATUS_TYPE.UPDATE }).forEach((field) => {
            body.fields[field.dbKey] = field.value;
        });
        this.props.closeDrawer();
        if (Object.keys(body.fields).length > 0) {
            this.updateReview(body.fields, index, rowId);
        } else {
            this.props.showAlertDisplay("Keine Änderungen zum Speichern", ALERT_SEVERITY.INFO);
        }
        // console.log("FILTERED FIELDS: ", filteredFields);
    }
    openEditDrawer(index, rowId) {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Speichern",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.updateReviewDrawer(configData, index, rowId) }
                }, {
                    label: "Abbrechen",
                    icon: <Close />,
                    onClick: (configData) => { this.props.closeDrawer() }
                }
            ],
            fields: [
                {
                    label: "ID",
                    disabled: true,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: rowId,
                    dbKey: "id",
                },

                {
                    label: "Kommentar",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.reviewData[index].comment,
                    dbKey: "comment",
                    isRequired: true
                },
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.reviewData[index].active,
                    dbKey: "active",
                },
                {
                    label: "Gemeldet",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.reviewData[index].reported,
                    dbKey: "reported",
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    render() {
        return (
            <CollapsibleTile onCollapseChange={(isExpanded, firstExpand) => {
                if (isExpanded && firstExpand && !this.state.showTable) {
                    this.setState({ showTable: true });
                    // this.loadRules();
                    // this.loadCommissionData();
                }
            }}
                title="Bewertungen"
                icon={<img src={require('../../../assets/icons/star.png')} width={20} height={20} />}
                isLoading={this.state.reviewsLoading}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <Card style={{ padding: 20, }}>
                        <Typography>Filter:</Typography>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ flexDirection: "row", marginLeft: -10, display: "flex", alignItems: "center" }}>
                                <Checkbox checked={this.state.activeFilterEnabled} onChange={(evt) => { this.setState({ activeFilterEnabled: evt.target.checked }) }} />
                                <Typography>Aktiv</Typography>
                                <Switch disabled={!this.state.activeFilterEnabled} checked={this.state.activeFilter} onChange={(evt) => { this.setState({ activeFilter: evt.target.checked }) }} />

                                <Checkbox checked={this.state.reportedFilterEnabled} onChange={(evt) => { this.setState({ reportedFilterEnabled: evt.target.checked }) }} />
                                <Typography>Gemeldet</Typography>
                                <Switch disabled={!this.state.reportedFilterEnabled} checked={this.state.reportedFilter} onChange={(evt) => { this.setState({ reportedFilter: evt.target.checked }) }} />
                            </div>
                            {
                                this.props.setShopId &&

                                <div style={{ flex: 1 }}>
                                    <ShopSelector onSelectShopId={id => { this.props.setShopId(id) }} />
                                </div>
                            }
                        </div>
                    </Card>
                    {this.state.showTable &&
                        <GenericTable
                            config={{ columns: [...reviewColumns] }}
                            onLoadError={(e) => { this.props.showAlertDisplay("Fehler beim Laden der Bewertungen.", ALERT_SEVERITY.ERROR) }}
                            apiEndPoint={APIData.SHOP + "/reviews/getPaged" +
                                (this.props.shopId != null ? "?shopId=" + this.props.shopId : "") +
                                (this.state.activeFilterEnabled ? (this.props.shopId != null ? "&" : "?") + "active=" + (this.state.activeFilter ? "1" : "0") : "") +
                                (this.state.reportedFilterEnabled ? (this.state.activeFilterEnabled || this.props.shopId ? "&" : "?") + "reported=" + (this.state.reportedFilter ? "1" : "0") : "")}
                            tableData={this.state.reviewData}
                            setTableData={(data) => { this.setState({ reviewData: data }) }}
                            activeKey="active"
                            onSetActive={(index, value) => {
                                this.updateReview({ active: value ? 1 : 0 }, index, this.state.reviewData[index].id);
                            }}
                            onEdit={(index, rowId) => {
                                this.openEditDrawer(index, rowId);
                            }}
                            noAdd
                        />
                    }
                </div>
            </CollapsibleTile>
        )
    }
}

export default ReviewsTile