import React, { useEffect } from 'react'
import SupportCaseBarItem from './SupportCaseBarItem';
import { connect } from 'react-redux';
// import { increaseUnreadItemsCountForCaseId, removeActiveCaseByIndex } from '../../../../redux/supportSlice';
import { io } from 'socket.io-client';
import { SOCKET_EVENT } from '../SupportChatDialog';
// import notification1 from 
// var innerCaseList = null;
// var firstTime = true;
export const ActiveCasesBar = (props) => {
    const [firstTime, setFirstTime] = React.useState(true);
    var mainNSSocket = io('https://viveel.de:3002', {
        withCredentials: true,
        transports: ['websocket'],  // Use WebSocket transport explicitly
        reconnection: true,         // Enable automatic reconnection
        reconnectionAttempts: 5,    // Number of attempts before failing
        reconnectionDelay: 1000,    // Delay before trying to reconnect
        reconnectionDelayMax: 5000, // Maximum delay for reconnection
    });
    useEffect(() => {
        console.log("###ACTIVE CASES BAR HAS BEEN MOUNTED");
    }, []);
    useEffect(() => {
        // to check here for firsttime and play sound if not first time has the reason, that useEffect toggles for notificationSoundToggle at the first time, since it gets set and was undefined before rendering
        // so nothing to blame useEffect actually for but we have to avoid the sound at the first time
        if (!firstTime) {
            new Audio(require('../../../../assets/sounds/notification3.wav')).play();
        } else {
            setFirstTime(false);
            // firstTime=false;
        }
    }, [props.notificationSoundToggle])
    function onCloseCase(id) {
        mainNSSocket.emit(SOCKET_EVENT.LEAVE_ROOM, id);
    }
    return (
        <div style={{ position: "absolute", display: "flex", overflow: "visible", flexDirection: "row", zIndex: 3, bottom: props.caseList?.length > 0 ? 0 : -50, left: 50, right: 50, height: 50, }}>
            {props.caseList?.map((item, index) => {
                return <SupportCaseBarItem onClose={() => { onCloseCase(item?.id?.toString()) }} key={"supportCaseBarItem_" + item?.id?.toString()} index={index} caseId={item?.id} unreadItems={item?.unreadItems} />
            })}
        </div>
    )
    // }
}
function mapStateToProps(state) {
    return {
        caseList: state.support.activeCaseList,
        role: state.permission.role,
        notificationSoundToggle: state.support.playNotificationSoundToggle

    }
}
function mapDispatchToProps(dispatch) {
    return {
        // increaseUnreadItemsQty: (caseId) => {
        //     // dispatch(increaseUnreadItemsCountForCaseId(caseId));
        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActiveCasesBar)
