import React, { Component } from 'react'
import Header from './components/Header'
import "./assets/css/general.css"
import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { APIData } from './constants/APIAdresses';
import { ROLES, setLoggedIn, setRole } from './redux/permissionSlice';
import { connect } from 'react-redux';
import { withNavigate } from './hookInjection/withNavigate';
import DefaultFrame from './components/DefaultFrame';
import TwoFactorInputField from './components/TwoFactorInputField';
import SecretTextField from './components/SecretTextField';
// import QRCode from 'qrcode.react';
import QRCode from 'react-qr-code';
// export var userLoggedIn = false;


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordVisible: false,
            username: "",
            password: "",
            loginError: false,
            totp: "",
            totpActive: false,
            totpWrong: false,
            totpBase: "",
        }
        this.checkInProgress = false;
        this.checkDone = false;
        this.checkSession();
    }
    togglePasswordVisibility() {
        this.setState({ passwordVisible: !this.state.passwordVisible });
    }
    login() {
        if (this.state.username?.length > 0 && this.state.password?.length > 0) {

            var bodyParamsObj = {
                "login_type": "internal",
                "username": this.state.username,
                "password": this.state.password,
            }
            let uri = APIData.USER + "/auth/session/checkCreate";
            fetch(uri, { body: JSON.stringify(bodyParamsObj), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    // if(response.ok){}
                    this.checkDone = true;
                    if (response.status === 202) {
                        // this.props.setLoggedIn();
                        return response.json();
                    } else {
                        this.setState({ loginError: true });
                    }
                    // return response.json();
                }).then(result => {
                    console.log("LOGINRESULT: ", result);
                    this.setState({ totpBase: result.totpbase != null ? result.totpbase : "", totpActive: true });
                }).catch(e => {
                    this.checkDone = true;
                    console.log("Error while checkSession...: ", e);
                })
        } else {
            alert("Benutzername oder Passwort darf nicht leer sein.");
        }
    }
    checkTOTP(totp) {
        if (this.state.totp.length === 6 || totp?.length === 6) {
            if (!isNaN(parseInt(this.state.totp))) {
                let uri = APIData.USER + "/auth/session/totpCheck";
                fetch(uri, { body: JSON.stringify({ totp: totp?.length === 6 ? totp : this.state.totp }), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            this.props.setLoggedIn();
                            this.setState({ totpWrong: true });
                            return null;
                        }
                    }).then(result => {
                        if (result?.role != null) {
                            this.props.setUserRole(result.role);
                            this.props.navigate("/dashboard");
                        }
                    }).catch(() => {
                        alert("Es ist etwas schiefgelaufen.");
                    })
            }
        } else {
            alert("2FA-Code muss 6-Stellig sein.");
        }
    }
    checkSession() {
        if (!this.checkInProgress) {
            this.checkInProgress = true;
            var bodyParamsObj = {
                // "login_type": LOGINTYPE.INTERNAL,
                // "username": this.userLogin,
                // "password": this.userPassword,
            }
            let uri = APIData.USER + "/auth/session/checkCreate";
            fetch(uri, { body: JSON.stringify(bodyParamsObj), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    this.checkDone = true;
                    if (response.status === 200) {
                        this.props.setLoggedIn();
                        return response.json();
                    }
                    // return response.json();
                })
                .then(result => {
                    if (result != null) {
                        console.log("setting userrole: ", result);
                        console.log("setting userrole: ", result.role);
                        if (result.role >= ROLES.SUPERVISOR) {
                            this.props.setUserRole(result.role);
                            this.props.navigate("/dashboard");
                        }
                    }
                })
                .catch(e => {
                    this.checkDone = true;
                    console.log("Error while checkSession...: ", e);
                })
        }
    }
    render() {
        return (
            <DefaultFrame title="Login" noPermissionCheck noBack noLogout noMenu>
                <div style={{ height: "100%", width: "100%" }}>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "70%" }}>
                        {!this.state.totpActive ?
                            <>
                                {this.state.loginError &&
                                    <Typography style={{ marginBottom: 15, color: "red", fontStyle: "italic" }}>Benutzerdaten falsch...</Typography>
                                }
                                <TextField value={this.state.username} onChange={(evt) => { this.setState({ username: evt.target.value, loginError: false }) }} onKeyDown={(evt) => { if (evt.key === "Enter") { evt.target.blur(); this.passwordFieldRef?.focus(); evt.preventDefault() } }} style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff" }} variant='outlined' label='Benutzername'></TextField>
                                <div style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff", marginTop: 15 }}>
                                    <SecretTextField inputRef={(ref) => { this.passwordFieldRef = ref }} value={this.state.password} onChange={(value) => { this.setState({ password: value, loginError: false }) }} onEnterPressed={() => { this.login() }} label='Passwort' />
                                </div>

                                <Button onClick={() => { this.login(); }} style={{ minWidth: "300px", width: "10%", marginTop: 15 }} variant='contained'>Einloggen</Button>
                            </>
                            :
                            <div style={{ display: "flex", flexDirection: "column", gap: 15, alignItems: "center" }}>
                                {this.state.totpBase.length > 0 &&
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap:15 }}>
                                        <div style={{width:160}}>
                                            <QRCode size={160} value={"otpauth://totp/Viveel%20GmbH?secret=" + this.state.totpBase + "&algorithm=SHA512&issuer=Viveel%20Support"} />
                                        </div>
                                        <Typography style={{ width: 600, fontSize:12 }}>Dies ist dein erster Login. Scanne den QR-Code in deiner Authenticator-App und speichere dein Zwei-Faktor-Authentifizierungsverfahren, um es später wieder verwenden zu können! Dieser Code wird nur beim erstmaligen Login angezeigt.</Typography>
                                    </div>
                                }
                                {this.state.totpWrong &&
                                    <Typography style={{ color: "red", fontStyle: "italic" }}>Code falsch...</Typography>
                                }
                                <TwoFactorInputField setTwoFactorValue={(val) => { this.setState({ totp: val, totpWrong: false }); }} checkTOTP={(totp) => { this.checkTOTP(totp) }} />
                                <Button onClick={() => { this.checkTOTP() }} style={{ minWidth: "300px", width: "10%", marginTop: 15 }} variant='contained'>Senden</Button>
                            </div>
                        }
                    </div>
                </div>

            </DefaultFrame>
        )
    }

}
function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: () => {
            dispatch(setLoggedIn(true));
        },
        setUserRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(null, mapDispatchToProps)(withNavigate(Login));

