import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setAllergens, setSelectedAllergens } from '../../../../redux/productSlice'
import { PE_CHANGE_TYPES } from './ProductEditor'
import CollapsibleTile from '../../../../components/CollapsibleTile'

export class AllergensSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
        }
    }
    onChangeCheckbox(checkboxIndex) {
        let selectedOptIndex = this.props.selectedOptions.findIndex((element) => { 
            return element.uniqueKey === this.props.options[checkboxIndex].uniqueKey 
        })
        if(selectedOptIndex == -1){
            this.props.setSelectedAllergens([...this.props.selectedOptions, {...this.props.options[checkboxIndex], status: PE_CHANGE_TYPES.NEW}]);
        }else{
            if(this.props.selectedOptions[selectedOptIndex].status === PE_CHANGE_TYPES.DELETE){
                this.props.setSelectedAllergens(this.props.selectedOptions.map((element) => {
                    if(element.uniqueKey === this.props.options[checkboxIndex].uniqueKey){
                        return {...element, status: null};
                    }
                    return element;
                }))
            }else if(this.props.selectedOptions[selectedOptIndex].status === PE_CHANGE_TYPES.NEW){
                this.props.setSelectedAllergens(this.props.selectedOptions.filter((element) => element.uniqueKey !== this.props.options[checkboxIndex].uniqueKey));
            }else{
                this.props.setSelectedAllergens(this.props.selectedOptions.map((element) => {
                    if(element.uniqueKey === this.props.options[checkboxIndex].uniqueKey){
                        return {...element, status: PE_CHANGE_TYPES.DELETE};
                    }
                    return element;
                }))
            }
        }
    }
    render() {
        return (
            <CollapsibleTile
             title = {`Allergene (${this.props.selectedOptions?.length > 0 ? this.props.selectedOptions.filter(so => so.status !== PE_CHANGE_TYPES.DELETE).length : 0})`}
             icon = {<img src={require('../../../../assets/icons/peanut.png')} width={20} height={20} />}
             onCollapseChange = {(collapsed, firstExpand) => { }}
            >
                <div key={"multiCb_" + this.props.selectedOptions?.length} style={{ display: "flex", flexWrap: 'wrap', gap: 10 }}>
                            {this.props.options?.map((optionData, optionIndex) => {
                                return (
                                    <div onClick={() => { this.onChangeCheckbox(optionIndex) }} key={"MULTICHECKBOX_" + optionIndex} style={{ border: '0.5px solid #DEDEDE', cursor: 'pointer', borderRadius: 5, paddingRight: 15, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Checkbox checked={this.props.selectedOptions?.find(o => o?.uniqueKey === optionData?.uniqueKey && o.status !== PE_CHANGE_TYPES.DELETE /*&& o.status !== GD_STATUS_TYPE.DELETE*/)} />
                                        <Typography style={{ userSelect: 'none', cursor: 'pointer' }}>{optionData.defaultLabel}</Typography>
                                    </div>)
                            })}
                        </div>
            </CollapsibleTile>
            // <div style={{ border: "0.5px solid #cecece", borderRadius: 5, }}>

                
            // </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        options: state.product.allergens,
        selectedOptions: state.product.selectedAllergens,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setAllergens: (allergens) => dispatch(setAllergens(allergens)),
        setSelectedAllergens: (allergens) => dispatch(setSelectedAllergens(allergens)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllergensSelector)