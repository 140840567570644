import { Button, Paper, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import CollapsibleTile from '../../../../components/CollapsibleTile'
import TileComponent from '../../../../components/TileComponent'
import { Delete, Edit } from '@mui/icons-material'
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../../../constants/MUIConstants'
import PVAllergensSelector from './PVAllergensSelector'
import { connect } from 'react-redux'
import { PE_CHANGE_TYPES } from './ProductEditor'
import PVCollectionSelector from './PVCollectionSelector'
import PVGCSelector from './PVGCSelector'
import { setProductVariants } from '../../../../redux/productSlice'

export class ProductVariant extends Component {
    constructor(props){
        super(props);
    }
    updateField(key, value){
        let myPV = [...this.props.productVariants];
        console.log("CHECKING UPDATEFIELD: ", myPV[this.props.index]);
        if(myPV[this.props.index].status === PE_CHANGE_TYPES.NEW){
            myPV[this.props.index][key] = value;
        } else {

            if(myPV[this.props.index][key] === value ){
              delete myPV[this.props.index].update[key];  
            } else{
                myPV[this.props.index].update = {...myPV[this.props.index].update ,[key]: value};
            }
            if(Object.keys(myPV[this.props.index].update).length === 0){
                delete myPV[this.props.index].update;
                delete myPV[this.props.index].status;
            }else{
                myPV[this.props.index].status = PE_CHANGE_TYPES.UPDATE;
            }
        }
        this.props.setProductVariants(myPV);
    }
    render() {
        return (
            <Paper style={{ display: "flex", flexDirection: "column", borderRadius: 5, backgroundColor:'#FEFEFE', border: "0.5px solid #cecece", gap: 10, padding: 15 }}>
                <div style={{ display: "flex", gap: 10 }}>
                    {this.props.data.id > 0 &&
                        <TextField style={{ flex: 1 }} label="Info" disabled={true} value={this.props.data.id} />
                    }
                    <TextField style={{ flex: 1 }} label="Name" onChange={(evt) => {this.updateField("name", evt.target.value)}}  value={this.props.data?.update?.name != null ? this.props.data.update.name : this.props.data.name != null ? this.props.data.name : ""} />
                    <TextField style={{ flex: 1 }} label="Info" onChange={(evt) => {this.updateField("info", evt.target.value)}} value={this.props.data?.update?.info != null ? this.props.data.update.info : this.props.data.info != null ? this.props.data.info : ""} />
                    <TextField style={{ flex: 1 }} label="Preis €" type='number' onChange={(evt) => {this.updateField("price", evt.target.value)}} value={this.props.data?.update?.price != null ? this.props.data.update.price : this.props.data.price != null ? this.props.data.price : ""} />
                </div>
                    <PVCollectionSelector shopId={this.props.shopId} pvIndex={this.props.index} selectedCollections={this.props.data.collData} />
                    <PVGCSelector shopId={this.props.shopId} pvIndex={this.props.index} selectedGroupCollections={this.props.data.pgData} />
                    <PVAllergensSelector 
                    pvIndex={this.props.index}
                    selectedOptions={
                        this.props.data.allergens?.length > 0 ?
                         this.props.allergens.filter((allergen) => this.props.data.allergens.some(pvAllergen => 
                            pvAllergen.aid.toString() === 
                            allergen.id.toString() && 
                            pvAllergen.status !== PE_CHANGE_TYPES.DELETE ) )
                         : []
                    }/>
                    <Button onClick={()=> {this.props.onDelete()}} variant='contained' color='warning' startIcon={<Delete />}>Löschen</Button>
            </Paper>
        )
    }
}
function mapStateToProps(state) {
    return {
        allergens: JSON.parse(JSON.stringify(state.product.allergens)),
        productVariants: JSON.parse(JSON.stringify(state.product.productVariants))
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setProductVariants: (variants) => {
            dispatch(setProductVariants(variants));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductVariant);