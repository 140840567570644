import { Alert, Button, CircularProgress, Dialog, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { SENDERTYPE, addMessageListToCaseList, addMessageToCaseList, disableLoadOffset, increaseLoadOffset, setChatDialogOpen, setCurrentChatCaseIndex, setRoomJoinedForCaseIndex, setSupportCaseTableData, setSupportChatTabAreaLoading } from '../../../redux/supportSlice';
import SendIcon from '@mui/icons-material/Send';
import SupportMessageDisplay from './SupportMessageDisplay';
import { io } from 'socket.io-client';
import { getSupportMessages } from '../../../APIHelper';
import "./chatDialog.css";
import InfoTabContainer from '../ChatDialogInfoArea/InfoTabContainer';
import { APIData } from '../../../constants/APIAdresses';

function PaperComponent(props) {
    return (
        <Paper
            style={{
                position: "absolute",
                bottom: 0,
                // left: "20vw",
                // right:"20vw",
                left: 0,
                right: 0,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                maxWidth: "100vw",
                height: "70vh",
                margin: 0,
            }}
            {...props} />
    );
}
export const SOCKET_EVENT = {
    JOIN_ROOM: "jr",
    LEAVE_ROOM: "lr",
    MESSAGE: "msg",
    REOPEN: "r",
    CREATED: "c",
    ERROR: "e",
    NEWSUPPORTCASE: "nsc",
    NEWSUPPORTMSG: "nsm",
}

export class SupportChatDialog extends Component {
    // chatContainerRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            msg: "",
            isLoading: false,
        }
        // this.loadOffset=0;
        // this.msgListLength = 0;
        this.testRf = React.createRef();
        this.shouldAutoScroll = true;
        // this.test = new Date();

        this.mainNS = io(APIData.REALTIME, {
            withCredentials: true,
            transports: ['websocket'],  // Use WebSocket transport explicitly
            reconnection: true,         // Enable automatic reconnection
            reconnectionAttempts: 5,    // Number of attempts before failing
            reconnectionDelay: 1000,    // Delay before trying to reconnect
            reconnectionDelayMax: 5000, // Maximum delay for reconnection
        });
        this.supportNS = io(APIData.REALTIME+'/support', {
            withCredentials: true,
            transports: ['websocket'],  // Use WebSocket transport explicitly
            reconnection: true,         // Enable automatic reconnection
            reconnectionAttempts: 5,    // Number of attempts before failing
            reconnectionDelay: 1000,    // Delay before trying to reconnect
            reconnectionDelayMax: 5000, // Maximum delay for reconnection
        });
        // this.supportNS.on("nsm", (scid) => {
        //     this.props.increaseUnreadItemsQty(scid);
        // })
        this.joined = false;
        this.newMsg = false;
        this.loadInProgress = false;
        console.log("NEW CONSTRUCTOR CALLING????")
        // this.currentLoadOffset = 0;

    }
    componentDidMount() {
        // alert("Chat geöffnet!");
        this.mainNS.on(SOCKET_EVENT.MESSAGE, (msg) => {
            if (msg.senderType === SENDERTYPE.USER) {
                this.newMsg = true;
                this.props.addMessageToCaseList(msg);
            }
        });
        this.supportNS.on(SOCKET_EVENT.NEWSUPPORTCASE, (supportCase) => {
            if(this.props.liveDataUpdate){
                let mySupportData = [...this.props.supportCaseTableData];
                mySupportData.unshift(supportCase);
                this.props.setSupportCaseTableData([...mySupportData]);
                new Audio(require('../../../assets/sounds/notification1.wav')).play();
            }
            // Alert.info("Neuer Supportfall!");
            // alert("Neuer Supportfall: "+scid);

        });
    }
    componentDidUpdate() {
        // console.log("CHAT MESSAGE DIALOG HAD MOUNTED: ",this.props.chatData);
        console.log("chatdialogupdate: ", this.props.chatOpened);
        console.log("###########JOINED?: ", this.joined);
        if (this.props.chatOpened) {

            if (this.newMsg) {
                this.newMsg = false;
                setTimeout(() => {
                    this.testRf.current.scrollTop = this.testRf.current.scrollHeight;
                }, 150);
            }
            if (!this.props.chatData.joined) {
                this.props.setRoomJoinedForCaseIndex(this.props.currentCaseIndex);
                // this.props.resetRefreshSignal();
                // this.joined = true;
                this.mainNS.emit(SOCKET_EVENT.JOIN_ROOM, this.props?.chatData?.id);
                // console.log("PROPS DATA::: ", this.props.chatData);
                // console.log("GETSUPPORTMESSAGE: ", this.props.chatData.id, " offset: ", this.props.chatData.loadOffset);
                // #region Loading Initial Supportmessages
                if (this.props.chatData.id != null && this.props.chatData.loadOffset != null && this.props.chatData.loadOffset !== -1 && !this.loadInProgress) {
                    this.loadInProgress = true;
                    getSupportMessages(this.props?.chatData?.id, this.props?.chatData?.loadOffset).then(result => {
                        this.loadInProgress = false;
                        this.setState({ isLoading: false });
                        if (result.length > 0) {
                            this.newMsg = true;
                            this.props.addMessageListToCaseList(this.props?.chatData?.id, result);
                            this.props.increaseLoadOffsetByCaseId(this.props.chatData.id);
                        } else { // this would be probably not the case, since the support case has one message at minimum.
                            this.props.disableLoadOffset(this.props.chatData.id);
                        }
                        // console.log("CHATMESSAGES: ", result);
                    }).catch(e => {
                        this.setState({ isLoading: false });
                        this.loadInProgress = false;
                        console.log("ERROR IN SUPPORTCHATDIALOG WHILE LOADING CHAT MESSAGES...");
                    })
                }
                // #endregion Loading Initial Supportmessages
            }
        } else {
            this.joined = false;
            // this.mainNS.emit(SOCKET_EVENT.LEAVE_ROOM, this.props?.chatData?.id);
        }
        if (this.shouldAutoScroll && this.props.chatOpened) {
            this.shouldAutoScroll = false;
            setTimeout(() => {
                this.testRf.current.scrollTop = this.testRf.current.scrollHeight;
            }, 250);
        }
    }
    loadMessages() {
        if (!this.loadInProgress && this.props?.chatData?.loadOffset != null && this.props?.chatData?.loadOffset != -1) {
            this.setState({ isLoading: true });
            this.loadInProgress = true;
            getSupportMessages(this.props?.chatData?.id, this.props?.chatData?.loadOffset).then(result => {
                this.loadInProgress = false;
                if (result.length > 0) {
                    this.setState({ isLoading: false });
                    console.log("LOADING MESSAGES: ", result);
                    this.props.addMessageListToCaseList(this.props?.chatData?.id, result);
                    this.props.increaseLoadOffsetByCaseId(this.props.chatData.id);
                    this.testRf.current.scrollTop = 1; // this is just to be able to scroll more.
                } else {
                    this.props.disableLoadOffset(this.props.chatData.id);
                }
            }).catch(e => {
                this.loadInProgress = false;
                this.setState({ isLoading: false });
                this.props.disableLoadOffset(this.props.chatData.id);
                console.log("ERROR IN SUPPORTCHATDIALOG WHILE LOADING CHAT MESSAGES...");
            })
        }
    }
    loadUserData() {
        // /user/:id/getSupportData
    }
    sendMsg() {
        this.setState({ msg: "" });
        if (this.state.msg.trim().length > 0) {
            this.supportNS.emit(SOCKET_EVENT.MESSAGE, { scid: this.props?.chatData?.id, content: this.state.msg });
            this.newMsg = true;
            this.props.addMessageToCaseList({ scid: this.props?.chatData?.id, content: this.state.msg, createdAt: (new Date() + ""), senderType: '1' });
        }
    }
    // loadSignal(){
    //     console.log("CHATDATAAAA: ",this.props.chatData);
    // }
    render() {
        return (
            <Dialog PaperComponent={PaperComponent} onClose={() => { this.shouldAutoScroll = true; this.props.closeChat() }} open={this.props.chatOpened}>
                <div style={{ display: "flex", width: "100%", height: "100%", backgroundColor: "#FEFEFE" }}>
                    <div style={{ width: "60%", paddingLeft: "2.5%", paddingRight: "2.5%", height: "100%", flexDirection: "column", display: "flex", }}>
                        <Typography style={{ textAlign: "center", marginTop: 10, marginBottom: 5, color: "#676767" }} fontSize="small">Fallnummer {this.props?.chatData?.id}</Typography>
                        <div className='chatContentArea' ref={this.testRf} onScroll={(evt) => { if (evt.target.scrollTop === 0) { this.loadMessages() } }} style={{ flex: 1, padding: 10, overflowY: "scroll", msOverflowStyle: "none", scrollbarWidth: "none", marginBottom: 10, marginTop: 5, scrollBehavior: "smooth" }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {this.state.isLoading &&
                                    <CircularProgress size={25} />
                                }
                            </div>
                            {this.props.chatData?.msgList?.map((msg, index) => {
                                return <SupportMessageDisplay key={"SUPPORTMSG_" + index} data={msg} />
                            })}
                        </div>
                        <div style={{ height: 60, marginBottom: "2.5%", display: "flex", alignItems: "center", }}>
                            {this.props.chatData?.status === "1" ?
                                <TextField placeholder='Nachricht eingeben...' value={this.state.msg} onChange={(evt) => { this.setState({ msg: evt.target.value }) }} onKeyDown={(e) => { if (e.key === "Enter") { this.sendMsg() } }} style={{ width: "100%", backgroundColor: "#FEFEFE" }} variant='outlined' InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Button>
                                                <SendIcon></SendIcon>
                                            </Button>
                                        </InputAdornment>
                                    )
                                }} />
                                :
                                <Typography style={{ textAlign: "center", width: "100%", color: "#d14d4d", }}> Dieser Fall ist geschlossen und muss erneut geöffnet werden um die Konversation fortzuführen.</Typography>
                            }
                        </div>

                    </div>
                    <div style={{ width: "40%", overflowX: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F8F8F8", width: "100%", height: "100%", position: "relative" }}>
                            <InfoTabContainer />
                            {this.props.tabAreaLoading &&
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, zIndex: 2, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.2)" }}>
                                    <CircularProgress />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}
function mapStateToProps(state) {
    return {
        chatOpened: state.support.chatDialogOpen,
        chatData: state.support.activeCaseList[state.support.chatCaseIndex],
        currentCaseIndex: state.support.chatCaseIndex,
        tabAreaLoading: state.support.supportChatTabAreaLoading,
        liveDataUpdate: state.support.liveDataUpdate,
        supportCaseTableData: state.support.supportCaseTableData
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSupportCaseTableData: (data) => {
            dispatch(setSupportCaseTableData(data));
        },
        setSupportChatTabAreaLoading: (isActive) => {
            dispatch(setSupportChatTabAreaLoading(isActive));
        },
        setRoomJoinedForCaseIndex: (index) => {
            dispatch(setRoomJoinedForCaseIndex(index));
        },
        disableLoadOffset: (caseId) => {
            dispatch(disableLoadOffset(caseId));
        },
        increaseLoadOffsetByCaseId: (caseId) => {
            dispatch(increaseLoadOffset(caseId))
        },
        closeChat: () => {
            dispatch(setCurrentChatCaseIndex(-1));
            dispatch(setChatDialogOpen(false));
        },
        addMessageListToCaseList: (scid, msgList) => {
            dispatch(addMessageListToCaseList({ scid: scid, msgList: msgList }));
        },
        addMessageToCaseList: (msgObj) => {
            dispatch(addMessageToCaseList(msgObj))
            // scid: 2, content: 'Hmmm hey wie gehts?', createdAt: '2023-10-16T14:21:38.058Z', senderType: '2'
        },
        // increaseUnreadItemsQty: (caseId) => {
        //     dispatch(increaseUnreadItemsCountForCaseId(caseId));
        // }
        // updateMessages: () => {

        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportChatDialog)
