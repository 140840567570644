import { Delete, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Card, CircularProgress, Collapse, Divider, IconButton, responsiveFontSizes, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { APIData } from '../constants/APIAdresses';
import { GD_STATUS_TYPE } from '../components/GenericDrawer';
import './GridLayout.css';
const dayOptions = [
    { label: 'Montag', value: 2 },
    { label: 'Dienstag', value: 3 },
    { label: 'Mittwoch', value: 4 },
    { label: 'Donnerstag', value: 5 },
    { label: 'Freitag', value: 6 },
    { label: 'Samstag', value: 7 },
    { label: 'Sonntag', value: 1 }
]
/**
 * WARNING: This component has to use the state.selectedShops to store the selected shops for rerendering.
 * The nested structure of the generic drawer would otherwise prevent the rerendering of the component and shops not been shown.
 * This is why we always call both props.setSelectedShops and this.setState({selectedShops: ...}) to ensure the rerendering of the component and also notifying the parent component about the changes, that found place.
 * GD_STATUS_TYPE was being used here to mark the status of the shop relation.
 */
export class UserShopSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            value: '',
            selectedShops: [],
            options: [],
            isLoading: false,
        }
    }
    /**
     * If this would be an general searchable selector these would be required:
     * PROPS: API endpoint to load selected data from or data source over props and set value prop
     * identifier field to use as key e.g. 'id'
     * label field to display as label e.g. 'name' or a function to format the label
     * Endpoint to load data from
     * query tag to use as search query e.g. 'q' or 'search'
     * or a data source 
     * 
     */
    componentDidMount() {
        this.loadUserCompanyRelation();
    }
    loadUserCompanyRelation() {
        this.setState({ isLoading: true });
        console.log("LOADING: ",APIData.USER + "/user/" + this.props.userId + "/getUserCompanyRelation");
        fetch(APIData.USER + "/user/" + this.props.userId + "/getUserCompanyRelation", { credentials: 'include' })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("ErrStatus (" + response.status + ") while loading user company relation");
                }
            }).then(data => {
                console.log("DATA: ",data)
                if (data?.length > 0) {
                    // /shop/getByIdList
                    let shopIds = data.map((item) => item.shopId);
                    if (shopIds.length > 0) {
                        let queryStr = shopIds.map(id => `id=${id}`).join("&");
                        fetch(APIData.SHOP + "/shop/getByIdList?" + queryStr, { credentials: "include", headers: { "Content-Type": "application/json" } })
                            .then(response => {
                                if (response.ok) {
                                    return response.json();
                                } else {
                                    throw new Error("ErrStatus (" + response.status + ") while loading shop by id list");
                                }
                            }).then(shopData => {
                                this.setState({ isLoading: false });
                                console.log("ShopData: ", shopData);
                                this.setState({ selectedShops: shopData });
                                this.props.setSelectedShops(shopData)
                            })
                            .catch(e => {
                                this.setState({ isLoading: false});
                                console.log("Error while /shop/getByIdList: ", e);
                            })
                    }
                }else{
                    this.setState({ isLoading: false });
                }
            })
            .catch(err => {
                this.setState({ isLoading: false});
                console.log("Error while /user/:id/getUserCompanyRelation: ", err);
            })
    }
    loadShopByName(name) {
        if (name.length > 0) {
            let exidQueryStr = "";
            if (this.state.selectedShops.length > 0) {
                exidQueryStr = this.state.selectedShops.filter(shop => shop.status !== GD_STATUS_TYPE.DELETE).map((item) => `exid=${item.id}`).join("&");
            }
            // this.setState({ isLoading: true });
            fetch(APIData.SHOP + "/shops/getByName?name=" + name + (exidQueryStr.length > 0 ? "&" + exidQueryStr : ""), { credentials: "include", headers: { "Content-Type": "application/json" } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("ErrStatus (" + response.status + ") while loading shop by name");
                    }
                }).then(shopData => {
                    console.log("ShopData: ", shopData);
                    if (shopData?.length > 0) {
                        shopData = shopData.map((item) => { return { ...item, label: item.name + ": " + item.street + " " + item.houseNumber + ", " + item.zip + " " + item.city } })
                        this.setState({ options: shopData });
                    }
                    // this.setState({ isLoading: false });
                    // this.setState({ selectedShops: shopData });
                })
                .catch(e => {
                    // this.setState({ isLoading: false});
                    console.log("Error while /shop/getByName: ", e);
                })
        } else {
            this.setState({ options: [] });
        }
    }
    deleteShopRelation(index) {
        if (this.props.autoSave) {
            // call the delete request immediately
        } else {
            let selectedShopData = [...this.state.selectedShops]
            if (selectedShopData[index].status === GD_STATUS_TYPE.NEW) {
                selectedShopData.splice(index, 1);
            } else {
                selectedShopData[index].status = GD_STATUS_TYPE.DELETE;
            }
            this.setState({ selectedShops: selectedShopData });
            this.props.setSelectedShops(selectedShopData);
        }
    }
    addShopRelation(shop) {
        if (this.props.autoSave) {
            // call the add request immediately
        } else {
            let selectedShopData = [...this.state.selectedShops]
            // if the status was delete, then just remove the status
            let index = selectedShopData.findIndex((item) => item.id === shop.id);
            if (index !== -1) {
                delete selectedShopData[index].status;
            } else {
                selectedShopData.push({ ...shop, status: GD_STATUS_TYPE.NEW });
            }
            this.setState({ selectedShops: selectedShopData });
            this.props.setSelectedShops(selectedShopData);
        }
    }
    componentDidUpdate(prevProps) {
        console.log("PROPS? : ", this.props);
    }
    render() {
        return (
            <Card key={"SHOPSELECTOR_" + this.props.keyHash} style={{ position: "relative", width: "100%", height: "auto" }} >
                {this.state.isLoading &&
                    <div style={{ position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.25)", width: "100%", height: "100%", top: 0, left: 0, borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1 }}>
                        <CircularProgress />
                    </div>
                }
                <div style={{ padding: 15, display: "flex", flexDirection: "column", gap: 10 }}>

                    <Typography>Shops Hinzufügen</Typography>
                    <Autocomplete
                        noOptionsText="Keine Ergebnisse"
                        style={{ minWidth: 0 }}
                        options={this.state.options}
                        value={null} // Ensures the input clears after selection
                        onChange={(evt, value) => {
                            console.log("ADD VALUE: ", value);
                            // Blur the input and clear it after a selection is made
                            value = { ...value, status: GD_STATUS_TYPE.NEW };
                            this.addShopRelation(value);
                            if (this.autocompleteInputRef) {
                                this.autocompleteInputRef.blur(); // Blur the specific input
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                style={{ minWidth: 0 }}
                                {...params}
                                inputRef={(input) => { this.autocompleteInputRef = input; }} // Store reference to the specific input field
                                onBlur={() => {
                                    // Clear the options after the input loses focus
                                    this.setState({ options: [] });
                                }}
                                onChange={(evt) => {
                                    // Load the options based on user input
                                    this.loadShopByName(evt.target.value);
                                }}
                                placeholder="Search..."
                            />
                        )}
                    />
                    <div style={{display:"grid", gap:16, gridTemplateColumns:"repeat(auto-fit, minmax(250px, 1fr))", width:"100%"}}>
                        {/* <div style={{ display: "flex", flexWrap: "wrap",  gap: 15 }}> */}
                        {this.state.selectedShops.map((shop, index) => {
                            return (
                                shop.status !== GD_STATUS_TYPE.DELETE &&
                                <Card key={"ShopSelector_" + index + "_" + shop.id} style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: 4, display: "flex", margin: 20, flexDirection: "column" }}>

                                        <Typography fontSize={14}>{shop.name}</Typography>
                                        <Typography fontSize={10}><b>Adresse: </b>{shop.street} {shop.houseNumber}, {shop.zip} {shop.city}</Typography>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderLeft: '0.5px solid #cecece' }}>
                                        <IconButton onClick={() => { this.deleteShopRelation(index) }}>
                                            <Delete color='error' />
                                        </IconButton>
                                    </div>
                                </Card>
                            )
                        })}
                        {/* </div> */}
                    </div>
                </div>
            </Card>
            // <Accordion style={{ padding: 15 }} expanded={this.state.collapsed} onChange={() => { this.setState({ collapsed: !this.state.collapsed }) }}>
            //     <AccordionSummary
            //         expandIcon={<ExpandMore />}
            //         style={{height:20}}
            //         aria-controls="panel1a-content"
            //         id="panel1a-header"
            //     >
            //         {/* <Typography>{this.props.title}</Typography> */}


            //     </AccordionSummary>
            //     <AccordionDetails>
            //         <Divider />
            //         <Typography>
            //             Hallo Welt...
            //         </Typography>
            //     </AccordionDetails>
            // </Accordion>
        )
    }
}

export default UserShopSelector