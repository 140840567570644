export const ORDER_STATUS= {
    PENDING: "1",
    BEINGPREPARED: "2",
    ONTHEWAY: "3",
    DELIVERY_ISSUE: "4",
    DELIVERED: "5",
    CANCELED: "6",
}

export const ORDER_STATUS_LABEL = {
    PENDING: "Ausstehend",
    BEINGPREPARED: "Wird Zubereitet",
    ONTHEWAY: "Unterwegs",
    READYTOPICKUP: "Abholbereit",
    DELIVERY_ISSUE: "Lieferschwierigkeit",
    DELIVERED: "Geliefert",
    CANCELED: "Storniert",
    ERROR: "Fehler",
}


export const DELIVERY_OPTION = { DELIVERY: "0", PICKUP: "1" }

// PENDING: timer.png
// BEINGPREPARED: cooking.png
// ONTHEWAY: deliveryOption DELIVERY => deliveryscooter.png ELSE: take-away.png
// DELIVERY_ISSUE: warning.png
// DELIVERED: checked_filled.png
// CANCELED: error_filled.png