import React, {  useEffect, useState } from 'react'
import { checkSession } from '../APIHelper'
import { CircularProgress, Typography } from '@mui/material';
import { ROLES } from '../redux/permissionSlice';
import { connect } from 'react-redux';
import { setRole } from '../redux/permissionSlice';

const loadingElement = (
    <div style={{width:"100vw", flexDirection:"column", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <img width={64} height={64} src={require("../assets/icons/viveel_logo128x128.png")}/>
        <CircularProgress size={32} style={{marginTop:5}}/>
        <Typography style={{color:"#979797", marginTop:5}} fontSize="small">Loading...</Typography>
    </div>
)

function PermissionAreaComponent(props) {
    const Wrapper = (props) => {
        const [isLoading, setIsLoading] = useState(true);
        // const [hasRight, setHasRight] = useState(false);
        useEffect(() => {
            if(props.role >= ROLES.SUPERVISOR){
                console.log("Role was already set, redirecting...");
                setIsLoading(false);
            }else{
                console.log("Role is not set checking session...");
                checkSession().then(result => {
                        console.log("Session exists auto-login...");
                        props.setRole(result.role);
                        if(result.role >= ROLES.SUPERVISOR){
                            setIsLoading(false);
                        }else {
                            console.log("User does not have permission to access this area, redirecting to login");
                            window.location.href = "/"
                        }
                    }).catch(e => {
                        console.log("User not logged in or session expired, redirecting to login");
                        window.location.href = "/"
                    })
            }
            
        }, []) // an empty watch parameter for useEffect will only execute one time. This will avoid multiple calls due to render().
        return isLoading ? 
            loadingElement : 
            props.children
    }
    return <Wrapper {...props}/>;
}
const mapStateToProps = (state) => {
    console.log("######## PAC STATE: ",state.permission);
    return {
        role: state.permission.role
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRole: (role) => dispatch(setRole(role))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent);
