import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import { Typography } from '@mui/material'

export class ConflictsView extends Component {
    render() {
        return (
            <DefaultFrame title="Konflikte">
                <Typography>Test???</Typography>
            </DefaultFrame>
        )
    }
}

export default ConflictsView