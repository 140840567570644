import { Close, Delete, Edit } from '@mui/icons-material'
import { IconButton, Switch, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'

export class DeliveryRuleButton extends Component {
    constructor(props) {
        super(props);
    }
    updateAmount() {

    }
    render() {
        return (
            <div style={{ textDecoration: "none", flexGrow: 1, flexDirection: "row", maxWidth: 300, padding: 20, borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", flexBasis: 300, backgroundColor: "#ebf1f5" }}>
                <div style={{ flex: 1 }}>
                    <Switch size='small' onChange={(evt) => { this.props.setActive(evt.target.checked) }} checked={this.props.rule.isActive} />
                </div>
                <div style={{ flex: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Typography style={{ textAlign: "center" }}><b>{this.props.rule.zip}:</b> {this.props.rule.minOrderAmount.replace(".", ",")} €</Typography>
                    {this.props.rule.deliveryFee != null &&
                        <Typography style={{ textAlign: "center" }}><b>Liefergebühr:</b> {this.props.rule.deliveryFee.replace(".", ",")} €</Typography>
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "right", flex: 1, flexDirection: "row" }}>
                    <IconButton onClick={() => { this.props.onDelete() }} size='small'>
                        <Delete />
                    </IconButton>
                    <IconButton onClick={() => { this.props.onEdit() }} size='small'>
                        <Edit />
                    </IconButton>
                </div>
            </div>
        )
    }
}

export default DeliveryRuleButton