import React, { Component } from 'react'
import GeneralPriceAdjustmentTypeSelector, { GPA_OPTIONS } from './GeneralPriceAdjustmentTypeSelector';
import { Button, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import { MUI_COLORS } from '../../../constants/MUIConstants';

export class GeneralPriceAdjustmentHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gpaSelection: GPA_OPTIONS.DECREASE,
            value: 0.0
        }
    }
    onSave() {
        if (parseFloat(this.state.value.toString().replace(",", ".")) !== 0) {
            this.props.openAlertDialog(
                [
                    {
                        label: "Ja",
                        color: MUI_COLORS.ERROR,
                        onChoice: () => {
                            // console.log("REGEL wird gelöscht!");
                            this.props.onSave(this.state.gpaSelection, parseFloat(this.state.value.toString().replace(",", ".")))
                        }
                    },
                    {
                        label: "Nein",
                        onChoice: () => {
                            console.log("Preis wird nicht angepasst...");
                        }
                    }
                ],
                "Preisanpassung", `Möchten Sie eine generelle Preiserhöhung vornehmen? Dies wird alle Preise um ${parseFloat(this.state.value.toString().replace(",",".")).toFixed(2).replace(".",",")} € ${this.state.gpaSelection === GPA_OPTIONS.DECREASE ? "reduzieren" : "erhöhen"}.`)
        }
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", gap: 20, alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                    <GeneralPriceAdjustmentTypeSelector value={this.state.gpaSelection} setValue={(value) => { this.setState({ gpaSelection: value }) }} />
                </div>
                <div style={{ width: 200, display: "flex", justifyContent: "center" }}>
                    <TextField
                        style={{ width: "100%" }}
                        size='small'
                        value={this.state.value}
                        onChange={(evt) => {
                            const regex = /^(0|[1-9][0-9]*)([.,][0-9]{0,2})?$/;
                            if (evt.target.value === '' || regex.test(evt.target.value)) {
                                this.setState({ value: evt.target.value });
                            }
                        }}
                    />
                </div>
                <div style={{ width: 200, display: "flex", justifyContent: "right" }}>
                    <Button
                        onClick={() => { this.onSave() }} style={{ height: 40, width: "100%" }} variant='contained' startIcon={<Save />}>Anwenden</Button>
                </div>
            </div>
        )
    }
}

export default GeneralPriceAdjustmentHeader