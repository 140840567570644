import { ArrowBack, Delete, Edit, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from '@mui/material'
import React, { Component } from 'react'
import { PE_CHANGE_TYPES } from './ProductEditor'
import { connect } from 'react-redux'
import GenericTable from '../../../../components/GenericTable/GenericTable'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles';
import CollapsibleTile from '../../../../components/CollapsibleTile'
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../../../constants/MUIConstants'
import CategorySelectorTable from './CategorySelectorTable'
import { APIData } from '../../../../constants/APIAdresses'
import { setCategories, setSelectedCategories } from '../../../../redux/productSlice'

const columns = [
    {
        // width: 200,
        width: 150,
        label: 'Kategorie-ID.',
        dataKey: 'cid',
    },
    {
        // width: 200,
        label: 'Kategorie',
        dataKey: 'name',
    },
    {
        // width: 50,
        width: 150,
        label: 'Unterkategorie-ID',
        dataKey: 'scKey',
    },
    {
        // width: 200,
        label: 'Unterkategorie',
        dataKey: 'scName',
    }

]
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#b9d3e4',
        // backgroundColor: '#adbdc8',
        color: '#5a5a5a',
        // fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const MODES = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
}
export class CategorySelector extends Component {
    constructor(props) {
        super(props)

        this.state = {
            collapsed: true,
            currentMode: MODES.VIEW,
        }
        // console.log("PROPS: ",this.props);
    }

    render() {
        return (
            <CollapsibleTile
                title="Kategorien"
                icon={<img src={require('../../../../assets/icons/categories.png')} width={20} height={20} />}
                onCollapseChange={(collapsed, firstExpand) => { }}
                actions={
                    this.state.currentMode === MODES.VIEW ? 
                    [
                        { label: "Editieren", icon: <Edit />, onClick: () => { this.setState({currentMode: MODES.EDIT}) } },
                    ] 
                    :
                    [
                        { label: "Zurück", icon: <ArrowBack />, variant: MUI_BTN_VARIANTS.OUTLINED, onClick: () => { this.setState({currentMode: MODES.VIEW}) } },
                    ]
                }
            >
                {this.state.currentMode === MODES.VIEW ?

                <Paper >
                    <TableContainer  sx={{ flex: 1, scrollbarWidth: 'none', borderRadius:1, msOverflowStyle: 'none' }} component={Box}>
                        <Table stickyHeader sx={{ minWidth: 700, }} size='small' aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => {

                                        return <StyledTableCell sx={column.width != null ? { width: column.width } : {}} key={column.dataKey}  >{column.label}</StyledTableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.selectedOptions?.map((row, index) => {
                                    return <StyledTableRow key={row.cid} >
                                        <StyledTableCell sx={{}}>{row.cid}</StyledTableCell>
                                        <StyledTableCell sx={{}}>{row.name}</StyledTableCell>
                                        <StyledTableCell sx={{}}>{row.update?.scKey != null ? (row.update?.scKey?.toString() !== "0" ? row.update.scKey : "-")  : row.scKey != null && row.scKey?.toString() !== "0" ? row.scKey : "-"}</StyledTableCell>
                                        <StyledTableCell sx={{}}>{row.update?.scName != null ? row.update.scName : row.scName != null && row.scKey?.toString() !== "0" ? row.scName : "-"}</StyledTableCell>
                                    </StyledTableRow>

                                })}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Paper>
                : 
                <CategorySelectorTable 
                apiEndPoint={APIData.SHOP+ `/shop/${this.props.shopId}/getPagedCategories`}
                // tableData={this.props.options}
                // setTableData={(data) => { this.props.setCategories(data) }}
                />
            }
            </CollapsibleTile>
        )
    }
}
function mapStateToProps(state) {
    return {
        selectedOptions: state.product.selectedCategories,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSelectedOptions: (data) => {
            dispatch(setSelectedCategories(data));
        },
        setCategories: (data) => {
            // dispatch(setSelectedCategories(data));
            dispatch(setCategories(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategorySelector)