import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import TileButton from '../../components/TileButton';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Save, Tune } from '@mui/icons-material';
import { withParams } from '../../hookInjection/withParams';
import CategoriesTile from './ShopContent/CategoriesTile';
import CollectionsTile from './ShopContent/CollectionsTile';
import GroupCollectionsTile from './ShopContent/GroupCollectionsTile';
import EntitySelection from '../../components/Elements/EntitySelection';
import GeneralPriceAdjustmentTypeSelector, { GPA_OPTIONS } from './ShopContent/GeneralPriceAdjustmentTypeSelector';
import GeneralPriceAdjustmentHeader from './ShopContent/GeneralPriceAdjustmentHeader';
import GroupCategoriesTile from './ShopContent/GroupCategoriesTile';
import ProductsTile from './ShopContent/ProductsTile';

export class ShopContentManagementView extends Component {
    constructor(props) {
        super(props);
        this.companyId = this.props.params.companyId;
        this.shopId = this.props.params.shopId;
    }
    render() {
        return (

            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                <ProductsTile shopId={this.shopId} {...this.props} />
                <GroupCategoriesTile shopId={this.shopId} {...this.props} />
                <CollectionsTile shopId={this.shopId} {...this.props} />
                <GroupCollectionsTile shopId={this.shopId} {...this.props} />
                <CategoriesTile shopId={this.shopId} openAlertDialog={this.props.openAlertDialog} showAlert={this.props.showAlertDisplay} openDrawer={this.props.openDrawer} closeDrawer={this.props.closeDrawer} />
            </div>
        )
    }
}

class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Content Management">
                <ShopContentManagementView {...this.props} />
            </DefaultFrame>
        )
    }
}
export default withParams(defaultWrapper);