import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import NutritionDataTile from './NutritionDataTile'
import AllergensTile from './AllergensTile'
import PromotionsTile from './PromotionsTile'
import CommissionConfigTile from './CommissionConfigTile'
import ShopCommissionConfigTile from '../partnermanagement/Shop/ShopCommissionConfigTile'
import ShopSelector from '../../components/ShopSelector'
import { Typography } from '@mui/material'
import ReviewsTile from '../partnermanagement/Shop/ReviewsTile'

export class DataManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewShopId: null,
            promotionShopId: null
        }
        console.log("PROPS???: ",props);
    }
    render() {
        return (
            <div style={{display:"flex", flexDirection:"column", gap:15}}>
                <NutritionDataTile {...this.props} />
                <ReviewsTile {...this.props} shopId={this.state.reviewShopId} setShopId={(id) => {this.setState({reviewShopId:id}) }} />
                <AllergensTile {...this.props} />
                <PromotionsTile {...this.props} shopId={this.state.promotionShopId} setShopId={(id) => {this.setState({promotionShopId: id})}}  />
                <ShopCommissionConfigTile {...this.props} shopId={0} />
            </div>
        )
    }
}

class defaultWrapper extends Component {
    render() {
        return (
            <DefaultFrame title="Plattformverwaltung">
                <DataManagement />
            </DefaultFrame>
        )
    }
}
export default defaultWrapper