import { APIData } from "./constants/APIAdresses";

// export class APIHelper {
    

    // resolves with {role: }
    export function checkSession() {
        return new Promise((resolve, reject) => {
            let uri = APIData.USER + "/auth/session/checkCreate";
            var bodyParamsObj = {}
            fetch(uri, { body: JSON.stringify(bodyParamsObj), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }else {
                        return null;
                    }
                })
                .then(result => {
                    if (result != null) {
                        resolve(result);
                    }else {
                        reject();
                    }
                }).catch(e => {
                    reject();
                    console.log("Error while checkSession...: ", e);
                })
        })
    }
    export function getSupportMessages(scid, offset){
        return new Promise((resolve, reject) => {
            let uri = APIData.REALTIME+"/support/getMessages?bigDisplay=1&isInverted=1&scid="+scid+"&offsetMultiplier="+offset;
            fetch(uri, {credentials:"include"})
            .then(response => {
                console.log("ResponseStatus: ",response.status);
                if(response.status === 200){
                    return response.json();
                }else{
                    console.log("ErrStatus while loading supportMessages: ",response.status);
                }
            }).then(result => {
                if(result != null){
                    resolve(result);
                }else{
                    reject();
                }
            }).catch(e => {
                console.log("Error while getSupportMessages: ",e);
                reject();
            })
        })
    }

// }