import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setProductVariants } from '../../../../redux/productSlice'
import { PE_CHANGE_TYPES } from './ProductEditor'
import CollapsibleTile from '../../../../components/CollapsibleTile'

export class PVAllergensSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
        }
    }
    onChangeCheckbox(checkboxIndex) {
        console.log("CHECKBOXINDEX: ",checkboxIndex);
        console.log("PVINDEX: ",this.props.pvIndex);
        let productVariants = [...this.props.productVariants];
        let pvAllergenIndex = productVariants[this.props.pvIndex].allergens.findIndex((element) => element.aid === this.props.options[checkboxIndex].id);
        if(pvAllergenIndex != -1){
            // console.log("PV...: ",productVariants[this.props.pvIndex].allergens[pvAllergenIndex]);
            if(productVariants[this.props.pvIndex].allergens[pvAllergenIndex].status === PE_CHANGE_TYPES.DELETE){
                delete productVariants[this.props.pvIndex].allergens[pvAllergenIndex].status;
            } else if (productVariants[this.props.pvIndex].allergens[pvAllergenIndex].status === PE_CHANGE_TYPES.NEW) {
                productVariants[this.props.pvIndex].allergens.splice(pvAllergenIndex, 1);
            } else {
                productVariants[this.props.pvIndex].allergens[pvAllergenIndex] = { ...productVariants[this.props.pvIndex].allergens[pvAllergenIndex], status: PE_CHANGE_TYPES.DELETE };
            }
        }else{
            productVariants[this.props.pvIndex].allergens.push({id: null, aid: this.props.options[checkboxIndex].id, status: PE_CHANGE_TYPES.NEW});
        }
        this.props.setProductVariants(productVariants);
    }
    render() {
        return (
            <CollapsibleTile
                title={`Allergene (${this.props.selectedOptions?.length > 0 ? this.props.selectedOptions.filter(so => so.status !== PE_CHANGE_TYPES.DELETE).length : 0})`}
                icon={<img src={require('../../../../assets/icons/peanut.png')} width={20} height={20} />}
                onCollapseChange={(collapsed, firstExpand) => { }}
            >
                <div key={"multiCb_" + this.props.selectedOptions?.length} style={{ display: "flex", flexWrap: 'wrap', gap: 10 }}>
                    {this.props.options?.map((optionData, optionIndex) => {
                        return (
                            <div onClick={() => { this.onChangeCheckbox(optionIndex) }} key={"MULTICHECKBOX_" + optionIndex} style={{ border: '0.5px solid #DEDEDE', cursor: 'pointer', borderRadius: 5, paddingRight: 15, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Checkbox checked={this.props.selectedOptions?.find(o => o?.uniqueKey === optionData?.uniqueKey && o.status !== PE_CHANGE_TYPES.DELETE /*&& o.status !== GD_STATUS_TYPE.DELETE*/)} />
                                <Typography style={{ userSelect: 'none', cursor: 'pointer' }}>{optionData.defaultLabel}</Typography>
                            </div>)
                    })}
                </div>
            </CollapsibleTile>
        )
    }
}
function mapStateToProps(state) {
    return {
        options: state.product.allergens,
        // selectedOptions: state.product.selectedAllergens,
        productVariants: JSON.parse(JSON.stringify(state.product.productVariants))
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setProductVariants: (variants) => dispatch(
            setProductVariants(variants)
        ),
        // setAllergens: (allergens) => dispatch(setAllergens(allergens)),
        // setSelectedAllergens: (allergens) => dispatch(setSelectedAllergens(allergens)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PVAllergensSelector)