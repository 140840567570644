
import React, { Component } from 'react'

import TableBody from '@mui/material/TableBody';
import { CELLFORMAT, StyledTableCell, StyledTableRow } from './OrderTable';
import {  Button, CircularProgress, Collapse, IconButton, Radio, Switch, TextField, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { Add, ArrowDownward, ArrowUpward, Delete, Edit, North, Save, South } from '@mui/icons-material';
import { getDateSectionConfigFromFormatToken } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
import { formatDateToGermanTime } from '../../Essentials';
import OrderPositionButton from './OrderPositionButton';
import OrderStatusSelector from './OrderStatusSelector';
// import OrderStatusSelector from './OrderStatusSelector';
// import OrderStatusSelector from '../support/ChatDialogInfoArea/Tabs/Elements/OrderStatusSelector';
// 

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [subTableColSpan, setSubTableColSpan] = React.useState(0);
    React.useEffect(() => {
        // setSubTableColSpan(props.columns.length + 1);
        setSubTableColSpan(
            props.columns.length +
            (props.onSetActive != null && props.activeKey != null ? 1 : 0) +
            (props.onDelete != null ? 1 : 0) +
            (props.onEdit != null ? 1 : 0) +
            (props.onPositionChange != null ? 2 : 0)
        );
    }, []);

    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' }, }} key={row.id}
                style={{ cursor: 'pointer', }}>
                <StyledTableCell onClick={() => setOpen(!open)}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    // onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>

                {props.columns.map((column) => {
                    return <StyledTableCell onClick={() => { props.onClick != null ? props.onClick() : setOpen(!open) }} key={column.dataKey} >{column.format ? column.format(row[column.dataKey], row) : row[column.dataKey]}</StyledTableCell>
                })}
                {props.onDelete != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onDelete(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <Delete />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onEdit != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onEdit(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <Edit />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onPositionChange != null &&
                    <StyledTableCell sx={{ maxWidth: 10 }} onClick={(event) => { event.stopPropagation(); props.onPositionChange(row, -1); }} >
                        <IconButton size="small" color='primary' >
                            <ArrowUpward style={{ height: 20, width: 20 }} />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onPositionChange != null &&
                    <StyledTableCell sx={{ maxWidth: 10 }} onClick={(event) => { event.stopPropagation(); props.onPositionChange(row, 1); }} >
                        <IconButton size="small" color='primary' >
                            <ArrowDownward style={{ height: 20, width: 20 }} />
                        </IconButton>
                    </StyledTableCell>
                }

            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ padding: 0 }} colSpan={subTableColSpan + 1}>
                    <Collapse key={"NTBody_Collapse_" + props.index + "_" + row[props.subTableKey]?.length} in={open} timeout="auto" unmountOnExit>
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {/* Dark background div */}
                            {row.isLoading &&
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0,0,0,0.3)',
                                        position: 'absolute', // This makes it overlap content
                                        top: 0,
                                        left: 0,
                                        zIndex: 10, // Ensure it is above the content
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <div style={{ width: 50, height: 50, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 4, backgroundColor: 'rgba(255,255,255,0.8)' }}>
                                        <CircularProgress size={35} />
                                    </div>
                                </div>
                            }

                            {/* Main content div */}
                            <div
                                style={{
                                    position: 'relative', // Ensure this element can have stacking order
                                    zIndex: 5, // Make sure content stays below the background
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 15,
                                    padding: 20,
                                }}
                            >
                                {/* Content below */}
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        <Typography fontSize={14}>
                                            <b>Bestellstatus</b>
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Bestellung Aufgegeben:</b> {formatDateToGermanTime(row.createDate)}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Vorbereitung gestartet:</b> {row.prepStart == null ? '-' : formatDateToGermanTime(row.prepStart)}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Vorbereitung beendet:</b> {row.prepEnd == null ? '-' : formatDateToGermanTime(row.prepEnd)}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Lieferung gestartet:</b> {row.deliveryStart == null ? '-' : formatDateToGermanTime(row.deliveryStart)}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Geliefert am:</b> {row.deliveryDate == null ? '-' : formatDateToGermanTime(row.deliveryDate)}
                                        </Typography>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Typography fontSize={14}>
                                            <b>Unternehmensdaten</b>
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Firma: </b> {row.companyName}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Adresse: </b> {row.companyAddress}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Shop: </b> {row.shopName}
                                        </Typography>
                                        <Typography fontSize={14}>
                                            <b>Shop-Adresse: </b> {row.shopAddress}
                                        </Typography>
                                        <div style={{ display: 'flex', gap: 15, flex: 1 }}>
                                            <Typography fontSize={14}>
                                                <b>Steuernr: </b> {row.taxId}
                                            </Typography>
                                            {row.vatId != null && (
                                                <Typography fontSize={14}>
                                                    <b>Ust.-IdNr.: </b> {row.vatId}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <OrderStatusSelector
                                            value={row.updatedStatus != null ? row.updatedStatus : row.status}
                                            updateStatus={props.updateStatus}
                                            deliveryOption={row.deliveryOption}
                                        />
                                    </div>
                                    <TextField
                                        style={{ flex: 1 }}
                                        label="Rabatt Hinzufügen"
                                        type="number"
                                        value={row.discount}
                                        onChange={(evt) => {
                                            props.updateDiscount(evt.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 20 }}>
                                    {row.orderPositions.map((op, index) => {
                                        return (
                                            <OrderPositionButton
                                                alreadyRefundedQty={row.orderPositions.filter((fop) => fop.isRefund === 1 && fop.refundPosition === op.position).map((fop) => fop.quantity).reduce((a, b) => a + b, 0)}
                                                key={'ORDER_' + row.id + '_OPB_' + index}
                                                data={op}
                                                updateQuantity={(value) => {
                                                    props.updateQuantity(index, value);
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                                <Button onClick={props.onSave} variant="outlined" startIcon={<Save />}>
                                    Speichern
                                </Button>
                            </div>
                        </div>
                    </Collapse>
                </StyledTableCell>

            </StyledTableRow>
        </React.Fragment>
    );
}
export class OTBody extends Component {
    render() {
        return (
            <TableBody>
                {this.props.tableData?.map((row, index) => {
                    return <Row
                        key={row.id}
                        row={row}
                        updateQuantity={(opIndex, value) => { this.props.updateQuantity(index, opIndex, value) }}
                        updateStatus={(status) => { this.props.updateStatus(index, status) }}
                        updateDiscount={(value) => { this.props.updateDiscount(index, value) }}
                        customSubTableHeaderComponent={this.props.customSubTableHeaderComponent}
                        customSubTableFooterComponent={this.props.customSubTableFooterComponent}
                        getRowId={this.props.getRowId}
                        getSubRowId={this.props.getSubRowId}
                        subTableSortModel={this.props.subTableSortModel}
                        subColumns={this.props.subColumns}
                        subTableKey={this.props.subTableKey}
                        onEdit={this.props.onEdit != null ? (rowId) => { this.props.onEdit(index, rowId) } : null}
                        // (index, rowId) => {this.props.onEdit(index, rowId)}
                        onDelete={this.props.onDelete != null ? (rowId) => { this.props.onDelete(index, rowId) } : null}
                        onSetActive={(checked) => { this.props.onSetActive(index, checked) }}
                        onSubSetActive={this.props.onSubSetActive != null ? (row) => {
                            this.props.onSubSetActive(index, row)
                        } : null}
                        onSave={() => { this.props.onSave(index) }}
                        activeCompType={this.props.activeCompType}
                        addSubBtnIcon={this.props.addSubBtnIcon}
                        addSubBtnLabel={this.props.addSubBtnLabel}
                        addSubBtnOnClick={this.props.addSubBtnOnClick}
                        noSubAdd={this.props.noSubAdd}
                        //     this.props.onSubSetActive(index, subIndex, checked) }}
                        activeKey={this.props.activeKey}
                        isActive={this.props.isActive}
                        subActiveKey={this.props.subActiveKey}
                        subIsActive={this.props.subIsActive}
                        columns={this.props.columns}
                        index={index}
                        onClick={this.props.onClickRow != null ? () => { this.props.onClickRow(index) } : null}
                        onSubClick={(myRow) => { this.props.onClickSubRow(index, myRow) }}
                        onSubEdit={this.props.onSubEdit != null ? (myRow) => { this.props.onSubEdit(index, myRow) } : null}
                        onSubDelete={this.props.onSubDelete != null ? (myRow) => { this.props.onSubDelete(index, myRow) } : null}
                    />
                })}
            </TableBody>
        )
    }
}

export default OTBody
