import { TableHead, TableRow } from '@mui/material'
import React, { Component } from 'react'
import { StyledTableCell } from './GenericTable'


export class GTTableHeader extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <TableHead>
                <TableRow >
                    {this.props.onSetActive != null && (this.props.activeKey != null || this.props.isActive != null) &&
                        <StyledTableCell sx={{ width: 25, textAlign:"center !important"}}>Aktiv</StyledTableCell>
                    }
                    {this.props.columns.map((column) => {
                        return <StyledTableCell sx={column.width != null ? { width: column.width } : {}} key={column.dataKey}  >{column.label}</StyledTableCell>
                    })}
                    {this.props.onDelete != null &&
                        <StyledTableCell sx={{ width: 25 }} >{/* THIS IS FOR THE DELETE BUTTON*/}</StyledTableCell>
                    }
                    {this.props.onDuplicate != null &&
                        <StyledTableCell sx={{ width: 25 }} >{/* THIS IS FOR THE DUPLICATE BUTTON*/}</StyledTableCell>
                    }
                    {this.props.onEdit != null &&
                        <StyledTableCell sx={{ width: 25 }} >{/* THIS IS FOR THE EDIT BUTTON*/}</StyledTableCell>
                    }
                </TableRow>
            </TableHead>
        )
    }
}

export default GTTableHeader
